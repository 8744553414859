import axios from "axios"
import {CLEAR_ERRORS, LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_USER} from "../constants/userConstants"
import {api} from "../../config";

export const login = (values, history) => async (dispatch) => {
    try {
        dispatch({type: LOGIN_REQUEST})
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {data} = await axios.post(`${api.API_URL}/auth/signin`, {
            username: values.email,
            password: values.password
        }, config);
        let date = new Date();
        date.setMilliseconds(date.getMilliseconds() + data.jwt.expiryMs);

        console.log('login', data)

        /*sessionStorage.setItem("authUser", JSON.stringify(data.user));
        sessionStorage.setItem("jwt", JSON.stringify(data.jwt));
        sessionStorage.setItem("expiry", date);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: data
        });

        if (data.user.activated === true) {
            history.push("/");
        } else {
            history.push({pathname: '/reset/password', state: {email: data.user.email}})
        }*/
    } catch (error) {
        console.log('error', error)
        dispatch({
            type: LOGIN_FAIL,
            payload: error
        })
    }
}

export const logout = () => async (dispatch) => {
    sessionStorage.removeItem("authUser");
    sessionStorage.removeItem("jwt");
    sessionStorage.removeItem("expiry");
    dispatch({type: LOGOUT_USER});
}

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}
