import React, { Fragment } from 'react'
import { Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ProtectedRoute = ({ component, ...props}) => {
    const {isAuthenticated, loading, expiry } = useSelector(state => state.auth)
    const now = new Date();
    const expiryDate =  new Date(expiry);
    console.log('expiry', expiryDate)
    console.log('now', now)
    return (isAuthenticated && expiryDate > now ?  component : <Navigate to="/login" />)
}
export default ProtectedRoute
