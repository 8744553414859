import React, {useState} from 'react';
import logo from '../../assets/img/logo-login.png';
import {useDispatch, useSelector} from "react-redux";
import * as client from '../../apis/APIClient';
import * as Yup from "yup";
import {useFormik} from "formik";
import {useNavigate, useLocation} from 'react-router-dom';
import {Label} from 'flowbite-react'
import PasswordInput from "./PasswordInput";

const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState()
    const [reset, setReset] = useState({});
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    const email = location.state?.email;
    const token = location.state?.token;

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            oldPassword: reset.email || '',
            newPassword: reset.password || '',
            confirmPassword: reset.confirmPassword || '',
            email: reset.email || email,
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required("Please enter the temporary password sent on email"),
            newPassword: Yup.string()
                .min(6, 'At least 6 characters')
                .matches(/[a-z]/, 'At least one lowercase character')
                .matches(/[A-Z]/, 'At least one uppercase character')
                .matches(/[0-9]/, 'At least 1 number.').required("New Password is required"),
            confirmPassword: Yup.string().required('Please Enter Your Confirm Password').oneOf([Yup.ref('newPassword'), null], 'Passwords do not match'),
        }),
        onSubmit: (values) => {
            setError()
            setSuccess()
            setLoading(true)
            values.token = token
            client.createPassword(values, function (data, error) {
                setLoading(false)
                if (data) {
                    setSuccess(data.message)
                } else {
                    setError(error)
                }
            })
        }
    });

    return (
        <div
            className="text-gray-500 bg-gray-100 font-body min-h-screen flex flex-grow flex-col justify-center align-middle">
            <div className="min-h-screen flex-grow flex flex-col justify-center">
                <section className="w-9/10 md:w-6/10 mx-auto md:flex justify-between border border-slate-200 rounded-md shadow-lg">
                    <div className="basis-1/2 bg-wsblue-600 p-12 rounded-l-none md:rounded-l-md flex flex-col justify-center text-center">
                        <img src={logo} alt="Majidata"/>
                    </div>
                    <div className="basis-1/2 bg-wsblue-50/60 p-12 flex flex-col justify-center">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }} action="">
                            <h1 className="text-3xl text-wsblue-700 mb-6 pb-1 border-b border-wsblue-200">
                                Reset Password
                            </h1>
                            <div className="text-sm text-wsblue-500 mb-4">
                                <b>New Password Requirements: </b>
                                <ul>
                                    <li>At least 6 characters</li>
                                    <li>At least one lowercase character</li>
                                    <li>At least one uppercase character</li>
                                    <li>At least one number</li>
                                </ul>
                            </div>
                            <div className="mb-4">
                                <Label className="form-label" htmlFor="password" value="Temporary Password"/>
                                <PasswordInput
                                    id="oldPassword"
                                    name="oldPassword"
                                    placeholder="Enter Temporary Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.oldPassword || ""}
                                    invalid={validation.touched.oldPassword && validation.errors.oldPassword ? "true" : "false"}/>
                                {validation.touched.oldPassword && validation.errors.oldPassword && (
                                    <p type="invalid"
                                       className="text-red-600 text-[0.9rem]">{validation.errors.oldPassword}</p>)}
                            </div>
                            <div className="mb-4">
                                <Label className="form-label" htmlFor="password" value="New Password"/>
                                <PasswordInput
                                    id="newPassword"
                                    name="newPassword"
                                    placeholder="Enter New Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.newPassword || ""}
                                    invalid={validation.touched.newPassword && validation.errors.newPassword ? "true" : "false"}/>
                                {validation.touched.newPassword && validation.errors.newPassword && (<p type="invalid"
                                       className="text-red-600 text-[0.9rem]">{validation.errors.newPassword}</p>)}
                            </div>
                            <div className="mb-4">
                                <Label className="form-label" htmlFor="password" value="Confirm Password"/>
                                <PasswordInput
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.confirmPassword || ""}
                                    invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? "true" : "false"}/>
                                {validation.touched.confirmPassword && validation.errors.confirmPassword && (
                                    <p type="invalid"
                                       className="text-red-600 text-[0.9rem]">{validation.errors.confirmPassword}</p>)}
                            </div>
                            <div className="mb-4">
                                {error && (<p className="text-sm text-red-600 text-[0.9rem]">{error}</p>)}
                                {success && (<p className="text-sm text-green-600 text-[0.9rem]">{success}</p>)}
                            </div>
                            <div className="grid grid-cols-2 gap-10">
                                <div>
                                    <button className="outline-button" type="button" onClick={() => navigate('/login')}>
                                        Back to Login
                                    </button>
                                </div>
                                <div className="text-right">
                                    <button className="primary-button" type="submit">
                                        Reset Password
                                        {loading && <svg aria-hidden="true" role="status"
                                                         className="inline w-5 h-5 mr-1.5 text-white animate-spin"
                                                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"/>
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"/>
                                        </svg>}

                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </div>)
}

export default ResetPassword