import StatsCard from './StatsCard.jsx'
import wsp from '../../assets/icons/wsp.svg'
import populationLogo from '../../assets/icons/population.svg'
import pipeline from '../../assets/icons/pipeline.svg'
import { Badge, Checkbox, Progress, Table } from 'flowbite-react'
import {api} from "../../config";
import * as url from "../../helpers/url_helper";
import * as client from '../../apis/APIClient';
import { useEffect, useState } from 'react'
import Moment from 'moment'
import {useSelector} from 'react-redux';
const Home = () => {

  const [wspsNumber, setWspsNumber] = useState(0);
  const [wspsPopulation, setWspsPopulation] = useState(0);
  const [ssspsNumber, setSsspsNumber] = useState(0);
  const [ssspsPopulation, setSsspsPopulation] = useState(0);
  const [pending, setPending] = useState([]);
  const [cardsLoading, setCardsLoading] = useState(true);
  const [cardStats, setCardStats] = useState([]);
  const {user, jwt} = useSelector((state) => state.auth);
  const params = { stages: 'SUBMITTED', sort : 'submittedAt,desc' };
  const secondparams = { stages: 'EDITING, SUBMITTED, APPROVED' };
  const statuses = 'SUBMITTED, REVIEW, CHANGE_REQUEST, REJECTED, APPROVED, PUBLISHED';

  useEffect(() => {
    client.fetchStatistics(function (data, error){
      console.log('data',data);
      setWspsNumber(data[0].properties.no_of_wsps)
      setWspsPopulation(data[0].properties.total_population_in_service_area)
      setSsspsNumber(data[0].properties.no_of_sssps)
      setSsspsPopulation(data[0].properties.total_population_served_by_sssps)
      setCardsLoading(false);
      console.log(wspsNumber)
    });

    let params = {
      statuses: statuses,
    }
    params.providerId = user.userOrg.category !== 'WASREB' ? user.userOrg.id : undefined
    client.fetchSubmissions(params,function (data, error){
      if (data) {
        console.log(data);
        setPending(data.content);
        setCardStats(data.content);
      }
    });
  }, []);

  return (
    <div className="p-3">
    <h1 className="dashboard-heading">Dashboard</h1>
    <div className="md:grid md:grid-cols-2 lg:grid-cols-4 gap-12 mt-6">
      <StatsCard
        label="No of WSPs"
        stat={wspsNumber?.toLocaleString()}
        trend="increase"
        trendStat={3}
        icon={wsp}
        cardsLoading={cardsLoading}
      />

      <StatsCard
        label="Pop. served by WSPs"
        stat={wspsPopulation?.toLocaleString()}
        trend="decrease"
        trendStat={9}
        icon={wsp}
        cardsLoading={cardsLoading}
      />

      <StatsCard
        label="No of SSSPs"
        stat={ssspsNumber?.toLocaleString()}
        trend="decrease"
        trendStat={12}
        icon={populationLogo}
        cardsLoading={cardsLoading}
      />

      <StatsCard
        label="Pop. served by SSSPs"
        stat={ssspsPopulation?.toLocaleString()}
        trend="increase"
        trendStat={7}
        icon={pipeline}
        cardsLoading={cardsLoading}
      />
    </div>

    <h1 className="dashboard-heading mt-12 mb-4">Latest Submissions</h1>
      <div className="md:grid md:grid-cols-4 gap-12 mt-6">
      <div className="md:col-span-3 overflow-scroll">
        <Table hoverable={true}>
          <Table.Head>
            <Table.HeadCell>Date</Table.HeadCell>
            <Table.HeadCell>Requested by</Table.HeadCell>
            <Table.HeadCell>Organisation</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {pending.slice(0, 4).map((request, index) =>
              <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {Moment(request.submittedAt ? request.submittedAt : request.audit?.createdAt).format('DD MMM YYYY hh:mma')}
                <span className="font-normal text-xs italic block mt-1 text-gray-500">
                {request.layer}
                </span>
              </Table.Cell>
              <Table.Cell>{request.submittedBy}</Table.Cell>
              <Table.Cell>{request.provider.fullName}</Table.Cell>
              <Table.Cell>
                <Badge color={request.status === 'SUBMITTED' ? "success" : "info"} className="uppercase">
                  {request.status}
                </Badge>
              </Table.Cell>
            </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
      <div className="basis-2/10 bg-white rounded shadow-lg border border-gray-200 p-6">
        <div className="mb-8 text-sm uppercase">
          <Progress
            color="blue"
            progress={Math.round(((cardStats.filter(x => x.status === 'SUBMITTED').length) / cardStats.length) * 100)}
            label="Submitted"
            labelposition="outside"
            labelProgress={true}
          />
        </div>
        <div className="mb-8 text-sm uppercase">
          <Progress
            color="green"
            progress={Math.round(((cardStats.filter(x => x.status === 'APPROVED').length) / cardStats.length) * 100)}
            label="Approved"
            labelposition="outside"
            labelProgress={true}
          />
        </div>
        <div className="mb-8 text-sm uppercase">
          <Progress
            color="purple"
            progress={Math.round(((cardStats.filter(x => x.status === 'REJECTED').length) / cardStats.length) * 100)}
            label="Rejected"
            labelposition="outside"
            labelProgress={true}
          />
        </div>

        <p className="mx-auto flex flex-col justify-center font-bold text-white text-2xl text-gray-400 font-light text-center mt-12 w-12 h-12 rounded-full bg-slate-600 border-2 border-slate-400">
          <span>{cardStats.length}</span>
        </p>
        <p className="text-sm uppercase text-center font-bold mt-4">
          Pending approvals
        </p>
      </div>
    </div>
  </div>
  )
}

export default Home