import React, { useEffect, useState }  from 'react';
import * as api  from '../../../apis/APIClient';
import Header from '../../Layouts/Header';
import Foot from '../../Layouts/Footer';
import {workspace} from '../../../config';
import { CircularProgress } from '@mui/material';
import MUIDataTable from "mui-datatables";
import { Link, useNavigate } from 'react-router-dom';
import {useSelector} from 'react-redux';
import { Button, Label, Modal, TextInput, Dropdown} from 'flowbite-react'

const Sssps = () => {
    const {user} = useSelector((state) => state.auth);
   ;
    const [rows, setRows] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const columns = [
        {
            name: "sssp_name",
            label: "Name",
        },
        {
            name: "sub_county",
            label: "Sub County",
        },
        {
            name: "Pop_Servd",
            label: "Pop. Served",
            options: {
                customBodyRender: (value) => {
                    return value.toLocaleString();
                },
                sort: false,
            }
        },
        {
            name: "In_WSPLSA",
            label: "IN WSP LSA",
        },
        {
            name: "supplymode",
            label: "Supply Mode",
        },
        {
            name: "OP_Status",
            label: "Status",
        },

        /*{
            name: "id",
            label: "Actions",
            options: {
                customBodyRender: (value, meta) => {
                    const row = meta.rowData[0];
                    return (
                        <button className='text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-2.5 mr-2 py-1 p-2'
                        onClick={() => { history.push({pathname: `/sssps/${value}`, state: {row}}) }}
                        >View</button>
                    );
                },
                sort: false,
            }
        },*/
    ];

    const options = {
        filterType: 'dropdown',
        selectableRows:'none',
    };

    useEffect(() => {
        setIsLoading(true);
        api.fetchSSSPs({provider : user.userOrg},  (data,error) =>{
            setIsLoading(false);
            if (data) {
               setRows(data.map(row=>row.properties));
            } else if (error) {
                setError(error);
            }
        })
    }, []);

    document.title = "SSSPs | Maji Data";

  return (
    <div className="text-gray-500 bg-gray-100 font-body min-h-screen flex flex-col justify-center align-middle">
        <Header />
        <main className="flex-grow">
            <div className="container mx-auto py-12">
                <h1 className="text-lg dashboard-heading mb-6 ">Providers: SSSPs</h1>

                <MUIDataTable
                    title={
                        <p>
                            SSSPs
                            {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                        </p>
                    }
                    data={rows}
                    columns={columns}
                    options={options}
                />

            </div>
        </main>
        
        <Foot />
    </div>
  )
}

export default Sssps