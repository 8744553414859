import Config from '../core/Config';
import DOM from '../helpers/Browser/DOM';
import { Control } from 'ol/control';
import { easeOut } from 'ol/easing';
import { SVGPaths, getIcon } from '../core/Icons';
import { isShortcutKeyOnly } from '../helpers/ShortcutKeyOnly';
import {DragBox} from 'ol/interaction';
import { setActiveTool } from '../helpers/ActiveTool';

const DEFAULT_OPTIONS = {};

class ZoomIn extends Control {
    constructor(options = {}) {
        const element = document.getElementById(options.target ? options.target : 'oltb');
        let toolbox = document.getElementById('ol-toolbox');
        if(toolbox === null) {
            toolbox = DOM.createElement({
                element: 'div',
                id: 'ol-toolbox',
                class: 'oltb-toolbox-container'
            });
        }

        super({
            element: element
        });
        
        const icon = getIcon({
            path: SVGPaths.ZoomIn,
            class: 'oltb-tool-button__icon'
        });

        const button = DOM.createElement({
            element: 'button',
            html: icon,
            class: 'oltb-tool-button',
            attributes: {
                type: 'button',
                'data-tippy-content': 'Zoom in (Q)'
            },
            listeners: {
                'click': this.handleClick.bind(this)
            }
        });
        this.mapElement = document.getElementById('ol-map');
        this.element.appendChild(button);
        this.button = button;
        this.options = { ...DEFAULT_OPTIONS, ...options };
        this.delta = 1;

        window.addEventListener('keyup', (event) => {
            if(isShortcutKeyOnly(event, 'q')) {
                this.handleZoomByDelta();
            }
        });
    }

    deSelect() {
        if (this.active) {
            this.getMap().removeInteraction(this.interaction);
        }
        this.active = false;
        this.button.classList.remove('oltb-tool-button--active');
        if(typeof this.options.click === 'function') {
            this.options.click(this.active);
        }
    }

    handleClick() {
        setActiveTool(this);
        this.handleZoomByDelta();
    }

    handleZoomByDelta() {
        const map = this.getMap();
        if (this.active) {
            if (this.interaction) {
                map.removeInteraction(this.interaction);
            }
            this.mapElement.style.cursor = "default";
        }else {
            this.interaction = new DragBox();
            this.mapElement.style.cursor = "zoom-in";
            this.interaction.on('boxend', function (event) {
                console.log('zoom-in',event.target.getGeometry().getExtent())
                var zoomInExtent = event.target.getGeometry().getExtent()
                map.getView().fit(zoomInExtent);
            });
            map.addInteraction(this.interaction);
        }

        this.active = !this.active;

        this.button.classList.toggle('oltb-tool-button--active');
        if(typeof this.options.click === 'function') {
            this.options.click(this.active);
        }
    }
}

export default ZoomIn;