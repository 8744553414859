import React, {useEffect, useState} from "react";
import {
    Button, Modal, Label, TextInput, FileInput, Textarea, Badge, Checkbox, Table, Tabs
} from 'flowbite-react'
import {workspace} from "../../config";
import {Draw} from 'ol/interaction';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLayerGroup, faXmark, faSearch, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select';
import {getArea, getLength} from 'ol/sphere';
import {useSelector} from 'react-redux';
import * as client from '../../apis/APIClient';
import {useFormik, Formik, Form} from "formik";

let excludedProps = ['geometry', 'geom', 'the_geom', 'gid', 'objectid', 'id', 'prev_id', 'prev_f_id', 'prev_f_gid', 'version', 'stage', 'operation', 'approved_by', 'date_approved', 'created_by', 'updated_by', 'date_updated', 'netwk_f_id', 'netwk_f', 'network_f', 'network_feature_id', 'network_feature', 'boundedBy', 'last_published']
let defaultProps = ['wsp_id', 'wsp_name', 'wsp_acrony', 'county_id', 'county', 'wwda_id', 'wwda', 'area_km2', 'area_m2', 'length_m', 'popdensity', 'datasource', 'feature','date_created','last_modified','modified_by']

const prepareFeature = (feature, data, user, layerName) => {
    ///console.log('prepareFeature', data)
    let category = user.userOrg.category
    if (feature) {
        if (data) {
            let provider = user.userOrg;
            defaultProps.forEach(prop => {
                if (data.properties[prop]) {
                    if (prop === 'wsp_id' && category === 'WSP') {
                        feature.set(prop, provider.id)
                        feature.set('wsp_name', provider.fullName)
                    }
                    if (prop === 'wsp_name' && category === 'WSP') {
                        feature.set(prop, provider.id)
                    }
                    if (prop === 'wsp_acrony' && category === 'WSP') {
                        feature.set(prop, provider.abbrev)
                    } else if (prop === 'county_id') {
                        feature.set(prop, category === 'COUNTY' ? provider.id : provider.countyId)
                        if (data.properties['county'] && category === 'COUNTY') {
                            feature.set('county', provider.fullName)
                        }
                    } else if (prop === 'wwda_id') {
                        feature.set(prop, provider.wwwdaId)
                    } else if (prop === 'area_km2') {
                        let area = getArea(feature.getGeometry());
                        area = area / 1000000;
                        feature.set(prop, area.toFixed(2))
                    } else if (prop === 'area_m2') {
                        let area = getArea(feature.getGeometry());
                        feature.set(prop, area.toFixed(2))
                    } else if (prop === 'length_m') {
                        let length = getLength(feature.getGeometry());
                        feature.set(prop, length.toFixed(2))
                    } else if (prop === 'feature') {
                        feature.set(prop, layerName)
                    }else if (prop === 'date_created' || prop === 'last_modified') {
                        const d = new Date();
                        feature.set(prop, d.toISOString())
                    } else if (prop === 'modified_by') {
                        feature.set(prop, user?.firstName + " " + user?.lastName)
                    } else if (prop === 'last_published') {
                        if (!feature.get(prop)) {
                            feature.set(prop, undefined)
                        }
                    }
                    
                }
            })
        }
    }
    return feature;
}


const Field = ({formData, property, field, data, onChange}) => {
    const [selected, setSelected] = useState()
    const [choices, setChoices] = useState(property?.choices)
    const [value, setValue] = useState()
    useEffect(()=>{
        let value = formData[field]
        if(choices) {
            if (property.multiple) {
                if (value) {
                    setSelected(value.split(',').map(val => {
                        return {value: val ?? '', label: val}
                    }))
                } else {
                    setSelected(false)
                }
            } else {
                setSelected(value && choices?.includes(value) ? {value: value, label: value} : false)
            }
            if (property.dependency?.dependsOn) {
                let dependsOn = property.dependency?.dependsOn?.toLowerCase()
                let dependencyValue = formData[dependsOn]
                let choices = property.dependency?.dependencyChoices?.find(choice => choice.value === dependencyValue)?.choices ?? []
                setSelected(value && choices?.includes(value) ? {value: value, label: value} : false)
                setChoices(choices)
            }
        }
        if(!value){
            switch (type){
                case 'number':
                    value = 0
                    break
                case 'date':
                    value = null
                    break
                default:
                    value = ''
            }
        }else if(value === 'Null'){
            value = ''
        }
        setValue(value)
    },[formData, field, property])

    const handleChange = (field, value) => {
        console.log(field,value)
        setSelected(value)
        if(onChange){
            onChange(field, value.value ? value.value : value.map(val=>val.value).join(','));
        }
    }


    let type = 'text';
    if (defaultProps.includes(field) || property?.prepopulated) {
        type = 'hidden';
    } else if (property?.type !== 'string') {
        type = property?.type == 'int' || property?.type == 'double' || property?.type == 'float' ? 'number' : property?.type;
    }
    if (choices) {
        var options = [{
            label: property?.label, options: choices.map(choice => ({
                value: choice, label: choice
            }))
        }]

        return (choices.length > 0 && (<div className={type !== 'hidden' ? 'p-2' : ''}>
            {type !== 'hidden' && (<div className="block  uppercase font-bold px-1">
                <Label htmlFor={field}
                       value={property.label ?? field.replace('_', ' ')}/>
                <i className="float-right"
                   data-tippy-content={property.description ?? field.replace('_', ' ')}>
                    <FontAwesomeIcon icon={faInfoCircle}/></i>
            </div>)}
            <Select
                isMulti = {property.multiple}
                name={field}
                options={options}
                value={selected}
                onChange={(value) => handleChange(field,value)}
            />
        </div>))
    } else {
        console.log(field, value)
        return (property && (<div className={type !== 'hidden' ? 'p-1' : ''}>
            {type !== 'hidden' && (<div className="block  uppercase font-bold px-1">
                <Label htmlFor={field}
                       value={property.label ?? field.replace('_', ' ')}/>
                <i className="float-right"
                   data-tippy-content={property.description ?? field.replace('_', ' ')}>
                    <FontAwesomeIcon icon={faInfoCircle}/></i>
            </div>)}
            <TextInput type={type == 'string' ? 'text' : type} min="0" id={field} name={field}
                       defaultValue={value}
                       onChange={(event) => {
                           onChange(field, event.target.value)
                       }}/>
        </div>))
    }
}

const EditForm = ({feature, data, editing, map, editLayer, completed}) => {
    const [formData, setFormData] = useState({});
    const {user} = useSelector((state) => state.auth);

    useEffect(()=>{
        let properties = {}
        Object.entries(feature.getProperties()).filter(prop => !excludedProps.includes(prop[0])).map(prop => {
            properties[prop[0]] = prop[1]
        })
        setFormData(properties)
    },[feature])
    
    const formatLength = function (line) {
        const length = getLength(line);
        return Math.round((length / 1000) * 100) / 100; //km
    };

    /**
     * Format area output.
     * @param {Polygon} polygon The polygon.
     * @return {string} Formatted area.
     */
    const formatArea = function (polygon) {
        const area = getArea(polygon);
        return Math.round((area / 1000000) * 100) / 100;
    };

    const onFieldChanged = (field, value) => {
        setFormData({...formData,[field]: value})
    }

    const handleSubmit = (event) => {
        const formData = new FormData(event.currentTarget);
        event.preventDefault();
        let properties = {}
        for (let [key, value] of formData.entries()) {
            if (!excludedProps.includes(key)) {
                if(data.properties[key].type === 'date' &&  !value){
                    properties[key] = null
                }else if(data.properties[key].multiple) {
                    if(properties[key]){
                        if(value) {
                            properties[key] = properties[key] + ',' + value
                        }
                    }else{
                        properties[key] = value
                    }
                } else {
                    properties[key] = value
                }
            }
        }


        if (properties.hasOwnProperty('popdensity')) {
            let population = properties.population;
            let area = properties.area_km2;
            var populationDensity = population / area
            properties.popdensity = populationDensity.toFixed(2)
        }
        console.log('modified', properties)
        if (feature) {
            feature.setProperties(properties)
            client.Transact({
                mode: editing.mode,
                features: [feature],
                edit: data.edit,
                targetNamespace: data.targetNamespace,
                targetPrefix: data.targetPrefix,
                workspace: workspace,
                provider: user.userOrg,
                remarks: feature.get('display') ? feature.get('display') : feature.get('label') ? feature.get('label') : ''
            }, (response, error) => {
                if (response) {
                    if(completed){
                        completed(response)
                    }
                }
            })
        }
    };

    const handleCancel = event => {
      //  setModified(false)
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="p-2 bg-white overflow-scroll" style={{'maxHeight': '65vh'}}>
                {data.properties  && Object.keys(formData).map((key) => (
                    <Field id={key} formData={formData} data={data} key={key} field={key} property={data.properties[key]} onChange={onFieldChanged}/>))}
            </div>
            <div className="p-2 bg-white overflow-hidden grid place-items-center ">
                <Button type="submit"
                        className="bg-slate-500 hover:bg-slate-400 text-white rounded-md">
                    Save
                </Button>
            </div>
        </form>)
}

export default EditForm;
export {prepareFeature, defaultProps, excludedProps}