import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { Tabs } from 'flowbite-react'
import Header from '../../Layouts/Header';
import Foot from '../../Layouts/Footer';

const SsspDetails = () => {

    const [activeTab, setActiveTab] = useState(0);
    const tabsRef = useRef(null);
    const location = useLocation();
    const row = location.state.row;
    useEffect(() => {
        console.log("Row state", row)
    }, [row]);

  return (
    <React.Fragment>
        <Header />
        <div className="page-content min-h-screen">
            <Container fluid>
                <Row>
                    <Col xs={12} className="my-6">
                        <div class="inline-flex items-center justify-center w-full">
                            <hr class="w-full h-2 my-8 bg-wsblue-500 border-0" />
                            <h4 className="mb-sm-0 absolute bg-wsblue-800 rounded-lg text-white px-3 py-3">{row.name_of_sssp}</h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className='align-center justify-center'>
                        <Tabs.Group
                            aria-label="Default tabs"
                            style="default"
                            ref={tabsRef}
                            onActiveTabChange={tab => setActiveTab(tab)}
                        >
                            <Tabs.Item title="Details">
                                <ul className="container">
                                    <li className="mb-2">Name Of wwda: {row?.name_of_wwda}</li>
                                    <li className="mb-2">Number of people served: {row?.no_of_people_served}</li>
                                    <li className="mb-2">Type of supply: {row?.type_of_supply}</li>
                                    <li className="mb-2">Ownership: {row?.ownership} </li>
                                    <li className="mb-2">County: {row?.county}</li>
                                    <li className="mb-2">Water Source: {row?.water_source}</li>
                                    <li className="mb-2">Operation Status: {row?.operation_status}</li>
                                </ul>
                            </Tabs.Item>
                            <Tabs.Item title="Maps">
                                <Container fluid>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="w-100 overflow-hidden">
                                                <div className="chat-content d-lg-flex">
                                                    <div className="w-100 overflow-hidden position-relative">
                                                        <div className="position-relative">
                                                            <div className="p-3 user-chat-topbar">
                                                                <Row className='pt-0'>
                                                                    <p>Map Component</p>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tabs.Item>
                        </Tabs.Group>
                    </div>
                </Row>
            </Container>
        </div>
        <Foot />
    </React.Fragment>
  )
}

export default SsspDetails