import React from 'react'
import Foot from './Layouts/Footer'
import Header from './Layouts/Header'
import Home from '../components/home'

const HomeDashboard = () => {
  document.title = "Dashboard | Maji Data";
  return (
    <div className='text-gray-500 bg-gray-100 font-body min-h-screen flex flex-grow flex-col justify-center align-middle'>
    <div className="min-h-screen flex flex-col">
    <Header />
    <main className="flex-grow">
      <div className="container mx-auto py-12">
        <Home />
      </div>
    </main>
    <Foot />
  </div>
  </div>
  )
}

export default HomeDashboard