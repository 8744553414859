/*const api = {
    GEOSERVER_URL: "http://20.105.198.226:8080/geoserver",
    API_URL: "http://20.105.198.226:5000/api",

}*/

const api = {
    GEOSERVER_URL: "https://apimajidata.wasreb.go.ke/geoserver",
    API_URL: "https://apimajidata.wasreb.go.ke/api",
}

const workspace = 'GENERAL'
const Permission = {
    can_publish_submissions: "can_publish_submissions",
    can_approve_submissions: "can_approve_submissions",
    can_manage_providers: "can_manage_providers",
    can_manage_data_requests: "can_manage_data_requests",
    can_view_submissions: "can_view_submissions",
    can_review_submissions: "can_review_submissions",
    can_view_data_requests: "can_view_data_requests",
    can_manage_users: "can_manage_users",
    can_add_features: "can_add_features",
    can_manage_data: "can_manage_data",
    can_edit_features: "can_edit_features",
    can_view_templates: "can_view_templates",
    can_delete_features: "can_delete_features",
    can_manage_tasks: "can_manage_tasks",
    can_view_tasks: "can_view_tasks",
    can_view_select: "can_view_select",
    can_view_data: "can_view_data",
    can_view_map: "can_view_map",
    can_view_layers: "can_view_layers",
    can_view_tools: "can_view_tools",
    can_view_infowindow: "can_view_infowindow",
}

const textLabels  = {
    selectedRows: {
        text: "row(s) selected",
            delete: "Clear",
            deleteAria: "Clear selection",
    },
}

const adminRoles = ['ADMIN','VIEWER']
const userRoles = ['GROUP_ADMIN','VIEWER','EDITOR']

export {api, workspace, Permission, textLabels,adminRoles,userRoles};