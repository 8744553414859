import Foot from '../Layouts/Footer'
import Header from '../Layouts/Header'
import React, {useEffect, useState, useMemo} from 'react';
import {Dropdown, Button, Modal, Label, Textarea, TextInput} from 'flowbite-react';
import Pagination from '../../components/Pagination';
import * as api from '../../apis/APIClient';
import {useSelector} from 'react-redux';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone';
import MUIDataTable from "mui-datatables";
import * as Yup from "yup";
import {useFormik} from "formik";
import {CircularProgress} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {Permission} from "../../config";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';


const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


let PageSize = 15;

const Edits = () => {
    const [pending, setPending] = useState(true);
    const [rows, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const {user} = useSelector((state) => state.auth);
    const workspace = user.userOrg? user.userOrg.abbrev : 'WASREB';
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [submitVisible, setSubmitVisible] = useState(false);
    const [deleteVisible, setDelete] = useState(false);
    const stages = 'CREATED,SUBMITTED,REVIEW,CHANGE_REQUEST,REJECTED,APPROVED,PUBLISHED';
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const navigate = useNavigate();

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return rows?.filter(task => task.audit).filter((item) => item.layer?.toLowerCase().includes(searchValue.toLowerCase())).slice(firstPageIndex, lastPageIndex);
    }, [currentPage, rows, searchValue]);

    const notify = (message) => toast.success(message ?? "Operation successful!");
    const warnNotify = (message) => toast.error(message ??  "Please select an edit!");

    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };

    const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);

    useEffect(() => {
        loadData()
    }, []);


    const loadData = () => {
        api.getLayerTasks({statuses: stages, orgId: user.userOrg.id}, function (data, error) {
            console.log(data);
            if(data){
                setRows(data.content)
            }else{
                console.log(error)
            }

            setPending(false);
        })
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            remarks: '',
        },
        validationSchema: Yup.object({
            remarks: Yup.string().required("Remarks is required"),
        }),
        onSubmit: (values) => {
            console.log(JSON.stringify(values));
            handleSubmit(values.remarks)
            setLoading(true);
        }
    });

    const tableOptions = {
        filterType: 'dropdown',
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        rowsSelected: selectedCheckboxes,
        selectableRows: 'multiple',
        selectableRowsOnClick: true,
        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            setSelectedCheckboxes(rowsSelected)
            const ids = rowsSelected.map(index => rows[index].id);
            console.log('ids', ids)
            console.log('rowsSelected', rowsSelected)
            //setIds(ids)
        }
    };

    const columns = [
        {
            name: 'layerTitle',
            label: 'Layer',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'notes',
            label: 'Name',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return value.map(note => note.note).join(', ');
                }
            }
        },
        {
            name: 'audit',
            label: 'Created At',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return moment.utc(value.createdAt).tz('Africa/Nairobi').format('DD MMM YYYY hh:mma');
                }
            }
        },
        {
            name: 'audit',
            label: 'Created By',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return value.createdBy;
                }
            }
        },
        {
            name: 'submittedAt',
            label: 'Submitted At',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return value ? moment.utc(value).tz("Africa/Nairobi").format('DD MMM YYYY hh:mma') : '-';
                }
            }
        },
        {
            name: 'taskAction',
            label: 'Action',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'taskStatus',
            label: 'Status',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "id",
            label: "",
            options: {
                print: false,
                download: false,
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Dropdown label="Action" inline  className="">
                            <Dropdown.Item onClick={()=>{navigate(`/submissions/edits/${value}`, {state: {task: value}})}}>
                                View
                            </Dropdown.Item>
                        </Dropdown>
                    );
                },
              
            },
        },
    ]


    const handleSubmit = (remarks) => {
        const ids = selectedCheckboxes.map(index => rows[index].id);
        const submissionRequest = {
            orgId: user.userOrg.id,
            layerTasks: ids,
            note: remarks
        }
        console.log('ids', ids)
        api.bulkUpdateTasks(submissionRequest, function (data, error) {
            setSubmitVisible(false)
            console.log(data);
            if (data) {
                setPending(false);
                notify();
                setSubmitVisible(false);
                loadData()
            } else if (error) {
                setPending(false);
            }
        })
    };
    const handleClickOpen = (status) => {
        setStatus(status)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const submit = async () => {
        const ids = selectedCheckboxes.map(index => rows[index].id);
        api.deleteEdits({ids: ids}, ()=>{
           setOpen(false);
            loadData()
       });
    };

    return (
        <div className="text-gray-500 bg-gray-100 font-body min-h-screen flex flex-col justify-center align-middle">
            <Header/>
            <ToastContainer/>
            <main className="flex-grow">
                <div className="container mx-auto py-12">
                    <h1 className="text-lg dashboard-heading mb-6 ">EDITS</h1>
                    {user.permissions.includes(Permission.can_manage_tasks) && (<div className="mb-6">
                        <Dropdown label="Action">
                            <Dropdown.Item onClick={() => {
                                if (selectedCheckboxes.length === 0) {
                                    warnNotify();
                                } else {
                                    setSubmitVisible(true);
                                }
                            }}>
                                Submit
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setOpen(true)}>
                                Delete
                            </Dropdown.Item>
                        </Dropdown>
                    </div>)}
                    <MUIDataTable
                        title={
                            <p>
                                {pending && <CircularProgress size={24} style={{
                                    marginLeft: 15,
                                    position: 'relative',
                                    top: 4
                                }}/>}
                            </p>
                        }
                        data={rows}
                        columns={columns}
                        options={tableOptions}
                    />
                </div>
            </main>
            <Foot/>
            <Modal
                show={submitVisible}
                size="md"
                popup={true}
                onClose={() => setSubmitVisible(false)}>
                
                <Modal.Header className="bg-slate-800">
                    <span className="text-slate-300">Confirm Submission</span>
                </Modal.Header>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <Modal.Body>
                        <div className="p-2">
                            <div className="mb-4">
                                <div className="block uppercase font-bold">
                                    <Label htmlFor="remarks" value="Remakrs"/>
                                </div>
                                <Textarea
                                    id="remarks"
                                    name="remarks"
                                    placeholder="Enter Remarks"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.remarks || ""}
                                    invalid={validation.touched.remarks && validation.errors.remarks ? "true" : "false"}
                                />
                                {(validation.touched.remarks && validation.errors.remarks) && (
                                    <p type="invalid"
                                       className="text-red-600 text-[0.9rem]">{validation.errors.remarks}</p>
                                )}
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className="bg-slate-700">
                        <Button type='submit'
                                className="bg-slate-300 hover:bg-slate-200 text-slate-800 rounded-md"
                                style={{backgroundColor: '#cbd5e1', color: '#1e293b'}}>
                            Submit
                        </Button>
                        <Button
                            onClick={() => setSubmitVisible(false)}
                            className="bg-slate-500 hover:bg-slate-400 text-slate-800 rounded-md"
                            style={{backgroundColor: '#64748b', color: '#1e293b'}}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                    Delete Confirmation
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Are you sure you want to delete selected edits?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button autoFocus onClick={submit}>
                        Yes, Delete
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

export default Edits