import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Navigate, withRouter } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/actions/userActions";

const Logout = (props) => {
  const dispatch = useDispatch();
  const { isAuthenticated, loading, user } = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  if (isAuthenticated === false) {
    return <Navigate to='/login' />
}

  return <></>;
};

export default Logout;