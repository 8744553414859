import React, {useEffect, useState} from 'react'
import logo from '../../assets/img/combined-logo.png'
import {Navbar, Dropdown, Avatar} from 'flowbite-react'
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Permission} from '../../config';


const UserIcon = () => (
    <div className="flex justify-start">
        <svg
            className="w-4 h-4 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            ></path>
        </svg>
        User Management
    </div>
)

const ProvidersIcon = () => (
    <div className="flex justify-start">
        <svg
            className="w-4 h-4 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
            ></path>
        </svg>
        Providers
    </div>
)

const RequestsIcon = ({title}) => (
    <div className="flex justify-start">
        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg"
        >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
            ></path>
        </svg>
        {title}
    </div>
)

const SubmissionsIcon = () => (
    <div className="flex justify-start">
        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg"
        >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
            ></path>
        </svg>
        Submissions
    </div>
)

const Header = (props) => {
    const location = useLocation();
    const {user} = useSelector(state => state.auth);
    const [activeTab, setActiveTab] = useState();
    const path = location.pathname;
    const category = user?.userOrg?.category;

   // console.log('header', user.permissions)

    const style = (link) => {
        const activate = link !== '/' ? path.startsWith(link) : path === '/'
        return {
            background: activate ? "#ffffffaa" : 'transparent',
            color: activate ? "#333333" : "white",
            padding: '0.6rem',
        }
    }

    return (
        <Navbar
            rounded={false}
            className="bg-wsblue-800 text-wsblue-100"
            style={{backgroundColor: 'rgb(30, 51, 114)'}}
        >
            <Navbar.Brand href={user.userOrg?.category === "WASREB" ? "/" : "/map"}>
                <img src={logo} className="sm:h-10" alt="Majidata"/>
            </Navbar.Brand>
            <div className="flex md:order-2">
                <Dropdown
                    arrowIcon={true}
                    inline={true}
                    label={ <div className="flex justify-start text-sm">{user?.firstName}</div>}>
                    <Dropdown.Header>
                        <span className="block text-sm font-bold  p-1">{user?.firstName + " " + user?.lastName}</span>
                        <span className="block text-sm  p-1">{user?.roleNames.join(', ')}</span>
                        <span className="block truncate text-sm font-medium  p-1">{user?.email}</span>
                        <span className="block text-sm p-1">{user?.userOrg.fullName}</span>
                    </Dropdown.Header>
                    {/* <a href="#"><Dropdown.Item>Profile</Dropdown.Item></a> */}
                    {/* <Dropdown.Divider /> */}
                    <a href="/logout"><Dropdown.Item>Sign out</Dropdown.Item></a>
                </Dropdown>
                <Navbar.Toggle/>
            </div>
            <Navbar.Collapse className="md:space-x-1">
                {user?.userOrg?.category === "WASREB" ?
                    <Navbar.Link
                        href="/"
                        className="text-wsblue-100 flex justify-start rounded-md"
                        style={style('/')}
                    >
                        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                            ></path>
                        </svg>
                        Dashboard
                    </Navbar.Link> : null}

                {(
                    user.permissions.includes(Permission.can_view_map) ||
                        user.permissions.includes(Permission.can_view_select) ||
                        user.permissions.includes(Permission.can_edit_features) ||
                        user.permissions.includes(Permission.can_delete_features)
                    ) &&
                <Navbar.Link
                    href={category !== 'WASREB' ? '/' : '/map'}
                    className="text-wsblue-100 flex justify-start  rounded-md"
                    style={style(category !== 'WASREB' ? '/' : '/map')}
                >
                    <svg className="h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                        ></path>
                    </svg>
                    Map
                </Navbar.Link>}
                {user.userOrg?.category === "WASREB" ?
                    user.permissions.includes(Permission.can_manage_providers) && <div className="rounded-md" style={style('/providers')}>
                    <Dropdown
                        label={<ProvidersIcon/>}
                        arrowIcon={true}
                        inline={true}
                        className="text-wsblue-100 hover:text-wsblue-200 rounded-md"
                    >
                        <a href="/providers?type=WSP"><Dropdown.Item>WSPs</Dropdown.Item></a>
                        <a href="/providers?type=SSSP"><Dropdown.Item>SSSPs</Dropdown.Item></a>
                        <a href="/providers?type=COUNTY"><Dropdown.Item>Counties</Dropdown.Item></a>
                        <a href="/providers?type=PARTNER"><Dropdown.Item>Partners</Dropdown.Item></a>
                    </Dropdown> </div>:
                user.permissions.includes(Permission.can_manage_providers) && <Navbar.Link
                        href="/sssps"
                        className="text-wsblue-100 flex justify-start rounded-md"
                        style={style('/sssps')}

                    >
                        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"></path>
                        </svg>
                        SSSPs
                    </Navbar.Link>}
                {(user.permissions.includes(Permission.can_view_submissions) || user.permissions.includes(Permission.can_view_tasks) || user.permissions.includes(Permission.can_manage_tasks)  || user.permissions.includes(Permission.can_publish_submissions) || user.permissions.includes(Permission.can_approve_submissions)   ) && <div className="rounded-md" style={style('/submissions')}>
                <Dropdown
                    label={<RequestsIcon title={user.userOrg?.category === "WASREB" ? 'Submissions' : 'Requests'}/>}
                    arrowIcon={true}
                    inline={true}
                    className="text-wsblue-100 hover:text-wsblue-200"
                    >
                    {user.userOrg?.category !== "WASREB" && (user.permissions.includes(Permission.can_view_submissions) || user.permissions.includes(Permission.can_view_tasks)  || user.permissions.includes(Permission.can_manage_tasks) ) &&  (<a href="/submissions/edits"><Dropdown.Item>Edits</Dropdown.Item></a>)}
                    {(user.permissions.includes(Permission.can_view_submissions) || user.permissions.includes(Permission.can_approve_submissions) || user.permissions.includes(Permission.can_manage_tasks)) && (<a href="/submissions"><Dropdown.Item>Submissions</Dropdown.Item></a>)}
                    {user.userOrg?.category === "WASREB" && user.permissions.includes(Permission.can_publish_submissions) && (
                        <a href="/submissions/publishing"><Dropdown.Item>Publishing</Dropdown.Item></a>)}
                </Dropdown>
                </div>}
                {user.userOrg?.category === "WASREB" && (user.permissions.includes(Permission.can_view_data_requests) || user.permissions.includes(Permission.can_manage_data_requests))  && (
                     <Navbar.Link href="/requests"
                                 className="text-wsblue-100 flex justify-start rounded-md"
                                 style={style('/requests')}
                    >
                        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                            ></path>
                        </svg>
                        Data Requests
                    </Navbar.Link>)}
                {user.userOrg?.category === "WASREB"  ? user.permissions.includes(Permission.can_manage_users) && (<div className="rounded-md" style={style('/users')}>
                    <Dropdown
                        label={<UserIcon/>}
                        arrowIcon={true}
                        inline={true}
                        className="text-wsblue-100 hover:text-wsblue-200 rounded-md">
                        <a href="/users?type=ADMIN"><Dropdown.Item>Administrators</Dropdown.Item></a>
                        <a href="/users?type=WSP"><Dropdown.Item>WSP Users</Dropdown.Item></a>
                        <a href="/users?type=COUNTY"><Dropdown.Item>County Users</Dropdown.Item></a>
                        <a href="/users?type=PARTNER"><Dropdown.Item>Partners Users</Dropdown.Item></a>
                    </Dropdown> </div>):
                    user.permissions.includes(Permission.can_manage_users) && (<Navbar.Link
                        href="/users"
                        className="text-wsblue-100 flex justify-start rounded-md"
                        style={style('/users')}
                    >
                        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                        </svg>
                        Users
                    </Navbar.Link>)}
                {user.permissions.includes(Permission.can_view_templates) && (<Navbar.Link
                    href="/templates"
                    className="text-wsblue-100 flex justify-start rounded-md"
                    style={style('/templates')}>
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                        ></path>
                    </svg>
                    Templates
                </Navbar.Link>)}
                <Navbar.Link
                    href="/help"
                    className="text-wsblue-100 flex justify-start rounded-md"
                    style={style('/help')}>
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                        ></path>
                    </svg>
                    Help
                </Navbar.Link>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header