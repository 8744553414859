import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import Select from 'react-select'
import {useSelector} from 'react-redux';
import Foot from '../../pages/Layouts/Footer'
import Header from '../../pages/Layouts/Header'
import {FileUploader} from "react-drag-drop-files";
import * as api from '../../apis/APIClient';
import {
    Button,
    Label,
} from 'flowbite-react'

import * as Yup from "yup";
import {useFormik, Formik, Form} from "formik";
import DownloadLink from "react-download-link";
import {files as templates} from "./Templates";


const BulkUpload = ({layer}) => {
    const fileTypes = ["ZIP"];
    const [files, setFiles] = useState([]);
    const [textColor, setTextColor] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(null);
    const {user} = useSelector((state) => state.auth);
    const category = user.providers ? user.providers[0]?.category : {};
    const workspace = 'GENERAL'
    const green = "text-green-600"
    const red = "text-red-600"

    const templateFile = templates.find(template => template.code === layer.code)?.file

    return (<div className="container mx-auto py-12 text-right">
            <Formik
                initialValues={{
                    files: null,
                    layer: layer.code,
                }}
                validationSchema={Yup.object({
                    files: Yup.array().nullable().required('Please upload zipped shapefiles(s)'),
                })}

                onSubmit={(values, {resetForm}) => {
                    const {files} = values

                    files.forEach((file) => {
                        let params = {
                            file: file,
                            layer: layer.code,
                            provider: user.userOrg
                        }

                        setMessage(null)
                        setLoading(true)
                        api.uploadFile(params, (response, error) => {
                            setLoading(false)
                            if (response) {
                                setMessage(response)
                                setTextColor(green)
                                resetForm({values: ''})
                            } else if (error) {
                                setMessage(error)
                                setTextColor(red)
                            }
                        })
                    })
                }}>

                {formik => (<>
                    <div className="p2">
                        {templateFile ? <a className="p-2 text-sm text-blue-600 underline"
                                           href={templateFile} download>Click to download template</a> :
                            <p className="p-2 text-sm">Template not available</p>

                        }

                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="grid place-items-center">
                            <FileUploader handleChange={(uploaded) => {
                                let files = formik.values.files ?? []
                                for (let i = 0; i < uploaded.length; i++) {
                                    files.push(uploaded[i])
                                }

                                formik.setFieldValue("files", files)
                            }}
                                          multiple={true}
                                          fileOrFiles={formik.values.files?.length > 0 ? formik.values.files : null}
                                          label={"Upload or drop zipped shapefiles here"} name="file"
                                          types={fileTypes}
                                          invalid={formik.touched.files && formik.errors.files ? "true" : "false"}/>

                            {formik.touched.files && formik.errors.files ? (
                                <p type="invalid"
                                   className="text-red-600 text-[0.9rem]">{formik.errors.files}</p>
                            ) : null}


                            {formik.values.files && (<div className="relative overflow-x-auto mt-3">
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <tbody>
                                    {formik.values.files.map((file, index) => (
                                        <tr key={index} className="border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td scope="row"
                                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {file.name}
                                            </td>
                                            <td scope="row"
                                                className="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap dark:text-white">
                                                {`${(file.size / (1000000))}Mb`.toLocaleString(undefined, {
                                                    maximumFractionDigits: 2,
                                                })}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>)}


                            {loading && (
                                <div role="status" className="relative overflow-x-auto mt-3">
                                    <svg aria-hidden="true"
                                         className="!absolute z-[9999999999999] h-20 mx-auto w-screen top-1/2 text-gray-200 animate-spin dark:text-gray-600 fill-wsblue-700"
                                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"/>
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"/>
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            )}

                            <div className="basis-1/12 mt-3">
                                <Button type="submit"
                                        className="bg-slate-500 hover:bg-slate-400 text-white rounded-md  float-left  ml-2 mb-2">
                                    Submit
                                </Button>
                            </div>

                        </div>
                    </form>
                </>)}
            </Formik>

            {message && (<div className="mt-3 grid place-items-center"><p
                className={`${textColor} text-[1.2rem]`}>{message}</p></div>)}
        </div>
    );
}

export default BulkUpload