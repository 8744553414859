import React, {useState} from 'react'
import Header from '../Layouts/Header'
import Foot from '../Layouts/Footer'
import VerticalTab from "../../components/VerticalTab/VerticalTab";
import user_guide from "../../assets/files/user_guide.pdf";
import parse from 'html-react-parser'

const Help = () => {
    const content = [
        {
            title: "Getting Started",
            topics: [
                {
                    title: 'Prerequisites',
                    description: "This is a geospatial system. You need intermediary geospaspatial knowledge",

                },
            ]
        },
        {
            title: "Digitization",
            topics: [
                {
                    title: 'Introduction',
                    description: "This section describes how to add features to layers, edit and delete. The digitization action buttons are provided against each  layer and you access them by clicking the 3-dots to the right of the layer in the layers panel.",
                },
                {
                    title: 'Adding Features',
                    description: 'Adding a new feature requires drawing the feature on to the layer map. Please note that <b> you can only draw the the feature type (Point, Line or Polygon) </b> that is already predefined for the selected layer. For instance, you can only draw polygons if the selected layer is defined for polygons',
                    steps: [
                        "Click on the <b>3-dots</b> next to the layer of choice. A list of action buttons appears",
                        "Click on the <b>Add Feature</b> from the list of action buttons. The map reloads and changes the base map to a Satellite map. The mouse also receives a blue dot denoting that you are ready to start drawing on the map",
                        "Depending on the layer selected, you will be able to draw only the type of feature available for the selected layer. <br/>" +
                        "<ul><li class='pl-10'>To Draw a <b>POINT</b>, click on the location on the map </li>" +
                        "<li class='pl-10'>To Draw a <b>LINE</b>, click on the start location then click on the end location</li>" +
                        "<li class='pl-10'>To Draw a <b>POLYGON</b>, click on the start and keep clicking on the all vertices until you come to where you started</li><ul>",
                        "On completing your drawing, a form will popup automatillcay with fields for you to provide the attribute details of the new feature. The form has labels and tooltips to help describe what each form does",
                        "Fill in the form appropriately and submit"
                    ]
                },
                {
                    title: 'Editing Features',
                    description: "Editing a feature can be done either on the geometry, the the attributes or on both. ",
                    steps: [
                        "Click on the <b>3-dots</b> next to the layer of choice. A list of action buttons appears",
                        "Click on the <b>Edit Features</b> from the list of action buttons. The map reloads and changes the base map to a Satellite map. All the features on the layer are loaded",
                        "Select the feature of choice. The selected feature gets highlighted and the editing form also appears",
                        "To edit the geometry of a polygon, move the mouse to the edges. A dot will be add on the edge where your mouse is pointing. You can then dag and drop the edges to increase or decrease the area of the polygon",
                        "To update the attributes, use the editing form provided",
                        "Click on save to submit and saave your changes"

                    ]
                },
                {
                    title: 'Deleting Features',
                    description: "Use this action to remove features from a layer",
                    steps: [
                        "Click on the <b>3-dots</b> next to the layer of choice. A list of action buttons appears",
                        "Click on the <b>Delete Features</b> from the list of action buttons. The map reloads and changes the base map to a Satellite map. All the features on the layer are loaded",
                        "Select the feaure you want to delete. To delete multiple features, press and hold <b>SHIFT</b> key while selecting the features. The selected features will be highlighted and a counter on the the delete button will be updated to show the number of selected features",
                        "To deselect a feature or features, just click on it again. If deselecting multiple features, press and hold <b>SHIFT</b> key while clicking on the selected features",
                        "Click on the delete button on the editing panel. A confirmation popup window appears",
                        "Click on Yes to delete all the selected features."

                    ]
                },
            ]
        },
        {
            title: "Bulk Upload",
            topics: [
                {
                    title: 'Bulk Upload',
                    description: "This functionality is meant for importing data from an external source into MajiData. You can only upload zipped shapefiles",
                },
                {
                    title: 'Templates',
                    description: "We have provided templates for each layer to ensure that your data is stuctured in the format expected by the MajiData system. ",
                },
                {
                    title: 'How to do bulk upload',
                    steps: [
                        "Click on the <b>3-dots</b> next to the layer of choice. A list of action buttons appears",
                        "Click on the <b>bulk Upload</b> from the list of action buttons. The map reloads and changes the base map to a Satellite map. All the features on the layer are loaded",
                        "The editing window appears with a field to upload the zipped shapefile",
                        "If you hadn't downloaded a template yet, you can click on  <b>Click to download template </b> link provided in the editing window",
                        "Upload your zipped file(s) by dragging and dropping them into the dotted area in the editing window. You can also click on the dotted area to select the files from your computer ",
                        "Click on the <b>upload </b> to upload the file(s)"

                    ]
                },
            ]
        },
        {
            title: "Feature Selection",
            topics: [
                {
                    title: 'What is feature selection?',
                    description: "This functionality allows you to perform advanced search on a layer of interest.",
                },
                {
                    title: 'How To Select',
                    steps: [
                        "On the map page, click on the selection button on the left side bar. The selection panel appears",
                        "On the dropdown, select the layer of interest",
                        "Below the layers dropdown, there is a plus (+). Use this button to add a selection criteria",
                        "On clicking the plus (+) button, a row with following details appears" +
                        "<ul class='py-3 px-10'> " +
                        "<li><b>Layer Attributes</b> This is a dropdown of all the fields/properties on the layer that you can use to perform your search</li>" +
                        "<li><b>Operators</b> These are the conditions to apply on the selected property</li>" +
                        "<li><b>Search Term</b> This is the value you want to filter with when performing the search</li>" +
                        "</ul>",
                        "If you add more more than one search criteria, a set of addional operators <b>(AND / OR) </b>  will appear. These are used to join between the multiple criteria provided",
                        "Click on  the  <b>Search</b> button to perform your query. On successful querying, the matched features will be displayed and highlighted on the map. The attributes table will also show at the bottom of the page. If no records matching your query were found, an error message will be displayed",

                    ]
                },
            ]
        },
        {
            title: "Submitting Requests",
            topics: [
                {
                    title: "Submissions and Approvals",
                    description: "All features added, edited or deleted will require approval from WASREB.",
                },
                {
                    title: 'Edits',
                    description: "This refers to  changes (adding, editing or deletions) that have been done on any layer but have not been submitted for approvals. These changes are only visible to the organisation that created them. Please note that when you add features via bulk upload, an edit will be added automoatically for ease of submission",
                },
                {
                    title: 'Submitting Approval Request',
                    steps: [
                        "Click on <b>Requests</b> on the navigation  bar at the top of the page. A dropdown appears",
                        "Click on <b>Edits</b> from the dropdown menu. A list of edits appears",
                        "Select the checkbox next to the edits you to submit",
                        "Click on the actions button and select <b>Submit</b>. A popup window appears",
                        "Enter remarks in the popup window and submit"
                    ]
                }
            ]
        },
        {
            title: "Approvals & Publishing",
            topics: [
                {
                    title: 'Approving Submission Requests <b>(WASREB ONLY) </b>',
                    steps: [
                        "Click on <b>Submissions</b> on the navigation  bar at the top of the page. A list of submissions appear",
                        "On the submission you want to approve, under the actions column, select <b>Review</b>. Please note that this action button only appears if the status of the selected submission is <b>Submitted</b>",
                        "On the submission details page that appears, 3 buttons <b>(Approve, Reject, Ask for modification) </b> are provided. Please note that this buttons only appear if you are the reviewer of the of the submission",
                        "Click on the one of the button and a popup window will appear asking you to provide remarks for the selected action",
                        "Enter remarks in the popup window and submit"
                    ]
                },
                {
                    title: 'Publishing Approved Requests <b>(WASREB ONLY) </b>',
                    steps: [
                        "Click on <b>Submissions</b> on the navigation  bar at the top of the page. A list of submissions appear",
                        "On the submission you want to approve, under the actions column, select <b>Review</b>. Please note that this action button only appears if the status of the selected submission is <b>Submitted</b>",
                        "On the submission details page that appears, 3 buttons <b>(Approve, Reject, Ask for modification) </b> are provided. Please note that this buttons only appear if you are the reviewer of the of the submission",
                        "Click on a button and a popup window will appear asking you to provide remarks for the selected action",
                        "Enter remarks in the popup window and submit"
                    ]
                },
            ]
        },
        {
            title: "Requests for Data",
            topics: [
                {
                    title: 'Processing Requests for Data<b>(WASREB ONLY) ',
                    description: "Data requests are made by users from the public website. <br/> The actual processing of these requests will happen outside the MajiData system. The WASREB admin is expected to utilise the information provided in the request to aggreagate the information from relevant sources and send the information via email to the requesting user. However other than viewing the requests, the WASREB admin can change the status of the request to match the current processing of the the user request",
                    steps: [
                        "Click on <b>Request for Data</b> on the navigation  bar at the top of the page. A list of requests appear",
                        "On the request of interest, select change status button. A popup window appears",
                        "Select the current status of the request and submit"
                    ],

                },
            ]
        },
        {
            title: "User Management",
            topics: [
                {
                    title: 'Introduction',
                    description: "This section is used to create, update, and delete users",
                },
                {
                    title: 'Adding a New User',
                    steps: [
                        "Click on <b>Users</b> link on the navigation bar at the top of the page. A list of users appear",
                        "Click on the <b>Add User</b> on the users page. A popup window with a user details forms appears",
                        "Fill in the details of the users <b>(First Name, Last Name, Email Address, Role, Password).</b> Password is optional",
                        "Click on the <b>Add</b> button. The request will be submitted and the user will receive an email will the login details on the email address provided"
                    ]
                },
                {
                    title: 'Update User',
                    steps: [
                        "Click on <b>Users</b> link on the navigation bar at the top of the page. A list of users appear",
                        "On the row of the user to update, select the <b>Update</b> button. The update page will appear",
                        "Fill in the details of the users <b>(First Name, Last Name, Role).</b>",
                        "Click on the <b>Save</b> button. The request will be submitted and a success message will be displayed"
                    ]
                },
                {
                    title: 'delete User',
                    steps: [
                        "Click on <b>Users</b> link on the navigation bar at the top of the page. A list of users appear",
                        "On the row of the user to update, select the <b>Delete</b> button. A confirmation popup will appear",
                        "Click on the <b>Yes</b> button to delete the user"
                    ]
                },
            ]
        }
    ];


    return (
        <div className="text-gray-500 bg-gray-100 font-body min-h-screen flex flex-col justify-center align-middle">
            <Header/>
            <main className="flex-grow">
                <div className="container mx-auto py-12">
                    <h1 className="text-lg dashboard-heading">Help</h1>
                    <p className="text-right p-3">
                        <a className="font-medium text-blue-600 underline" download href={user_guide}>Click to
                            download user guide.pdf</a>
                    </p>
                    <div className="container mx-auto py-12">
                        <VerticalTab titles={content.map(content => content.title)} content={
                            content.map(content => (
                                content.topics.map((topic, index) => (
                                    <div key={index}
                                         className="py-2">
                                        <h5 className="font-bold">{parse(topic.title)}</h5>
                                        {topic.description && (<p>{parse(topic.description)}</p>)}
                                        {topic.steps && (
                                            <div>
                                                <h6 className="font-bold">Steps</h6>
                                                {topic.steps && topic.steps.map((step, index) => (
                                                    <div key={index}
                                                         className="section__Jobs-detail">{parse(step)}</div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))
                            ))}/>
                    </div>
                </div>
            </main>
            <Foot className="bg-blue-900 py-4 text-white">
                <div className="container mx-auto text-center">
                    <p className="text-sm">Wasreb 2022 All Rights Reserved</p>
                </div>
            </Foot>
        </div>
    )
}

export default Help