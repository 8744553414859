import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import {useLocation} from 'react-router-dom';
import {Tabs} from 'flowbite-react'
import Header from '../Layouts/Header';
import Foot from '../Layouts/Footer';
import {messages} from '../../property_mapping';
import {api, workspace} from '../../config';
import * as client from '../../apis/APIClient';
import * as url from "../../helpers/url_helper";
import MapComponent from '../../components/Map'
import {GeoJSON} from "ol/format";
import {CircularProgress} from '@mui/material';
import MUIDataTable from "mui-datatables";
import moment from 'moment-timezone'
import {Dropdown, Button, Modal, Label, Textarea, TextInput} from 'flowbite-react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useNavigate} from 'react-router-dom';

const UsersList = ({provider}) => {
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState()
    const columns = [
        {
            name: "firstName",
            label: "First Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "lastName",
            label: "Last Name",
            options: {
                filter: true,
                sort: false,
            }
        }, {
            name: "email",
            label: "Email Address",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "roleNames",
            label: "Roles",
            options: {
                customBodyRender: (value) => {
                    return value.join(', ');
                },
                sort: false,
            },
        },

        {
            name: "permissions",
            label: "Permissions",

            options: {
                setCellProps: () => ({style: {width: "20%"}}),
                customBodyRender: (value) => {
                    return value.map(p => p.replaceAll('_', ' ')).join(', ');
                },
                sort: false,
            },
        }
    ];

    const tableOptions = {
        filterType: 'dropdown',
        download: false,
        print: false,
        selectableRows: 'none',
    };

    useEffect(() => {
        client.fetchUsers({orgId: provider.id}, function (response, error) {
            setUsers(response.reverse());
        })
    }, [])

    return (<MUIDataTable
            title={
                <p>
                    Users
                    {loading && <CircularProgress size={24} style={{
                        marginLeft: 15,
                        position: 'relative',
                        top: 4
                    }}/>}
                </p>
            }
            data={users}
            columns={columns}
            options={tableOptions}
        />
    )
}

const SubmissionsList = ({provider}) => {
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState()

    const navigate = useNavigate()


    const stages = 'SUBMITTED,REVIEW,CHANGE_REQUEST,REJECTED,APPROVED,PUBLISHED';

    const columns = [
        {
            name: 'submittedAt',
            label: 'Date',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return moment.utc(value).tz('Africa/Nairobi').format('DD MMM YYYY hh:mma');
                }
            }
        },
        {
            name: 'notes',
            label: 'Name',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return value.map(note => note.note).join(', ');
                }
            }
        },
        {
            name: 'provider',
            label: 'Org Name',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return value.fullName;
                }
            }
        },

        {
            name: 'submittedBy',
            label: 'Submitted By',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return value;
                }
            }
        },
        {
            name: 'reviewedBy',
            label: 'Reviewer',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "id",
            label: "Action",
            options: {
                customBodyRender: (value, rowMeta, updateValue) => {
                    let row = rows[rowMeta.rowIndex]
                    return (
                        <div className="mb-6  relative">
                            <Dropdown  className="z-5000"   label="Actions" inline size="sm" >
                                <Dropdown.Item onClick={()=>{navigate(`/submissions/${row.id}`, { state: {feature: row}})}}>
                                    View
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    );
                },
                sort: false,
            },
        },
    ]

    const tableOptions = {
        filterType: 'dropdown',
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        selectableRows: 'none',
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            let row = rows[rowMeta.rowIndex]
            console.log('row', row);
            return (
                <TableRow>
                    <TableCell colSpan={rowData.length + 1}>
                        <Table size="small" className="w-full">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Layer</TableCell>
                                    <TableCell>Notes</TableCell>
                                    <TableCell>Date Created</TableCell>
                                    <TableCell>Created By</TableCell>
                                    <TableCell>Action</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row.layerTasks.map((layerTask) => (
                                    <TableRow key={layerTask.id}>
                                        <TableCell>{layerTask.layerTitle}</TableCell>
                                        <TableCell>{layerTask.notes?.map(note => note.note).join(',  ')}</TableCell>
                                        <TableCell>{moment.utc(layerTask.audit.createdAt).tz('Africa/Nairobi').format('DD MMM YYYY hh:mma')}</TableCell>
                                        <TableCell>{layerTask.audit.createdBy}</TableCell>
                                        <TableCell>{layerTask.taskAction}</TableCell>
                                        <TableCell>{layerTask.taskStatus}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>
            );
        }
    };

    useEffect(() => {
        client.fetchSubmissions({provider: provider.id,  statuses: stages}, function (data, error) {
            console.log(data.content);
            if (data.content) {
                setRows(data.content)
            } else {
                setRows(data.reverse())
            }
        })
    }, []);

    return (<MUIDataTable
            title={
                <p>
                    {loading && <CircularProgress size={24} style={{
                        marginLeft: 15,
                        position: 'relative',
                        top: 4
                    }}/>}
                </p>
            }
            data={rows}
            columns={columns}
            options={tableOptions}
        />
    )
}

const ProviderDetails = () => {
    const [activeTab, setActiveTab] = useState(0);
    const tabsRef = useRef(null);
    const location = useLocation();
    const [provider, setProvider] = useState()
    const [properties, setProperties] = useState()
    const [feature, setFeature] = useState()
    const row = location.state.row;
    const excludeProps = ['the_geom', 'geometry', 'county_id', 'wsp_id', 'gid', 'wwda_id']
    useEffect(() => {
        let layer, properties, cql, feature
        console.log('provider', row)
        switch (row.category) {
            case 'WSP':
                layer = 'wsp_licensed_service_area'
                cql = `wsp_id=${row.id}`
                break
            case 'SSSP':
                layer = 'sssp_service_area'
                cql = `sssp_id=${row.id}`
                break
            case 'COUNTY':
                layer = 'county'
                cql = `county_id=${row.countyId}`
                break
        }
        if (layer) {
            let params = {
                service: 'WFS',
                version: '1.0.0',
                request: 'GetFeature',
                typeName: `${workspace}:${layer}`,
                outputFormat: 'application/json',
                CQL_FILTER: cql
            }
            let layerUrl = `${api.GEOSERVER_URL + url.GET_WFS}?${client.formData(params)}`
            console.log('params', params)
            fetch(layerUrl)
                .then(res => res.json())
                .then((response) => {
                    if (response.features.length) {
                        feature = new GeoJSON().readFeatures(response)[0]
                        let mapping = messages.find(message => message[layer])
                        if (mapping) {
                            properties = mapping[layer]?.filter(column => !excludeProps.includes(column.column_name.toLowerCase())).map(column => {
                                let name = column.column_name.toLowerCase()
                                console.log(name, feature.get(name))
                                return {
                                    name: name,
                                    label: column.display_name,
                                    value: feature.get(name)
                                }
                            }).filter(item => item.value && item.value !== 'Null')
                        } else {
                            properties = Object.entries(feature.getProperties()).filter(prop => !excludeProps.includes(prop[0])).map(prop => {
                                let name = prop[0].replaceAll('_', ' ')
                                return {
                                    name: name,
                                    label: name,
                                    value: prop[1]
                                }
                            }).filter(item => item.value && item.value !== 'Null')
                        }
                        console.log('feature', feature)
                        console.log('data', properties)
                        setProperties(properties)
                        setFeature(feature)
                    }
                }, (error) => {
                    console.log('error', error)
                })

        }
        setProvider(row)

    }, []);


    return (
        <div className="text-gray-500 bg-gray-100 font-body min-h-screen flex flex-col justify-center align-middle">
            <Header/>
            <main className="flex-grow">
                <div className="container mx-auto pt-12">
                    <h1 className="text-lg dashboard-heading">{provider?.fullName}</h1>

                    <div className="container mx-auto pt-3">
                        <Tabs.Group
                            style="underline"
                            onActiveTabChange={tab => setActiveTab(tab)}
                        >
                            <Tabs.Item title={<span>Details</span>}>
                                <div className='flex'>
                                    <div className="basis-8/12 rounded-md bg-white overflow-hidden">
                                        {provider && (<MapComponent height={65} provider={provider}/>)}
                                    </div>
                                    <div className="basis-4/12  bg-white overflow-scroll" style={{height: 65 + 'vh'}}>
                                        <table
                                            className="w-96 text-sm text-left text-gray-500 dark:text-gray-400 w-full">
                                            <thead
                                                className="text-xs text-white uppercase bg-wsblue-600 dark:bg-wsblue-600 dark:text-gray-400">
                                            <tr>
                                                <th scope="col-4" className="px-6 py-3">Property</th>
                                                <th scope="col-8">Value</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {properties && properties.map((property, index) => (
                                                    <tr key={index}
                                                        className="bg-white  p-2 border-b dark:bg-gray-800 dark:border-gray-700">
                                                        <td scope="row"
                                                            className="px-6 py-2 font-medium text-gray-900 dark:text-white whitespace-nowrap">{property.label}</td>
                                                        <td>{property.value}</td>
                                                    </tr>
                                                )
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Tabs.Item>

                            <Tabs.Item title={<span>Users</span>}>
                                {provider && (<UsersList provider={provider}/>)}
                            </Tabs.Item>
                            <Tabs.Item title={<span>Submissions</span>}>
                                {provider && (<SubmissionsList provider={provider}/>)}
                            </Tabs.Item>
                        </Tabs.Group>
                    </div>
                </div>
            </main>
            <Foot className="bg-blue-900 py-4 text-white">
                <div className="container mx-auto text-center">
                    <p className="text-sm">Wasreb 2022 All Rights Reserved</p>
                </div>
            </Foot>
        </div>
    )
}

export default ProviderDetails