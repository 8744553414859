import * as control from "ol/control";
import DOM from '../helpers/Browser/DOM';
import {Control} from 'ol/control';
import {DragPan} from 'ol/interaction';
import {listen} from 'ol/events';
import {SVGPaths, getIcon} from '../core/Icons';
import {isShortcutKeyOnly} from '../helpers/ShortcutKeyOnly';
import Toast from '../common/Toast';

const DEFAULT_OPTIONS = {};

class Pan extends Control {
    constructor(options = {}) {
        let toolbox = document.getElementById('ol-toolbox');
        super({
            element: document.getElementById(options.element),
            target: document.getElementById(options.target)
        });
        this.state = {layers: []};
        this.options = {...DEFAULT_OPTIONS, ...options};

        const icon = getIcon({
            view: {
                width: 1024,
                height: 1024
            },
            path: SVGPaths.Pan,
            class: 'oltb-tool-button__icon'
        });



        const button = DOM.createElement({
            element: 'button',
            html: icon,
            class: 'oltb-tool-button',
            attributes: {
                type: 'button',
                'data-tippy-content': 'Pan (P)'
            },
            listeners: {
                'click': this.handlePan.bind(this)
            }
        });
        this.mapElement = document.getElementById('ol-map');

        this.element.appendChild(button);
        this.button = button;
        this.active = false;

        window.addEventListener('keyup', (event) => {
            if (isShortcutKeyOnly(event, 'p')) {
                this.handlePan(event);
            }
        });
    }

    handlePan() {
        if(this.active) {
            this.getMap().removeInteraction(this.interaction);
            this.mapElement.style.cursor = "inherit";
        }else{
            this.pan()
            this.mapElement.style.cursor = "grab";
        }
        this.active = !this.active;
        this.button.classList.toggle('oltb-tool-button--active');
    }
    pan() {
        const map = this.getMap();
        if (this.interaction) {
            map.removeInteraction(this.interaction);
        }
        this.interaction = new DragPan();
        map.addInteraction(this.interaction);
    }
}

export default Pan;



