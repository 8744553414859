import React, {useMemo} from 'react';
import {api, Permission,adminRoles,userRoles} from "../../../config";
import {useEffect, useState} from 'react';
import Pagination from '../../../components/Pagination';
import * as url from "../../../helpers/url_helper";
import Header from '../../Layouts/Header';
import {Checkbox, Table, Button, Label, Modal, TextInput, Dropdown} from 'flowbite-react'
import Foot from '../../Layouts/Footer';
import Select from 'react-select';
import * as Yup from "yup";
import {useFormik} from "formik";
import * as client from '../../../apis/APIClient';
import {useNavigate} from 'react-router-dom';
import {FiEdit} from 'react-icons/fi';
import {MdOutlineDeleteOutline} from 'react-icons/md';
import {useLocation} from "react-router-dom";
import {CircularProgress} from '@mui/material';
import MUIDataTable from "mui-datatables";
import {useSelector} from 'react-redux';
import PasswordInput from "../../Authentication/PasswordInput";
import {ToastContainer, toast} from 'react-toastify';

const Users = () => {
    const {user} = useSelector((state) => state.auth);
    const [pending, setPending] = useState(true);
    const [rows, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [approvalModalVisible, setApprovalModalVisible] = useState(false);
    const [providers, setProviders] = useState([]);
    const [roles, setRoles] = useState(null);
    const [admin, setAdmin] = useState({});
    const [responseError, setresponseError] = useState(null);
    const [error, setError] = useState(null);
    const [loadingProviders, setLoadingProviders] = useState(true);
    const [loadingCreateUser, setLoadingCreateUser] = useState(false);
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const type = new URLSearchParams(useLocation().search).get('type');

    const notify = (message) => toast.success(message ?? "Operation successful!");
    const warnNotify = (message) => toast.error(message ??  "An error occured");


    const navigate = useNavigate();

    client.fetchRoles().then(value => {
            console.log(value);
        }
    );

    const columns = [
        {
            name: "firstName",
            label: "First Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "lastName",
            label: "Last Name",
            options: {
                filter: true,
                sort: false,
            }
        }, {
            name: "email",
            label: "Email Address",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "userOrg",
            label: "Org",
            options: {
                customBodyRender: (value) => {
                    return value.fullName;
                },
                sort: false,
            },
        },
        {
            name: "roleNames",
            label: "Roles",
            options: {
                customBodyRender: (value) => {
                    return value.join(', ');
                },
                sort: false,
            },
        },
        {
            name: "enabled",
            label: "Active",

            options: {
                customBodyRender: (value) => {
                    return  value ? 'Yes': 'No';
                },
                sort: false,
            },
        },

        {
            name: "permissions",
            label: "Permissions",

            options: {
                setCellProps: () => ({style: {width: "20%"}}),
                customBodyRender: (value) => {
                    return value.map(p => p.replaceAll('_', ' ')).join(', ');
                },
            },
        },

        {
            name: "id",
            label: "Action",
            options: {
                print: false,
                download: false,
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let row = rows[tableMeta.rowIndex]
                    return ( user.id !== value && user.permissions.includes(Permission.can_manage_users)  && (
                        <Dropdown label="Action" inline className="z-5000">

                            {user.permissions.includes(Permission.can_manage_users) &&  (<Dropdown.Item onClick={() => {navigate(`/users/${value}`, {state: {id: value}})}}>
                                    Update
                                </Dropdown.Item>)}
                            {user.permissions.includes(Permission.can_manage_users) &&  (<Dropdown.Item onClick={() => {setConfirmDeleteVisible(row)}}>
                                {row.enabled ? 'Deactivate' : 'Activate'}
                            </Dropdown.Item>)}
                        </Dropdown>
                        ));
                },
            },
        },
    ];

    const tableOptions = {
        filterType: 'dropdown',
        download: false,
        print: false,
        selectableRows: 'none',
    };

    useEffect(() => {
        loadData()
    }, [type, approvalModalVisible]);

    useEffect(() => {
        if (user.userOrg.category === "WASREB" && type !== 'ADMIN') {
            client.fetchProviders({type: type}, function (data, error) {
                if (data) {
                    console.log(data)
                    setLoadingProviders(false);
                    setProviders([{
                        label: 'Service Providers',
                        options: data.map(provider => ({
                                value: provider.id,
                                label: provider.fullName
                            })
                        )
                    }])
                }
            })
        }
    }, [])


    const loadData = () => {
        if (!approvalModalVisible) {
            let params = {};

            if (type) {
                params.category = type !== 'ADMIN' ? type : 'WASREB'
            } else if (user.userOrg.category !== 'WASREB') {
                params.orgId = user.userOrg.id
            }
            client.fetchUsers(params, function (response, error) {
                console.log('response', response)
                if (response?.errors) {
                    setresponseError(response.errors);
                    setIsLoading(false);
                    return;
                }
                setPending(false);
                setIsLoading(false);
                setRows(response.reverse());
            })
        }
    }


    const validation = useFormik({
            enableReinitialize: true,
            initialValues: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                roles: '',
                category: type !== 'ADMIN' ? type : 'WASREB',
                providers: [user.userOrg.id] || '',
                redirectUrl: process.env.REACT_APP_URL + "/login"
            },
            validationSchema: Yup.object({
                firstName: Yup.string().required("First Name is required"),
                lastName: Yup.string().required("Last Name is required"),
                email: Yup.string().email().required("Email Address is required"),
                password: Yup.string()
                    .min(6, 'at least 6 chars')
                    .matches(/[a-z]/, 'at least one lowercase char')
                    .matches(/[A-Z]/, 'at least one uppercase char')
                    .matches(/[0-9]/, 'at least one number') .required("Password is required"),
                roles: Yup.array().required("Role is required"),
                providers:Yup.array().when("category", {
                    is: (category) => {
                        console.log({ category: category });
                        return category !== "ADMIN";
                    },
                    then: ()=> Yup.array().required("Provider is required"),
                })
            }),
            onSubmit: (values, {resetForm}) => {
                console.log('onSubmit',JSON.stringify(values));
                setError()
                setLoadingCreateUser(true);
                client.createUser(values, function (data, error) {
                    setLoadingCreateUser(false);
                    console.log('data', data)
                    if (data) {
                        resetForm({values: ''})
                        setApprovalModalVisible(false)
                    //    loadData()
                    } else {
                        setError(error)
                    }
                })
            }
        })
    ;

    const options =  type === 'ADMIN' ? adminRoles.map(role => {return {value: role, label: role}}) : userRoles.map(role => {return {value: role, label: role}})

    const deactivateUser = () => {
        client.deactivateUser({id: confirmDeleteVisible.id}, (data, error)=>{
            if(error){
                warnNotify(error)
            }else{
                notify()
                setConfirmDeleteVisible(false)
                loadData()
            }
        });
    };

    const activateUser = () => {
        client.activateUser({id: confirmDeleteVisible.id}, (data, error)=>{
            if(error){
                warnNotify(error)
            }else{
                notify()
                setConfirmDeleteVisible(false)
                loadData()
            }
        });
    };

    document.title = "Users | Maji Data";

    return (
        <div className="text-gray-500 bg-gray-100 font-body min-h-screen flex flex-col justify-center align-middle">
            <Header/>
            <ToastContainer />
            <main className="flex-grow">
                <div className="container mx-auto py-12">
                    <h1 className="text-lg dashboard-heading mb-6 ">{type} Users</h1>
                    {(user.permissions.includes(Permission.can_manage_user) || user.permissions.includes(Permission.can_manage_users)) && (
                        <div className="flex justify-between items-center pb-4 bg-transparent">
                            <div className="py-2 px-4">
                                <button onClick={() => setApprovalModalVisible(true)}
                                        className="inline-flex items-center text-gray-500 bg-blue-100 border border-blue-300 focus:outline-none hover:bg-blue-200 focus:ring-4 focus:ring-gray-200 font-medium text-sm px-3 py-1.5 rounded-sm"
                                        type="button">
                                    Add User
                                </button>
                            </div>
                        </div>)}
                    <MUIDataTable
                        title={
                            <p>
                                Users
                                {isLoading && <CircularProgress size={24} style={{
                                    marginLeft: 15,
                                    position: 'relative',
                                    top: 4
                                }}/>}
                            </p>
                        }
                        data={rows}
                        columns={columns}
                        options={tableOptions}
                    />
                </div>
            </main>
            <Foot/>

            <Modal
                show={approvalModalVisible}
                size="md"
                onClose={() => setApprovalModalVisible(false)}
            >
                <Modal.Header className="bg-wsblue-600">
                    <span className="text-wsblue-100">Create User</span>
                </Modal.Header>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <Modal.Body>
                        <div className="p-2">
                            <div className="mb-4">
                                <div className="block uppercase font-bold">
                                    <Label htmlFor="firstName" value="First Name"/>
                                </div>
                                <TextInput
                                    id="firstName"
                                    name="firstName"
                                    placeholder="Enter First Name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.firstName || ""}
                                    invalid={validation.touched.firstName && validation.errors.firstName ? "true" : "false"}
                                />
                                {(validation.touched.firstName && validation.errors.firstName) && (
                                    <p type="invalid"
                                       className="text-red-600 text-[0.9rem]">{validation.errors.firstName}</p>
                                )}
                            </div>
                            <div className="mb-4">
                                <div className="block uppercase font-bold">
                                    <Label htmlFor="lastName" value="Last Name"/>
                                </div>
                                <TextInput
                                    id="lastName"
                                    name='lastName'
                                    placeholder="Enter Last Name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lastName || ""}
                                    invalid={validation.touched.lastName && validation.errors.lastName ? "true" : "false"}
                                />
                                {validation.touched.lastName && validation.errors.lastName && (
                                    <p type="invalid"
                                       className="text-red-600 text-[0.9rem]">{validation.errors.lastName}</p>
                                )}
                            </div>
                            <div className="mb-4">
                                <div className="block uppercase font-bold">
                                    <Label htmlFor="email" value="Email Address"/>
                                </div>
                                <TextInput
                                    id="email"
                                    name='email'
                                    placeholder="Enter Email Address"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={validation.touched.email && validation.errors.email ? "true" : "false"}
                                />
                                {validation.touched.email && validation.errors.email && (
                                    <p type="invalid"
                                       className="text-red-600 text-[0.9rem]">{validation.errors.email}</p>
                                )}
                            </div>
                            {user.userOrg.category === "WASREB" && type !==  'ADMIN' && (
                                <div className="mb-4">
                                    <div className="block uppercase font-bold">
                                        <Label htmlFor="providers" value="Providers"/>
                                    </div>
                                    <Select
                                        name="providerId"
                                        options={providers}
                                        isLoading={loadingProviders}
                                        onBlur={validation.handleBlur}
                                        onChange={(provider) => {
                                            console.log(provider)
                                            validation.setFieldValue("providers", [provider.value])
                                        }}
                                        invalid={validation.touched.providers && validation.errors.providers ? "true" : "false"}
                                    />
                                    {validation.touched.providers && validation.errors.providers && (
                                        <p type="invalid"
                                           className="text-red-600 text-[0.9rem]">{validation.errors.providers}</p>
                                    )}
                                </div>)}
                            <div className="mb-4">
                                <div className="block uppercase font-bold">
                                    <Label htmlFor="roles" value="Roles"/>
                                </div>
                                <Select
                                    options={options}
                                    isMulti
                                    name='roles'
                                    onChange={(e) => {
                                        setRoles(e.map(role => role.value));
                                        validation.setFieldValue("roles", e.map(role => role.value));
                                    }}
                                    onBlur={validation.handleBlur}
                                    invalid={validation.touched.roleNames && validation.errors.roleNames ? "true" : "false"}
                                />
                                {validation.touched.roles && validation.errors.roles && (
                                    <p type="invalid"
                                       className="text-red-600 text-[0.9rem]">{validation.errors.roles}</p>
                                )}
                            </div>
                            <div className="mb-4">
                                <div className="block uppercase font-bold">
                                    <Label htmlFor="password" value="Password"/>
                                </div>
                                <PasswordInput
                                    id="password"
                                    name="password"
                                    className="form-control"
                                    placeholder="Enter Password"
                                    type="password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.password || ""}
                                    invalid={validation.touched.password && validation.errors.password ? "true" : "false"}/>
                                {validation.touched.password && validation.errors.password && (
                                    <p type="invalid" className="text-red-600 text-[0.9rem]">{validation.errors.password}</p>)}
                            </div>
                            {error && (
                                <div className="mb-4 p-2">
                                    <p className="text-red-500">{error}</p>
                                </div>)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="bg-wsblue-600">
                        <Button type='submit'
                                className="bg-wsblue-300 hover:bg-wsblue-200 text-wsblue-100 rounded-md"
                                style={{backgroundColor: '#cbd5e1', color: '#1e293b'}}
                        >
                            {loadingCreateUser && <svg aria-hidden="true" role="status"
                                                       className="inline w-5 h-5 mr-1.5 text-[#1e293b] animate-spin"
                                                       viewBox="0 0 100 101" fill="none"
                                                       xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="#E5E7EB"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentColor"/>
                            </svg>}
                            Add
                        </Button>
                        <Button
                            onClick={() => setApprovalModalVisible(false)}
                            type="button"
                            className="bg-wsblue-500 hover:bg-wsblue-400 text-wsblue-100 rounded-md"
                            style={{backgroundColor: '#64748b', color: '#1e293b'}}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal
                show={confirmDeleteVisible}
                size="md"
                popup={true}
                onClose={() => setConfirmDeleteVisible(false)}
            >
                <Modal.Header/>
                <Modal.Body>
                    <div className="text-center">
                        <svg className="w-16 h-16 text-red-600 mx-auto mb-6" fill="none" stroke="currentColor"
                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                        </svg>
                        <h3 className="mb-5 font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to {confirmDeleteVisible.enabled ? 'Deactivate' : 'Activate'} this User?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <button data-modal-toggle="popup-modal" type="button"
                                    onClick={() => confirmDeleteVisible.enabled ? deactivateUser() : activateUser()}
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                Yes, I'm sure
                            </button>
                            <button data-modal-toggle="popup-modal" type="button"
                                    onClick={() => setConfirmDeleteVisible(false)}
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No,
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>)}

export default Users