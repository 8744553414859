import {useEffect, useState} from 'react'
import {useParams, useLocation, useNavigate} from "react-router-dom";
import {Button, Label, Modal, Textarea, TextInput, Radio} from 'flowbite-react'
import Header from './Layouts/Header'
import Foot from './Layouts/Footer'
import SingleSubmission from '../pages/Submissions/SingleSubmission'
import {api} from "../config";
import * as url from "../helpers/url_helper";
import * as client from '../apis/APIClient';
import moment from 'moment-timezone'


const ApprovalDetails = ({layerTask}) => {
    let {id} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [layer, setLayer] = useState(null);
    const [actions, setActions] = useState([]);

    useEffect(() => {
        if(layerTask){
            setLayer(layerTask)
        }else {
            client.fetchLayerTask(id, function (data, error) {
                setLayer(data)
                let actions = [];

                if (data.audit.createdAt) {
                    actions.push({
                        date: data.audit.createdAt,
                        action: 'Created',
                        actionBy: data.audit.createdBy,
                    })
                }

                if (data.audit.updatedAt) {
                    actions.push({
                        date: data.audit.updatedAt,
                        action: 'Created',
                        actionBy: data.audit.updatedBy,
                    })
                }

                if (data.submittedAt) {
                    actions.push({
                        date: data.submittedAt,
                        action: 'Submitted',
                        actionBy: data.submittedBy,
                    })
                }
                if (data.reviewAt) {
                    actions.push({
                        date: data.reviewAt,
                        action: 'Reviewed',
                        actionBy: data.reviewedBy,
                    })
                }
                if (data.approvedAt) {
                    actions.push({
                        date: data.approvedAt,
                        action: 'Approved',
                        actionBy: data.approvedBy,
                    })
                }
                if (data.rejectedAt) {
                    actions.push({
                        date: data.rejectedAt,
                        action: 'Rejected',
                        actionBy: data.rejectedBy,
                    })
                }
                actions.sort((a, b) => a.date - b.date)
                setActions(actions)
            })
        }
    }, [layerTask])

    return (
        <div className='flex flex-col min-h-screen'>
            <Header/>
            <div className="flex-grow">
                <div className="container mx-auto py-6">
                    <div className="rounded-md bg-white ml-6 overflow-hidden">
                        <div className="border-y border-gray-200 bg-gray-50">
                            <div>
                                <div
                                    className="flex w-full items-center justify-between first:rounded-t-lg last:rounded-b-lg py-5 px-5 text-left font-medium text-gray-500 dark:text-gray-400 hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:hover:bg-gray-800 dark:focus:ring-gray-800 text-gray-900 bg-gray-100 dark:bg-gray-800 dark:text-white">
                                    <h2>{layer?.provider?.fullName}</h2>

                                    <div>
                                        <button className="outline-button" type="button"
                                                onClick={() => navigate(-1)}>
                                            Back
                                        </button>
                                    </div>
                                </div>
                                <div className="relative overflow-x-auto sm:rounded-lg">
                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                        <tr>
                                            <td className="border border-slate-300  w-6/12">
                                                <ul className="p-3">
                                                    {layer?.notes?.map((note,index) => (
                                                        <li key={index} className="p-1">{note.note}</li>
                                                    ))}
                                                </ul>
                                                <p className="mb-2 text-gray-500">

                                                </p>
                                            </td>
                                            <td className="border border-slate-300  w-6/5">
                                                <table className="table-auto border-collapse  w-full">
                                                    <thead
                                                        className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3">Date</th>
                                                        <th scope="col" className="px-6 py-3">Action</th>
                                                        <th scope="col" className="px-6 py-3">Action by</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {actions.length > 0 && actions.map(action => (<tr>
                                                        <td className="px-6 py-1">{moment.utc(action.date).tz('Africa/Nairobi').format('DD MMM YYYY hh:mma')}</td>
                                                        <td className="px-6 py-1">{action.action}</td>
                                                        <td className="px-6 py-1">{action.actionBy}</td>
                                                    </tr>))}
                                                    </tbody>

                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            {layer && (
                                <SingleSubmission layerTask={layer}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Foot/>
        </div>
    )
}

export default ApprovalDetails