import React, {useEffect, useState, useMemo} from 'react';
import * as api from '../../apis/APIClient';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown} from "react-icons/md";
import Foot from '../Layouts/Footer';
import Header from '../Layouts/Header';
import {useNavigate} from 'react-router-dom';
import LayerFilter from './LayerFilter'
import * as client from '../../apis/APIClient';
import Dialog from '../../components/Map/common/Dialog';
import moment from 'moment-timezone'
import {useSelector} from 'react-redux';
import MUIDataTable from "mui-datatables";
import {CircularProgress} from '@mui/material';
import {Dropdown, Button, Modal, Label, Textarea, TextInput} from 'flowbite-react';
import {Permission} from "../../config";

const Submissions = () => {
    const [pending, setPending] = useState(true);
    const [startReview, setStartReview] = useState(true);
    const [rows, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const {user} = useSelector((state) => state.auth);
    const stages = 'SUBMITTED,REVIEW,CHANGE_REQUEST,REJECTED,APPROVED,PUBLISHED';
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [submitVisible, setSubmitVisible] = useState(false);
    const [row, setRow] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        let params = {
            statuses: stages,
        }
        params.provider = user.userOrg.category !== 'WASREB' ? user.userOrg.id : undefined
        api.fetchSubmissions(params, function (data, error) {
            console.log(data.content);
            if (data.content) {
                setRows(data.content)
            } else {
                setRows(data.reverse())
            }
            setPending(false);
        })
    }, []);

    const onCheckboxChange = (id) => {
        const currentStatus = [...selectedCheckboxes];
        const findIdx = currentStatus.indexOf(id);

        if (findIdx > -1) {
            currentStatus.splice(findIdx, 1);
        } else {
            currentStatus.push(id);
        }
        setSelectedCheckboxes(currentStatus)
        console.log(selectedCheckboxes)
    };

    const onClickReview = (row) => {
        setSubmitVisible(true)
        setRow(row)
    };

    const onStartReview = () => {
        console.log('submission', row)
        const updateRequest = {
            status: "REVIEW"
        }
        client.editSubmission(row.id, updateRequest, (data, error) => {
            if (data) {
                console.log(data);
                setSubmitVisible(false);
                navigate(`/submissions/${row.id}`, {state: {feature: row}})
            } else if (error) {
                //   setError(error);
            }
        })
    };


    const tableOptions = {
        filterType: 'dropdown',
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        rowsSelected: selectedCheckboxes,
        selectableRows: 'none',
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            let row = rows[rowMeta.rowIndex]
            console.log('row', row);
            return (
                <TableRow>
                    <TableCell colSpan={rowData.length + 1}>
                        <Table size="small" className="w-full">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Layer</TableCell>
                                    <TableCell>Notes</TableCell>
                                    <TableCell>Date Created</TableCell>
                                    <TableCell>Created By</TableCell>
                                    <TableCell>Action</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row.layerTasks.map((layerTask) => (
                                    <TableRow key={layerTask.id}>
                                        <TableCell>{layerTask.layerTitle}</TableCell>
                                        <TableCell>{layerTask.notes?.map(note => note.note).join(',  ')}</TableCell>
                                        <TableCell>{moment.utc(layerTask.audit.createdAt).tz('Africa/Nairobi').format('DD MMM YYYY hh:mma')}</TableCell>
                                        <TableCell>{layerTask.audit.createdBy}</TableCell>
                                        <TableCell>{layerTask.taskAction}</TableCell>
                                        <TableCell>{layerTask.taskStatus}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>
            );
        }
    };

    const columns = [
        {
            name: 'submittedAt',
            label: 'Date',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return moment.utc(value).tz('Africa/Nairobi').format('DD MMM YYYY hh:mma');
                }
            }
        },
        {
            name: 'notes',
            label: 'Name',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return value.map(note => note.note).join(', ');
                }
            }
        },
        {
            name: 'provider',
            label: 'Org Name',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return value.fullName;
                }
            }
        },

        {
            name: 'submittedBy',
            label: 'Submitted By',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return value;
                }
            }
        },
        {
            name: 'reviewedBy',
            label: 'Reviewer',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "id",
            label: "Action",
            options: {
                print: false,
                download: false,
                filter: false,
                sort: false,
                customBodyRender: (value, rowMeta, updateValue) => {
                    let row = rows[rowMeta.rowIndex]
                    return (

                        <div className="relative">
                            <Dropdown  className="z-5000"   label="Actions" inline size="sm" >
                                <Dropdown.Item onClick={()=>{navigate(`/submissions/${row.id}`, { state: {feature: row}})}}>
                                    View
                                </Dropdown.Item>
                                {user.userOrg.category === 'WASREB' && row.status === 'SUBMITTED' && user.permissions.includes(Permission.can_review_submissions)  && (<Dropdown.Item onClick={()=>onClickReview(row)}>
                                    Review
                                </Dropdown.Item>)}
                            </Dropdown>
                        </div>
                    );
                },
            },
        },
    ]

    return (
        <div className="text-gray-500 bg-gray-100 font-body min-h-screen flex flex-col justify-center align-middle">
            <Header/>
            <main className="flex-grow">
                <div className="container mx-auto py-12 ">
                    <h1 className="text-lg dashboard-heading mb-6 ">SUBMISSIONS</h1>
                    <MUIDataTable
                        title={
                            <p>
                                {pending && <CircularProgress size={24} style={{
                                    marginLeft: 15,
                                    position: 'relative',
                                    top: 4
                                }}/>}
                            </p>
                        }
                        data={rows}
                        columns={columns}
                        options={tableOptions}
                    />
                </div>
            </main>
            <Foot/>
            <Modal
                show={submitVisible}
                size="md"
                popup={true}
                onClose={() => setSubmitVisible(false)}
            >
                <Modal.Header/>
                <Modal.Body>
                    <div className="text-center">
                        <svg className="w-16 h-16 text-red-600 mx-auto mb-6" fill="none" stroke="currentColor"
                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                        </svg>
                        <h3 className="mb-5 font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to start the review?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <button data-modal-toggle="popup-modal" type="button"
                                    onClick={() => onStartReview()}
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                Yes, I'm sure
                            </button>
                            <button data-modal-toggle="popup-modal" type="button"
                                    onClick={() => setSubmitVisible(false)}
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No,
                                cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default Submissions