import React, {useState} from 'react'
import MapComponent from '../../components/Map'
import Foot from '../Layouts/Footer'
import Header from '../Layouts/Header'

const Map = () => {
    document.title = "Map | Maji Data";
  return (
    <div className="h-screen flex flex-col">
        <Header />
        <MapComponent />
        <Foot />
    </div>
  )
}
export default Map