import React, {useMemo} from 'react';
import * as client from '../../apis/APIClient';
import {useEffect, useState} from 'react';
import Pagination from '../../components/Pagination';
import Header from '../Layouts/Header';
import {Checkbox, Table, Button, Label, Modal, TextInput, Dropdown} from 'flowbite-react'
import Foot from '../Layouts/Footer';
import {useLocation} from "react-router-dom";
import {CircularProgress} from '@mui/material';
import MUIDataTable from "mui-datatables";
import {useNavigate} from 'react-router-dom';
import * as Yup from "yup";
import {useFormik, Formik, Form} from "formik";
import {useSelector} from 'react-redux';
import Select from 'react-select';
import {Permission} from "../../config";
import {ToastContainer, toast} from 'react-toastify';
let PageSize = 15;

const Providers = () => {
    const {user} = useSelector((state) => state.auth);
    const [pending, setPending] = useState(true);
    const [rows, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [firstEntry, setFirstEntry] = useState(1);
    const [lastEntry, setLastEntry] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [modalVisible, setModalVisible] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState()
    const [loadingWsps, setLoadingWsps] = useState()
    const [loadingCounties, setLoadingCounties] = useState()
    const [loadingWwdas, setLoadingWwdas] = useState()
    const [counties, setCounties] = useState([])
    const [wwdas, setWwdas] = useState([])
    const [wsps, setWsps] = useState([])
    const [provider, setProvider] = useState()

    const navigate = useNavigate()

    const type = new URLSearchParams(useLocation().search).get('type');

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        setFirstEntry(firstPageIndex + 1);
        const lastPageIndex = firstPageIndex + PageSize;
        setLastEntry(rows?.slice(firstPageIndex, lastPageIndex).length + firstPageIndex);
        return rows?.filter((item) => item?.fullName?.toLowerCase().includes(searchValue?.toLowerCase())).slice(firstPageIndex, lastPageIndex);
    }, [currentPage, rows, searchValue]);

    let title = '';
    switch (type.toUpperCase()) {
        case 'WSP':
            title = 'WSPs'
            break
        case 'COUNTY':
            title = 'Counties'
            break
        case 'SSSP':
            title = 'SSSPs'
            break
        case 'PARTNER':
            title = 'Partners'
            break
        case 'WWDA':
            title = 'WWDAs'
            break
    }

    const columns = [
        {
            name: "fullName",
            label: "Full Name",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let row = rows[tableMeta.rowIndex]
                    return type  === 'WSP' ? `${value} (${row.abbrev})`:  value
                },
            }
        },
        {
            name: "county",
            label: "County",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "id",
            label: "Action",
            options: {
                print: false,
                download: false,
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let row = rows[tableMeta.rowIndex]
                    return (
                        <Dropdown label="Action" inline className="z-5000">
                            <Dropdown.Item onClick={() => {navigate(`/providers/${value}`, {state: {row: row}})}}>
                                View
                            </Dropdown.Item>
                            {/*user.permissions.includes(Permission.can_manage_providers) &&  (type === 'WSP' ||  type === 'SSSP' || type === 'PARTNER') &&  (<Dropdown.Item onClick={() => {handleUpdate(value)}}>
                                    Update
                                </Dropdown.Item>)*/}
                        </Dropdown>
                    );
                },

            },
        },
    ];

    const options = {
        filterType: 'dropdown',
        selectableRows: 'none',
    };

    useEffect(() => {
        loadData()
        setLoadingCounties(true);
        client.fetchCounties({},(data, error) => {
            console.log('Counties',data)
            setLoadingCounties(false);
            if (data) {
                setCounties([{
                    label: 'Counties',
                    options: data.map(county => ({
                            value: county.properties.county_id,
                            label: county.properties.countyname
                        })
                    )
                }])
            }else{
                setError(error)
            }
        })
        setLoadingWwdas(true);
        client.fetchWWDAs({},(data, error) => {
            setLoadingWwdas(false);
            if (data) {
                console.log('WWDAs',data)
                setWwdas([{
                    label: 'WWDAs',
                    options: data.map(wwda => ({
                            value: wwda.properties.wwda_id,
                            label: wwda.properties.wwda
                        })
                    )
                }])
            }else{
                setError(error)
            }
        })
        setLoadingWsps(true);
        client.fetchWSPs({},(data, error) => {
            console.log('WSPs',error)

            setLoadingWsps(false);
            if (data) {
                console.log('WSPs',data)
                setWsps([{
                    label: 'WSPs',
                    options: data.map(wsp => ({
                            value: wsp.properties.wsp_id,
                            label: `${wsp.properties.wsp_name} (${wsp.properties.wsp_acrony})`
                        })
                    )
                }])
            }else{
                setError(error)
            }
        })
    }, []);

    const loadData = () => {
        setLoading(true);
        client.fetchProviders({type: type}, (data, error) => {
          //  console.log('Providers',data.reverse())
            setLoading(false);

            if (data) {
                setRows(type === 'WSP' ?  data.reverse() : data)
            } else {
                setError(error)
            }
        })
    }

    const handleUpdate = (id) => {
        setLoading(true);
        client.getProvider(id, (data, error) => {
            console.log('Provider',data)
            console.log('counties',counties)

            setLoading(false);
            if (data) {
                setProvider(data)
                setModalVisible(true)
            } else {
                setError(error)
            }
        })
    }

    document.title = title.toUpperCase() + " | Maji Data";

    return (
        <div className="text-gray-500 bg-gray-100 font-body min-h-screen flex flex-col justify-center align-middle">
            <Header/>
            <main className="flex-grow">
                <div className="container mx-auto py-12">
                    <h1 className="text-lg dashboard-heading mb-6 ">{title.toUpperCase()}</h1>
                    <MUIDataTable
                        title={
                            <p>
                                {title.toUpperCase()}
                                {loading && <CircularProgress size={24} style={{
                                    marginLeft: 15,
                                    position: 'relative',
                                    top: 4
                                }}/>}
                            </p>
                        }
                        data={rows}
                        columns={columns}
                        options={options}
                    />

                </div>
            </main>
            <div>
            </div>
            <Foot/>
            <Modal
                show={modalVisible}
                position="center"
                size="md"
                onClose={() => setModalVisible(false)}
            >
                <Modal.Header className="bg-wsblue-600">
                    <span className="text-wsblue-100">{`${provider ? 'Update' : 'Create'} ${type}`}</span>
                </Modal.Header>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        shortName: provider?.shortName || '',
                        fullName: provider?.fullName ||  '',
                        abbrev: provider?.abbrev || '',
                        manager: provider?.manager || '',
                        wwwdaId: provider?.wwwdaId ? wwdas.map(item => item.options.find(option =>  option.value === provider?.wwwdaId)) : '',
                        countyId:  provider?.countyId ? counties.map(item => item.options.find(option =>  option.value === provider?.countyId)) : '',
                        wspId:  provider?.wspId ? wsps.map(item => item.options.find(option =>  option.value === provider?.wspId)) : '',
                        category: type
                    }}
                    validationSchema={Yup.object({
                        fullName: Yup.string().required("Name is required"),
                        abbrev: Yup.string().required("Abbreviation is required"),
                        description: Yup.string(),
                        category: Yup.string(),
                        wspId: Yup.object(), 
                        countyId: Yup.object().required("County is required"),
                        wwwdaId:Yup.object().when("category", {
                            is: (category) => {
                                console.log({ category: category });
                                return category === "WSP";
                            },
                            then: ()=> Yup.object().required("WWDA is required"),
                        })
                    })}

                    onSubmit={(values, {resetForm}) => {
                        console.log('submit', values)
                        if(!values.category){
                            values.category = type
                        }
                        if(values.wwwdaId){
                            values.wwwdaId = values.wwwdaId.value
                            values.wwwda = values.wwwdaId.label
                        }
                        if(values.countyId){
                            values.countyId = values.countyId.value
                            values.county = values.countyId.label
                        }

                        if(values.wspId){
                            values.wspId = values.wspId.value
                        }
                        setError()
                        setLoading(true)
                        client.createProvider(values, (data, error)=>{
                            setLoading(false);
                            if(data){
                                setModalVisible(false)
                                resetForm({values: ''})
                                loadData()
                            }else{
                                setError(error)
                            }
                        })

                    }}>
                    {formik => (<form onSubmit={formik.handleSubmit}>
                        <Modal.Body>
                            <div className="p-2">
                                <div className="mb-4">
                                    <div className="block uppercase font-bold">
                                        <Label htmlFor="fullName" value="Name"/>
                                    </div>
                                    <TextInput
                                        id="fullName"
                                        name="fullName"
                                        placeholder="Enter Name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.fullName || ""}
                                        invalid={formik.touched.fullName && formik.errors.fullName ? "true" : "false"}
                                    />
                                    {(formik.touched.fullName && formik.errors.fullName) && (
                                        <p type="invalid"
                                           className="text-red-600 text-[0.9rem]">{formik.errors.fullName}</p>
                                    )}
                                </div>
                                { (type === 'WSP' ||  type === 'SSSP') && (<div className="mb-4">
                                    <div className="block uppercase font-bold">
                                        <Label htmlFor="abbrev" value="Abbreviation"/>
                                    </div>
                                    <TextInput
                                        id="abbrev"
                                        name='abbrev'
                                        placeholder="Enter Abbreviation"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.abbrev || ""}
                                        invalid={formik.touched.abbrev && formik.errors.abbrev ? "true" : "false"}
                                    />
                                    {formik.touched.abbrev && formik.errors.abbrev && (
                                        <p type="invalid"
                                           className="text-red-600 text-[0.9rem]">{formik.errors.abbrev}</p>
                                    )}
                                </div>)}
                                <div className="mb-4">
                                    <div className="block uppercase font-bold">
                                        <Label htmlFor="description" value="Description"/>
                                    </div>
                                    <TextInput
                                        id="description"
                                        name='description'
                                        placeholder="Enter Description"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.description || ""}
                                        invalid={formik.touched.description && formik.errors.description ? "true" : "false"}
                                    />
                                    {formik.touched.description && formik.errors.description && (
                                        <p type="invalid"
                                           className="text-red-600 text-[0.9rem]">{formik.errors.description}</p>
                                    )}
                                </div>
                                {/*type === 'SSSP' && (<div className="mb-4">
                                    <div className="block uppercase font-bold">
                                        <Label htmlFor="wspId" value="Regulating WSP"/>
                                    </div>
                                    <Select
                                        id="wspId"
                                        name="wspId"
                                        value={formik.values.wspId}
                                        options={wsps}
                                        isLoading={loadingWsps}
                                        onBlur={formik.handleBlur}
                                        onChange={(wsp) => {
                                            console.log(wsp)
                                            formik.setFieldValue("wspId", wsp)
                                        }}
                                        invalid={formik.touched.countyId && formik.errors.wspId ? "true" : "false"}
                                    />
                                    {formik.touched.wspId && formik.errors.wspId && (
                                        <p type="invalid"
                                           className="text-red-600 text-[0.9rem]">{formik.errors.wspId}</p>
                                    )}
                                </div>)*/}
                                {(type === 'WSP' || type === 'SSSP') && (<div className="mb-4">
                                        <div className="block uppercase font-bold">
                                            <Label htmlFor="countyId" value="County"/>
                                        </div>
                                        <Select
                                            name="countyId"
                                            options={counties}
                                            value={formik.values.countyId}
                                            isLoading={loadingCounties}
                                            onBlur={formik.handleBlur}
                                            onChange={(county) => {
                                                console.log(county)
                                                formik.setFieldValue("countyId", county)
                                            }}
                                            invalid={formik.touched.countyId && formik.errors.countyId ? "true" : "false"}
                                        />
                                        {formik.touched.countyId && formik.errors.countyId && (
                                            <p type="invalid"
                                               className="text-red-600 text-[0.9rem]">{formik.errors.countyId}</p>
                                        )}
                                    </div>)}
                                {type === 'WSP' && (<div className="mb-4">
                                    <div className="block uppercase font-bold">
                                        <Label htmlFor="wwda" value="WWDA"/>
                                    </div>
                                    <Select
                                        id="wwda"
                                        name="wwwdaId"
                                        options={wwdas}
                                        value={formik.values.wwwdaId}
                                        isLoading={loadingWsps}
                                        onBlur={formik.handleBlur}
                                        onChange={(wwda) => {
                                            console.log(wwda)
                                            formik.setFieldValue("wwwdaId", wwda)
                                        }}
                                        invalid={formik.touched.wwdaId && formik.errors.wwdaId ? "true" : "false"}
                                    />
                                    {formik.touched.wwdaId && formik.errors.wwdaId && (
                                        <p type="invalid"
                                           className="text-red-600 text-[0.9rem]">{formik.errors.wwdaId}</p>
                                    )}
                                </div>)}
                                {error && (
                                    <div className="mb-4 p-2">
                                        <p className="text-red-500">{error}</p>
                                    </div>)
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="bg-wsblue-600">
                            <Button type='submit'
                                    className="bg-wsblue-300 hover:bg-wsblue-200 text-wsblue-100 rounded-md"
                                    style={{backgroundColor: '#cbd5e1', color: '#1e293b'}}
                            >
                                {loading && <svg aria-hidden="true" role="status"
                                                           className="inline w-5 h-5 mr-1.5 text-[#1e293b] animate-spin"
                                                           viewBox="0 0 100 101" fill="none"
                                                           xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="#E5E7EB"/>
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentColor"/>
                                </svg>}
                                Add
                            </Button>
                            <Button
                                onClick={() => setModalVisible(false)}
                                type="button"
                                className="bg-wsblue-500 hover:bg-wsblue-400 text-wsblue-100 rounded-md"
                                style={{backgroundColor: '#64748b', color: '#1e293b'}}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </form>)}
                </Formik>
            </Modal>
        </div>
    )
}
export default Providers