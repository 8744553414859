import React, {useEffect, useState} from 'react';
import {Label, TextInput, Modal} from 'flowbite-react';
import Select from 'react-select';
import Header from '../../Layouts/Header';
import {useParams} from 'react-router-dom';
import * as client from '../../../apis/APIClient';
import {adminRoles,userRoles} from '../../../config';
import Foot from '../../Layouts/Footer';
import {useSelector} from 'react-redux';
import VerticalTab from "../../../components/VerticalTab/VerticalTab";
import {useFormik, Formik, Form} from "formik";
import {ToastContainer, toast} from 'react-toastify';
import * as Yup from "yup";
import { useNavigate, useLocation } from 'react-router-dom';

const EditUser = () => {
    const {id} = useParams()
    const param = id
    const {user} = useSelector((state) => state.auth);
    const [firstName, setFirstName] = useState('');
    const [userId, setUserId] = useState('');
    const [lastName, setLastName] = useState('');
    const [category, setCategory] = useState();
    const [userPermissions, setUserPermissions] = useState('');
    const [provider, setProvider] = useState(null);
    const [roleOptions, setRoleOptions] = useState([]);
    const [roles, setRoles] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [permissionOptions, setPermissionOptions] = useState([]);
    const [removedPermissions, setRemovedPermissions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, showSuccess] = useState(false);
    const [tab, setTab] = useState(false);
    const [error, setError] = useState('');
    const [activeTabId, setActiveTabId] = useState(0);
    const [submitted, setSubmitted] = useState()
    const [back, onBack] = useState()
    const navigate = useNavigate();

    useEffect(() => {
        client.fetchSingleUser(param, function (response, error) {
            if (response) {
                if (response.error) {
                    setError(response.error);
                    return;
                }
                setFirstName(response.firstName);
                setUserId(response.id);
                setLastName(response.lastName);
                setUserPermissions(response.permissions);
                setRoles(response.roleNames);
                setCategory(response.userOrg?.category)
            } else if (error) {
                setError(response.error);
            }

            getRoles(response);
        })

        const getRoles = async (response) => {
            const category = response.userOrg?.category
            
            const data = await client.fetchRoles();
            setRoleOptions(data.filter(role=>role.permissions.length).filter( role => (category === 'WASREB' && adminRoles.includes(role.name)) || (category !== 'WASREB' && userRoles.includes(role.name))).map(role => {
                return {value: role.id, label: role.name}
            }));

            let permissions = {}
            data.map(role => {
                permissions[role.id] = role.permissions.map(permission => {
                    return {value: permission, label: permission}
                })
            })
            setPermissions(permissions)

            let permissionOptions = []
            data.filter(role => response.roleNames.includes(role.name)).map(role=>{
                let selectedPermissions  = permissions[role.id]
                permissionOptions = [...permissionOptions, ...selectedPermissions]
                setRemovedPermissions(permissionOptions.filter(permission => !response.permissions.includes(permission)))
            })
            setPermissionOptions(permissionOptions)
        }

    }, []);


    return (<div className="text-gray-500 bg-gray-100 font-body min-h-screen flex flex-col justify-center align-middle">
        <Header/>
        <ToastContainer/>
        <main className="flex-grow">
            <div className="container mx-auto py-12">
                <h1 className="text-lg dashboard-heading">{`${firstName} ${lastName}`}</h1>
                <div className="container mx-auto py-12">
                    <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                        <VerticalTab
                            options={{titleClass: 'basis-4/12 p-2', contentClass: 'basis-8/12 p-2'}}
                            titles={[<div className="text-gray-600">
                                <p className="font-medium text-wsblue-700 text-lg">Edit User Details</p>
                                <p>Please fill all fields.</p>
                            </div>, <div className="text-gray-600">
                                <p className="font-medium text-wsblue-700 text-lg">Edit Roles &  Permissions</p>
                                <p>Define roles and permissions.</p>
                            </div>]} content={[<Formik
                            enableReinitialize={true}
                            initialValues={{
                                firstName: firstName, lastName: lastName,
                            }}
                            validationSchema={Yup.object({
                                firstName: Yup.string().required("Please Enter Your Names"),
                                lastName: Yup.string().required("Please Enter Your Names"),
                            })}

                            onSubmit={(values, {resetForm}) => {
                                console.log('submit', values)
                                setLoading(true)
                                setSubmitted(true)
                                const params = {
                                    id: userId,
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                }
                                console.log(params)
                                setLoading(true)
                                showSuccess(false)
                                client.editUser(params, function (data, error) {
                                    setLoading(false)
                                    console.log(data);
                                    if (data) {
                                        showSuccess('user');
                                    }
                                })
                            }}>

                            {formik => (<form onSubmit={formik.handleSubmit}>
                                <div className="md:col-span-2 mb-4">
                                    <div className="block uppercase font-bold">
                                        <Label htmlFor="firstName" className="text-wsblue-700"
                                               value="First Name"/>
                                    </div>
                                    <TextInput
                                        id="firstName"
                                        name="firstName"
                                        placeholder="Enter First Name"
                                        onChange={formik.handleChange}
                                        value={formik.values.firstName}/>
                                    {formik.touched.firstName && formik.errors.firstName ? (<p type="invalid"
                                                                                               className="text-red-600 text-[0.9rem]">{formik.errors.firstName}</p>) : null}
                                </div>

                                <div className="md:col-span-2 mb-4">
                                    <div className="block uppercase font-bold">
                                        <Label htmlFor="lastName" className="text-wsblue-700"
                                               value="Last Name"/>
                                    </div>
                                    <TextInput
                                        id="lastName"
                                        name='lastName'
                                        placeholder="Enter Last Name"
                                        onChange={formik.handleChange}
                                        value={formik.values.lastName}/>
                                    {formik.touched.lastName && formik.errors.lastName ? (<p type="invalid"
                                                                                             className="text-red-600 text-[0.9rem]">{formik.errors.lastName}</p>) : null}
                                </div>
                                {success == 'user' && <div
                                    className="bg-green-100 rounded-lg px-6 py-3 my-2 text-base text-green-700 inline-flex items-center w-full"
                                    role="alert">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                         data-icon="check-circle"
                                         className="w-4 h-4 mr-2 fill-current" role="img"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor"
                                              d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
                                    </svg>
                                    Edited this user successfully!
                                </div>}
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-4">
                                    <div>
                                        <button className="outline-button" type="button" onClick={()=> !submitted ? onBack(true) :   navigate(-1)}>
                                            Back to Users
                                        </button>
                                    </div>
                                    <div className="text-right">
                                        <button type='submit' className="primary-button">Update</button>
                                    </div>
                                </div>
                            </form>)}
                        </Formik>, <Formik
                            enableReinitialize={true}
                            initialValues={{
                                roles: roleOptions.filter(opt => roles.includes(opt.label)),
                                permissions: userPermissions,
                            }}
                            validationSchema={Yup.object({
                                roles: Yup.array().min(1).required("Select at least one role"),
                                permissions: Yup.array().min(1).required("Select at least one permission")
                            })}

                            onSubmit={(values, {resetForm}) => {
                                console.log('submit', values)
                                const params = {
                                    id: userId,
                                    roles: values.roles.map(role=>role.value),
                                    permissions: values.permissions,
                                }
                                console.log(params)
                                setLoading(true)
                                showSuccess(false)
                                const update = async () => {
                                    const response = await  client.updateUserRoles(params);
                                    setLoading(false)
                                    showSuccess('roles');
                                }

                                update();
                            }}>

                            {formik => (<form onSubmit={formik.handleSubmit}>
                                <div className="md:col-span-4 mb-4">
                                    <div className="block uppercase font-bold">
                                        <Label htmlFor="roles" className="text-wsblue-700"
                                               value="Roles"/>
                                    </div>
                                    <Select
                                        isMulti
                                        options={roleOptions}
                                        value={formik.values.roles}
                                        onChange={(roles) => {
                                            let permissionOptions = []
                                            roles.map(role=>{
                                                let selectedPermissions  = permissions[role.value]
                                                permissionOptions = [...permissionOptions, ...selectedPermissions]

                                            })
                                            setPermissionOptions(permissionOptions)
                                            formik.setFieldValue("roles", roles)
                                            formik.setFieldValue("permissions", permissionOptions.filter(opt => !removedPermissions.includes(opt.value)).map(opt => opt.value))
                                        }}
                                        invalid={formik.touched.kpis && formik.errors.roles ? "true" : "false"}/>
                                    {formik.touched.roles && formik.errors.roles ? (<p type="invalid"
                                                                                       className="text-red-600 text-[0.9rem]">{formik.errors.roles}</p>) : null}
                                </div>

                                {permissionOptions.length > 0 && (<div className="mt-6">
                                    <div className="block uppercase font-bold">
                                        <Label htmlFor="permissions" className="text-wsblue-700"
                                               value="Permissions"/>
                                    </div>
                                    <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-6">
                                        {permissionOptions?.map((item) => {
                                            return (<div className="checkbox-container" key={item?.label}>
                                                <input key={item?.value} type="checkbox"
                                                       value={`${item?.value}`}
                                                       checked={formik.values.permissions.includes(item?.value)}
                                                       name="permissions" className="custom-checkbox"
                                                       onChange={(event) => {
                                                           if(event.target.checked){
                                                               setRemovedPermissions(removedPermissions.filter(removed => removed !=  event.target.value))
                                                           }else{
                                                               setRemovedPermissions([...removedPermissions, event.target.value])
                                                           }
                                                           console.log(event.target.value, event.target.checked);
                                                           let permissions = []
                                                           if (event.target.checked && !formik.values.permissions.includes(item?.value)) {
                                                               permissions = [...formik.values.permissions, item?.value]
                                                           } else {
                                                               permissions = formik.values.permissions.filter((permission) => {
                                                                   return permission !== item?.value
                                                               });
                                                           }
                                                           formik.setFieldValue("permissions", permissions)
                                                       }}
                                                       invalid={formik.touched.permissions && formik.errors.permissions ? "true" : "false"}/>
                                                <label htmlFor="permissions"
                                                       className="custom-checkbox-label p-2 uppercase">{item.label.replaceAll('_', '  ')}</label>
                                            </div>)
                                        })}

                                    </div>
                                    {formik.touched.permissions && formik.errors.permissions ? (<p type="invalid"
                                                                                                   className="text-red-600 text-[0.9rem]">{formik.errors.permissions}</p>) : null}
                                </div>)}

                                {success == 'roles' && <div
                                    className="bg-green-100 rounded-lg px-6 py-3 my-2 text-base text-green-700 inline-flex items-center w-full"
                                    role="alert">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                         data-icon="check-circle"
                                         className="w-4 h-4 mr-2 fill-current" role="img"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor"
                                              d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
                                    </svg>
                                    Roles and permissions updated successfully!
                                </div>}


                                <div className="grid grid-cols-1 md:grid-cols-2 gap-10  mt-4">
                                    <div>
                                        <button className="outline-button" type="button" onClick={()=> navigate(-1)}>
                                            Back to Users
                                        </button>
                                    </div>
                                    <div className=" text-right">
                                        <button type='submit' className="primary-button">Update
                                        </button>
                                    </div>
                                </div>
                            </form>)}
                        </Formik>]}/>

                    </div>
                </div>
            </div>
        </main>
        <Modal
            show={back}
            size="md"
            popup={true}
            onClose={() => onBack(false)}
        >
            <Modal.Header/>
            <Modal.Body>
                <div className="text-center">
                    <svg className="w-16 h-16 text-red-600 mx-auto mb-6" fill="none" stroke="currentColor"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                    </svg>
                    <h3 className="mb-5 font-normal text-gray-500 dark:text-gray-400">
                        Are you sure you want to go back? You will lose unsaved changes
                    </h3>
                    <div className="flex justify-center gap-4">
                        <button data-modal-toggle="popup-modal" type="button"
                                onClick={() => navigate(-1)}
                                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                            Yes, I'm sure
                        </button>
                        <button data-modal-toggle="popup-modal" type="button"
                                onClick={() => onBack(false)}
                                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No,
                            cancel
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        <Foot/>
    </div>)
}

export default EditUser