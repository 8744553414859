import React, {useState, useEffect, useRef, useMemo} from 'react'
import layersIcon from '../../assets/icons/layers.svg'
import DOM from './helpers/Browser/DOM';
import {
    Button, Modal, Label, TextInput, FileInput, Textarea, Badge, Checkbox, Table, Tabs
} from 'flowbite-react'

import Select from 'react-select';
import View from "ol/View";
import Map from "ol/Map";
import SearchNominatim from 'ol-ext/control/SearchNominatim';
import PrintDialog from 'ol-ext/control/PrintDialog';
import CanvasScaleLine from 'ol-ext/control/CanvasScaleLine';
import CanvasTitle from 'ol-ext/control/CanvasTitle';
import CanvasAttribution from 'ol-ext/control/CanvasAttribution';
import MousePosition from 'ol/control/MousePosition'
import {createStringXY} from 'ol/coordinate';
import Popup from 'ol-ext/overlay/Popup';
import 'ol-ext/dist/ol-ext.css'
import * as layer from "ol/layer";
import VectorLayer from 'ol/layer/Vector'
import LayerGroup from 'ol/layer/Group'
import TileLayer from 'ol/layer/Tile'
import * as control from "ol/control";
import * as source from "ol/source";
import * as extent from "ol/extent";
import * as Interaction from "ol/interaction";
import Feature from 'ol/Feature.js';
import Polygon from 'ol/geom/Polygon.js';
import Point from 'ol/geom/Point.js';
import {GeoJSON, WMSCapabilities, WMSGetFeatureInfo} from "ol/format";
import {toLonLat, fromLonLat, transform, transformExtent} from 'ol/proj';
import {toStringHDMS} from 'ol/coordinate';
import {getCenter} from 'ol/extent';
import {defaults as defaultControls} from 'ol/control';
import {Draw, Modify, Snap, Select as SelectInteration, DragPan, MouseWheelZoom, defaults} from 'ol/interaction';
import {platformModifierKeyOnly} from 'ol/events/condition';
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import LayerSwitcher from "ol-layerswitcher";
import {getArea, getLength} from 'ol/sphere';
import Draggable from 'react-draggable';
import 'ol/ol.css';
import * as fa from "react-icons/fa";
import {api, workspace, Permission} from "../../config";
import * as url from "../../helpers/url_helper";
import * as client from '../../apis/APIClient';
import "./Map.css";
import "../../assets/scss/map.scss";
import "./Sidebar/SidebarControl";
import Home from './tools/Home';
import ZoomIn from './tools/ZoomIn';
import ZoomOut from './tools/ZoomOut';
import FullScreen from './tools/Fullscreen';
import ExportPNG from './tools/ExportPNG';
import EditMode from './tools/EditMode';
import ViewAll from './tools/ViewAll';
import ExtendView from './tools/ExtendView';
import ViewLabels from './tools/ViewLabels';
import MeasureTool from './tools/MeasureTool';
import DrawTool from './tools/DrawTool';
import EditTool from './tools/EditTool';
import Info from './tools/Info';
import AddIncident from './tools/AddIncident';
import DeleteTool from './tools/DeleteTool';
import Bookmark from './tools/Bookmark';
import Layers from './tools/Layers';
import SplitView from './tools/SplitView';
import Overview from './tools/Overview';
import GraticuleTool from './tools/Graticule';
import Magnify from './tools/Magnify';
import ResetNorth from './tools/ResetNorth';
import Coordinates from './tools/Coordinates';
import MyLocation from './tools/MyLocation';
import ImportVectorLayer from './tools/ImportVectorLayer';
import ScaleLineTool from './tools/ScaleLineTool';
import Refresh from './tools/Refresh';
import Grid from './tools/Grid';
import Pan from './tools/Pan';
import {Search, InfoWindow, AttributeTable} from './tools/Toolbox';
import ThemeToggle from './tools/ThemeToggle';
import {getMeasureTooltipCoordinates, getMeasureTooltipValue} from './helpers/olFunctions/Measure';
import Notification from './tools/Notification';
import Help from './tools/Help';
import Settings from './tools/Settings';
import EditForm, {prepareFeature, defaultProps, excludedProps} from './EditForm';
import SettingsManager from './core/Managers/SettingsManager';
import InfoWindowManager from './core/Managers/InfoWindowManager';
import LayerManager from './core/Managers/LayerManager';
import StateManager from './core/Managers/StateManager';
import TooltipManager from './core/Managers/TooltipManager';
import Dialog from './common/Dialog';
import ContextMenu from './common/ContextMenu';
import {Sidebar, Tab} from "./Sidebar";
import SidebarControl from "./Sidebar/SidebarControl";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLayerGroup, faXmark, faSearch, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import Pagination from '../../components/Pagination';
import {delegate} from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import {Fill, Stroke, Style, Circle, Text, Icon} from 'ol/style';
import MultiPoint from 'ol/geom/MultiPoint';
import {useSelector} from 'react-redux';
import Toast from '../../components/Map/common/Toast';
import BulkUpload from '../../pages/Map/BulkUpload';
import PDF from 'jspdf-react'


const MapComponent = ({height, layerTask, callback, provider}) => {
    const LOCAL_STORAGE_NODE_NAME = 'drawTool';
    const LOCAL_STORAGE_LAYERS_DIV = 'layers';
    const [modalVisible, setModalVisible] = useState(false)
    const [layersVisible, setLayersVisible] = useState(true)
    const [warningVisible, setWarningVisible] = useState(false)
    const [center, setCenter] = useState([36.817223, -1.286389]);
    const [zoom, setZoom] = useState(6.0);
    const [map, setMap] = useState(null);
    const [modified, setModified] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [remoteLayers, setRemoteLayers] = useState([]);
    const [selectedLayer, setSelectedLayer] = useState(null);
    const [properties, setProperties] = useState([]);
    const [drawInteraction, setDrawInteraction] = useState(null);
    const [feature, setFeature] = useState(null);
    const [editing, setEditing] = useState(false);
    const [editingMode, setEditingMode] = useState();
    const [highlightLayer, setHighlightLayer] = useState(null);
    const [editLayer, setEditLayer] = useState(null);
    const [targetLayer, setTargetLayer] = useState(null);
    const {user} = useSelector((state) => state.auth);
    const duration = {duration: 1000, maxZoom: 16}
    const category = user.userOrg ? user.userOrg.category : null;
    let countyId = category && category !== 'WASREB' ? user.userOrg.countyId : null;
    const [collapsed, setCollapsed] = useState(true)
    const [selected, setSelected] = useState('home')
    let PageSize = 5;
    const [rows, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState(null);
    const [showAttributeTable, setShowAttributeTable] = useState(false);
    const [searchLayer, setSearchLayer] = useState(null);
    const [searchData, setSearchData] = useState();
    const [tool, setTool] = useState({title: 'Toolbox', component: null});
    const [labels, setLabels] = useState([]);
    const [formData, setFormData] = useState({});
    const [cql, setCql] = useState('');
    const [defaultExtent, setDefaultExtent] = useState(null);
    const [viewAll, setViewAll] = useState(false);
    const [extendView, setExtendView] = useState(false);
    const [viewLabels, setViewLabels] = useState(false);
    const [satelliteLayer, setSatelliteLayer] = useState(false);
    const [defaultLayer, setDefaultLayer] = useState(false);
    const [onClick, setOnClick] = useState(false);
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [interactions, setInteractions] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [selectedLayers, setSelectedLayers] = useState([]);
    const [hideLayer, setHideLayer] = useState(false);
    const [onHome, setHome] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [dependencyChoices, setDependencyChoices] = useState(false);
    const [showLabel, setShowLabel] = useState(false);

    let popup = new Popup({
        popupClass: "black", //"tooltips", "warning" "black" "default", "tips", "shadow",
        anim: true, // closeBox: true,
        positioning: 'auto',
    });

    const getText = (feature) => {
        let text = feature.get('label');
        return text;
    };

    const createTextStyle = (feature) => {
        return new Text({
            textAlign: 'centre',
            textBaseline: 'middle',
            font: 'Arial',
            text: getText(feature),
            fill: new Fill({color: '#aa3300'}),
            stroke: new Stroke({color: '#ffffff', width: 3}),
            offsetX: 0,
            offsetY: 0,
        });
    }

    const editingStyleFunction = (feature) => {
        return new Style({
            stroke: new Stroke({
                color: 'blue', width: 1,
            }), fill: new Fill({
                color: 'rgba(0, 0, 255, 0.1)',
            }),
        });
    }

    const styles = [/* We are using two different styles for the polygons:
         *  - The first style is for the polygons themselves.
         *  - The second style is to draw the vertices of the polygons.
         *    In a custom `geometry` function the vertices of a polygon are
         *    returned as `MultiPoint` geometry, which will be used to render
         *    the style.
         */

        new Style({
            stroke: new Stroke({
                color: 'blue', width: 3,
            }), fill: new Fill({
                color: 'rgba(0, 0, 255, 0.1)',
            })
        }), new Style({
            image: new Circle({
                radius: 10, fill: new Fill({
                    color: 'red',
                }),
            }),

            geometry: function (feature) {
                var coordinates;
                var origGeom = feature.getGeometry();
                //  console.log('origGeom.getType()', origGeom.getType())
                if (origGeom.getType() === 'Polygon' || origGeom.getType() === 'MultiPolygon') {
                    coordinates = feature.getGeometry().getCoordinates()[0];
                } else {
                    coordinates = feature.getGeometry().getCoordinates();
                }
                // console.log('coordinates', coordinates)
                return new MultiPoint(coordinates);
            },
        }),];
    var sidebar = document.getElementById('sidebar');
    var topToolbar = document.getElementById('oltb-data');

    const toc = useRef();
    const mainRef = useRef();
    const mapRef = useRef();
    const formRef = useRef();
    const toolbox = useRef();

    const onClose = () => {
        setCollapsed(true)
    }
    const onCloseInfoWindow = () => {
        setSelectedLayers([])
    }
    const onOpen = (id) => {
        if (selected === id) {
            setCollapsed(!collapsed)
        } else {
            setCollapsed(false)
        }
        setSelected(id)
    }

    const parser = new WMSCapabilities()
    let _ = ['geometry', 'geom', 'the_geom', 'gid', 'objectid', 'id', 'prev_id', 'prev_f_id', 'prev_f_gid', 'version', 'stage', 'operation', 'approved_by', 'date_approved', 'created_by', 'updated_by', 'date_updated', 'netwk_f_id', 'netwk_f', 'network_f', 'network_feature_id', 'network_feature', 'boundedBy', 'last_published']
    let defaultProps = ['wsp_id', 'wsp_name', 'wsp_acrony', 'county_id', 'county', 'wwda_id', 'wwda', 'area_km2', 'area_m2', 'length_m', 'popdensity', 'datasource', 'feature', 'date_created', 'last_modified', 'modified_by']

    function arrayRemove(arr, value) {
        return arr.filter(prop => {
            return prop !== value
        })
    }

    const setupReview = (map, extent) => {
        if (map) {
            if (editLayer) {
                map.removeLayer(editLayer)
                console.log('Removed edit', editLayer);
            }
            if (targetLayer) {
                map.removeLayer(targetLayer)
                console.log('Removed target', targetLayer);
            }

            let edits = layerTask.edits
            if (edits.length == 1) {
                edits.push(edits[0]) //add to self. CQL wont work with one value in IN
            }

            console.log('edits', edits)
            client.GetProperties({
                layer: `${workspace}:${layerTask.editLayer}`,
            }, function (data, error) {
                console.log('data', data)
                const keys = Object.keys(data.properties);
                const id = keys.find(prop => prop === 'id');
                const gid = keys.find(prop => prop === 'gid');
                const category = user.userOrg.category;
                let edits = layerTask.edits
                if (edits.length == 1) {
                    edits.push(edits[0]) //add to self. CQL wont work with one value in IN
                }
                let cql;
                if (category === "WASREB") {
                    // cql = "stage='published'"
                } else if (category === "COUNTY") {
                    cql = `county_id=${countyId}`;
                } else {
                    cql = `wsp_id=${user.userOrg.id}`
                }
                let editCql = `${gid ? 'gid' : 'id'} in (${edits.join(',')})`
                /*if (cql) {
                    editCql = `${cql} AND (${editCql})`
                }*/

                let params = {
                    service: 'WFS',
                    version: '1.0.0',
                    request: 'GetFeature',
                    typeName: `${workspace}:${layerTask.editLayer}`,
                    outputFormat: 'application/json',
                    CQL_FILTER: editCql
                }
                let layerUrl = `${api.GEOSERVER_URL + url.GET_WFS}?${client.formData(params)}`
                console.log('edited paramns', params)
                fetch(layerUrl)
                    .then(res => res.text())
                    .then((response) => {
                        const features = new GeoJSON().readFeatures(response);
                        if (callback) {
                            callback(features, data)
                        }
                        let editSource = new source.Vector({features: features, wrapX: false});
                        let extent = editSource.getExtent()
                        console.log('extent', extent)
                        let vectorLayer = new layer.Vector({
                            title: layerTask.layerTitle, visible: true, source: editSource,
                        })
                        map.addLayer(vectorLayer)
                        setEditLayer(vectorLayer)
                        map.getView().fit(extent)
                        StateManager.updateStateObject('extent', extent)
                    }, (error) => {
                        console.log('error', error)
                    })

                let otherCql;
                if (cql) {
                    otherCql = `${cql} AND stage='published'`
                } else {
                    otherCql = `stage='published'`
                }
                params = {
                    LAYERS: `${workspace}:${layerTask.editLayer}`,
                    'authkey': user.authKey,
                    CQL_FILTER: encodeURIComponent(otherCql)
                }

                /*if (countyId && category === 'COUNTY') {
                    params.CQL_FILTER = layerTask.targetLayer === 'county' ? 'id=' + countyId : 'county_id=' + countyId
                }*/

                // console.log('current params', params)

                layerUrl = `${api.GEOSERVER_URL}/${workspace}/wms?${client.formData(params)}`
                let tileLayer = new layer.Tile({
                    visible: true,
                    source: new source.TileWMS({url: layerUrl})
                });
                map.addLayer(tileLayer)
                setTargetLayer(tileLayer)
                LayerSwitcher.renderPanel(map, toc.current, {reverse: true});
            })
        }
        return null;
    }

    useEffect(() => {
        console.log('editing', editing)
        setModified(false)
        setDeleted(false)
        setShowBulkUpload(false)
        highlightLayer?.getSource().clear()
        interactions.map(interaction => map.removeInteraction(interaction))
        setInteractions([])

        if (editing) {
            setEditingMode(editing.mode)
            const value = editing.layer.code
            satelliteLayer?.setVisible(true)
            defaultLayer?.setVisible(false)
            closeAttributTable()
            setCollapsed(false)

            let layers = []
            layers = map.getLayers().getArray().map((layer, index) => {
                if (index > 0) {
                    return layers.concat(visibleLayer(layer));
                }
            });
            layers = layers?.filter(layer => layer).flat(5)
            layers.map(layer => layer.setVisible(false))
            LayerSwitcher.renderPanel(map, toc.current, {reverse: true});

            client.GetProperties({layer: `${workspace}:${value}`}, function (data, error) {
                let feature = {
                    layer: value,
                    edit: {code: value, name: editing.layer.name},
                    type: data.type,
                    properties: data.properties,
                    targetPrefix: data.targetPrefix,
                    targetNamespace: data.targetNamespace
                }
                setFeature(feature)
                const params = {
                    service: 'WFS',
                    version: '1.0.0',
                    request: 'GetFeature',
                    typeName: workspace + ':' + value,
                    outputFormat: 'application/json',
                }
                if (cql) {
                    params.CQL_FILTER = cql
                }
                let layerUrl = `${api.GEOSERVER_URL + url.GET_WFS}?${client.formData(params)}`
                // console.log('selectLayer', layerUrl)

                fetch(layerUrl)
                    .then(res => res.json())
                    .then((response) => {
                        const features = new GeoJSON().readFeatures(response);
                        setRows(features)
                        let vectorSource = new source.Vector({features: features, wrapX: false});
                        let extent = vectorSource.getExtent()

                        map.getLayers().forEach(function (layerGroup, index) {
                            if (layerGroup instanceof VectorLayer) {
                                map.removeLayer(layerGroup)
                            }
                        });

                        let vectorLayer = new layer.Vector({
                            visible: true, source: vectorSource
                        })
                        map.addLayer(vectorLayer)

                        setHighlightLayer(vectorLayer)
                        if (features.length) {
                            map.getView().fit(extent)
                        }
                        const snap = new Snap({source: vectorSource});
                        if (editing.mode === 'insert') {
                            const interaction = new Draw({
                                source: vectorSource, type: data.type,
                            })
                            setDrawInteraction(interaction)
                            map.addInteraction(interaction);
                            map.addInteraction(snap);
                            setInteractions([...interactions, interaction, snap])

                            interaction.on('drawstart', (event) => {
                                console.log('drawstart')
                            });

                            document.addEventListener('keyup', (event) => {
                                const key = event.key?.toLowerCase();
                                if (key === 'escape') {
                                    if (interaction) {
                                        interaction.abortDrawing();
                                    }
                                } else if (event.ctrlKey && key === 'z') {
                                    if (interaction) {
                                        interaction.removeLastPoint();
                                    }
                                }
                            })

                            interaction.on('drawend', (event) => {
                                const feature = event.feature;
                                let provider = user.userOrg;
                                console.log('props', feature)
                                map.removeInteraction(interaction);
                                Object.values(data.properties).map(prop => {
                                    feature.set(prop.name, prop.value)
                                })
                                setModified(prepareFeature(feature, data, user, editing.layer.name))
                            });

                            interaction.on('drawabort', (event) => {
                                console.log('Edit abort');
                            });
                            interaction.on('error', (event) => {
                                console.log('Edit error');
                            });
                        } else if (editing.mode === 'update') {
                            const select = new SelectInteration();
                            const interaction = new Modify({
                                features: select.getFeatures()
                            });
                            map.addInteraction(interaction);
                            map.addInteraction(select);
                            map.addInteraction(snap);
                            setInteractions([...interactions, interaction, select, snap])

                            document.addEventListener('keyup', (event) => {
                                const key = event.key?.toLowerCase();
                                if (key === 'escape') {
                                    if (interaction) {
                                        interaction.abortDrawing();
                                    }
                                } else if (event.ctrlKey && key === 'z') {
                                    if (interaction) {
                                        interaction.removeLastPoint();
                                    }
                                }
                            })

                            interaction.on('modifystart', (event) => {
                                setEditingMode('update')
                                let features = event.features.getArray();
                                console.log('modifyend', features.length)
                                for (var i = 0; i < features.length; i++) {
                                    setModified(prepareFeature(features[i], null, user, editing.layer.name))
                                }
                            });

                            interaction.on('modifyend', (event) => {
                                setEditingMode('update')
                                let features = event.features.getArray();
                                console.log('modifyend', features.length)
                                for (var i = 0; i < features.length; i++) {
                                    setModified(prepareFeature(features[i], data, user, editing.layer.name))
                                }
                            });

                            interaction.on('modifyabort', (event) => {
                                console.log('Edit abort');
                            });
                            interaction.on('error', (event) => {
                                console.log('Edit error');
                            });

                            select.on('select', (event) => {
                                let features = event.target.getFeatures().getArray();
                                console.log('select', features);
                                setEditingMode('update')
                                for (var i = 0; i < features.length; i++) {
                                    setModified(prepareFeature(features[i], null, user, editing.layer.name))
                                }
                            });
                        } else if (editing.mode === 'delete') {
                            const interaction = new SelectInteration();
                            map.addInteraction(interaction);
                            setInteractions([...interactions, interaction])
                            interaction.on('select', (event) => {
                                let features = event.target.getFeatures().getArray();
                                console.log('Selected', features);
                                setDeleted(features)
                                if (features.length) {
                                    setEditingMode('delete')
                                } else {
                                    setEditingMode()
                                }
                            });
                        } else if (editing.mode === 'upload') {
                            setShowBulkUpload(editing.layer)
                        } else if (editing.mode === 'add_incident') {
                            let value = 'wsp_network_incident'
                            client.GetProperties({layer: `${workspace}:${value}`}, function (data, error) {
                                console.log('data', data)
                                let feature = {
                                    layer: value,
                                    edit: {code: value, name: 'WSP Network Incident'},
                                    type: data.type,
                                    properties: data.properties,
                                    targetPrefix: data.targetPrefix,
                                    targetNamespace: data.targetNamespace
                                }
                                setFeature(feature)
                                const select = new SelectInteration();
                                const modify = new Modify({
                                    features: select.getFeatures()
                                });

                                const interaction = new Draw({
                                    source: vectorSource,
                                    type: 'Point',
                                });

                                map.addInteraction(interaction);
                                map.addInteraction(snap);
                                map.addInteraction(select);

                                setInteractions([...interactions, interaction, select, snap, modify])

                                interaction.setActive(false)

                                interaction.on('drawstart', (event) => {
                                    console.log('drawstart')
                                });

                                document.addEventListener('keyup', (event) => {
                                    const key = event.key?.toLowerCase();
                                    if (key === 'escape') {
                                        if (interaction) {
                                            interaction.abortDrawing();
                                        }
                                    } else if (event.ctrlKey && key === 'z') {
                                        if (interaction) {
                                            interaction.removeLastPoint();
                                        }
                                    }
                                })

                                let selectedFeature;
                                interaction.on('drawend', (event) => {
                                    const feature = event.feature;
                                    console.log('selectedFeature', selectedFeature)
                                    setEditingMode('insert')

                                    Object.values(data.properties).map(prop => {
                                        feature.set(prop.name, prop.value)
                                    })

                                    interaction.setActive(false)

                                    let provider = user.userOrg;
                                    console.log('data', data)
                                    defaultProps.map(prop => {
                                        if (prop === 'wsp_id' && category === 'WSP') {
                                            feature.set(prop, provider.id)
                                            feature.set('wsp_name', provider.fullName)
                                        } else if (prop === 'county_id') {
                                            feature.set(prop, category === 'COUNTY' ? provider.id : provider.countyId)
                                            feature.set('county', provider.fullName)
                                        } else if (prop === 'wwda_id') {
                                            feature.set(prop, provider.wwwdaId)
                                        } else if (prop === 'date_created' || prop === 'last_modified') {
                                            const d = new Date();
                                            feature.set(prop, d.toISOString())
                                        } else if (prop === 'modified_by') {
                                            feature.set(prop, user?.firstName + " " + user?.lastName)
                                        } else if (prop === 'last_published') {
                                            if (!feature.get(prop)) {
                                                feature.set(prop, undefined)
                                            }
                                        }
                                    })
                                    let layer = selectedFeature.getId().split('.')
                                    feature.set('network_feature_id', layer[1])
                                    feature.set('network_feature', layer[0])
                                    feature.set('netwk_f_id', layer[1])
                                    feature.set('netwk_f', layer[0])
                                    feature.set('network_f', layer[0])
                                    feature.set('feature', 'WSP Network Incident')
                                    console.log('incident', feature.getProperties())
                                    setModified(prepareFeature(feature, null, user,'WSP Network Incident'))
                                });

                                interaction.on('drawabort', (event) => {
                                    console.log('Edit abort');
                                });
                                interaction.on('error', (event) => {
                                    console.log('Edit error');
                                });

                                select.on('select', (event) => {
                                    interaction.setActive(true)

                                    let features = event.target.getFeatures().getArray();
                                    console.log('select', features);

                                    for (var i = 0; i < features.length; i++) {
                                        if (features[i].getId()) {
                                            selectedFeature = features[i];
                                        }
                                    }
                                });
                            })
                        }
                    }, (error) => {
                        console.log('error', error)
                    })
                console.log('feature', feature)
                setFeature(feature)
            })
        } else {
            if (map) {
                if (satelliteLayer) satelliteLayer?.setVisible(false)
                if (defaultLayer) defaultLayer?.setVisible(true)
                closeAttributTable()
                setCollapsed(false)
                console.log('interactions', interactions)
                interactions.map(interaction => {
                    map.removeInteraction(interaction)
                })
                let layers = []
                layers = map?.getLayers().getArray().map((layer, index) => {
                    if (index > 0) {
                        return layers.concat(visibleLayer(layer));
                    }
                });
                layers = layers?.filter(layer => layer).flat(5)
                layers.map(layer => layer.setVisible(layer.get('LAYERS') === `${workspace}:${editing.layer?.code}`))

                LayerSwitcher.renderPanel(map, toc.current, {reverse: true});
                map.removeLayer(highlightLayer)
                setShowConfirmation(false)
            }
        }
    }, [editing])


    useEffect(() => {
        if (onHome) {
            if (map) map.getView().fit(defaultExtent, duration);
        }
        return function cleanUp() {
            setHome(false);
        }
    }, [onHome])


    useEffect(() => {
        console.log('refresh', refresh)
        if (refresh) {
            clearMap()
            setShowInfoWindow(false)
            closeAttributTable()
            if (map) map.getView().fit(defaultExtent, duration);
        }
        return function cleanUp() {
            setRefresh(false);
        }
    }, [refresh])

    useEffect(() => {
        if (onClick && showInfoWindow) {
            updateInfoWindow()
            return function cleanup() {
                setOnClick(false)
            };
        }
    }, [onClick])


    const clearMap = () => {
        if (highlightLayer) {
            highlightLayer?.getSource().clear()
        }

        let layers = []
        layers = map.getLayers().getArray().map((layer, index) => {
            if (index > 0) {
                return layers.concat(visibleLayer(layer));
            }
        });
        layers = layers?.filter(layer => layer).flat(5)
        layers.map(layer => layer.setVisible(false))
        LayerSwitcher.renderPanel(map, toc.current, {reverse: true});
    }


    const updateInfoWindow = () => {
        highlightLayer?.getSource().clear();
        let parser = new WMSGetFeatureInfo();
        const view = map.getView();
        let layers = [];
        layers = map
            .getLayers()
            .getArray()
            .map((layer, index) => {
                if (index > 0) {
                    return layers.concat(visibleLayer(layer));
                }
            });
        layers = layers.filter((layer) => layer).flat(5);
        let features = []
        let selected = []
        layers.map((layer) => {
            if (layer.getVisible()) {
                console.log('click', layer)
                if (layer instanceof TileLayer) {
                    const item = layer.get("layer");
                    console.log('item', item)
                    var url = layer.getSource().getFeatureInfoUrl(onClick.coordinate, view.getResolution(), view.getProjection(),
                        {
                            'INFO_FORMAT': 'application/vnd.ogc.gml',
                            'QUERY_LAYERS': `${workspace}:${item.code}`
                        });
                    fetch(url)
                        .then(res => res.text())
                        .then(function (response) {
                            let features = parser.readFeatures(response)
                            //console.log('features', features)
                            if (features.length) {
                                selected = [...selected, {layer: item, feature: features[0]}]
                                setSelectedLayers(selected)
                                highlightLayer.getSource().addFeatures(features);
                            }
                        })
                } else if (layer instanceof VectorLayer) {
                    var features = map.getFeaturesAtPixel(onClick.pixel);
                    console.log('click', features);
                }
            }
        })
    }


    useEffect(() => {
        let remotes = [];
        StateManager.updateStateObject('toolType', null)
        const capOptions = {
            service: 'WMS', url: `${api.GEOSERVER_URL}/${workspace}/ows`, authKey: user.authKey,
        }
        client.fetchCapabilities(capOptions, function (xmlText, error) {
            //console.log(xmlText);
            if (xmlText) {
                var result = parser.read(xmlText);
                result.Capability.Layer.Layer.map((xmlLayerGroup) => {
                    let subLayers = []
                    if (xmlLayerGroup.Layer) {
                        let category;
                        switch (xmlLayerGroup.Name) {
                            case 'Administrative':
                                category = 'WASREB'
                                break;
                            case 'county_layers':
                                category = 'COUNTY'
                                break;
                            case 'wsp_layers':
                                category = 'WSP'
                                break;
                        }
                        xmlLayerGroup.Layer.map((xmlSubLayer) => {
                            let layers = []
                            if (xmlSubLayer.Layer) {
                                xmlSubLayer.Layer.map((xmlLayer) => {
                                    layers.push({
                                        layer: {code: xmlLayer.Name, name: xmlLayer.Title},
                                        extent: transformExtent(xmlLayer.EX_GeographicBoundingBox, 'EPSG:4326', 'EPSG:3857'),
                                        legend: xmlLayer.Style[0].LegendURL[0].OnlineResource,
                                        category: category
                                    })
                                })
                            }
                            if (layers.length > 0) {
                                subLayers.push({
                                    code: xmlSubLayer.Name,
                                    name: xmlSubLayer.Title,
                                    extent: transformExtent(xmlSubLayer.EX_GeographicBoundingBox, 'EPSG:4326', 'EPSG:3857'),
                                    layers: layers
                                })
                            } else {
                                subLayers.push({
                                    layer: {code: xmlSubLayer.Name, name: xmlSubLayer.Title},
                                    extent: transformExtent(xmlSubLayer.EX_GeographicBoundingBox, 'EPSG:4326', 'EPSG:3857'),
                                    legend: xmlSubLayer.Style[0].LegendURL[0].OnlineResource,
                                    category: category
                                })
                            }
                        })

                        if (subLayers.length > 0) {
                            if (!provider || (provider.category === 'WSP' && xmlLayerGroup.Name === 'wsp_layers') || (provider.category === 'COUNTY' && xmlLayerGroup.Name === 'county_layers')) {
                                console.log(provider, xmlLayerGroup.Name)
                                remotes.push({
                                    code: xmlLayerGroup.Name,
                                    name: xmlLayerGroup.Title,
                                    legend: xmlLayerGroup.Style.LegendURL,
                                    extent: transformExtent(xmlLayerGroup.EX_GeographicBoundingBox, 'EPSG:4326', 'EPSG:3857'),
                                    layers: subLayers.reverse(),

                                })
                            }
                        }

                    }
                })
                remotes.reverse()
                setRemoteLayers(remotes)
                console.log('remotes', remotes);

                let selected = null;

                let wfsParams = {
                    service: 'WFS',
                    version: '1.0.0',
                    request: 'GetFeature',
                    outputFormat: 'application/json',
                    authkey: user.authKey,
                }
                let category = provider ? provider.category : user.userOrg.category
                console.log('category', category)
                let cql = undefined;
                if (layerTask) {
                    selected = layerTask.editLayer;
                    let edits = layerTask.edits
                    if (edits.length == 1) {
                        edits.push(edits[0]) //add to self. CQL wont work with one value in IN
                    }
                    cql = `gid in (${edits.join(',')})`
                } else if (category === "WASREB") {
                    selected = 'country'
                } else if (category === "COUNTY") {
                    selected = 'county'
                    cql = `county_id=${provider ? provider.countyId : countyId}`;
                } else {
                    selected = 'wsp_licensed_service_area'
                    cql = `wsp_id=${provider ? provider.id : user.userOrg.id}`
                }
                setCql(cql)

                wfsParams.typeName = `${workspace}:${layerTask ? layerTask?.editLayer : selected}`
                if (cql) {
                    wfsParams.CQL_FILTER = cql;
                }
                let wfsUrl = `${api.GEOSERVER_URL + url.GET_WFS}?${client.formData(wfsParams)}`
                console.log('wfsParams', wfsParams)
                fetch(wfsUrl)
                    .then(res => res.json())
                    .then((response) => {
                        console.log('response', response)
                        const features = new GeoJSON().readFeatures(response);
                        let extent = new source.Vector({
                            features: features
                        }).getExtent()
                        if (features.length) {
                            console.log('features', features.length)
                            console.log('extent', extent)
                            setDefaultExtent(extent)
                            setupMap(remotes, selected, extent, cql)
                        }
                    }, (error) => {
                        console.log('error', error)
                    })

            }
        })

        delegate(mainRef.current, {
            content(reference) {
                const title = reference.getAttribute('title');
                reference.removeAttribute('title');
                return title;
            }, target: '[data-tippy-content]', placement: 'right', theme: 'oltb oltb-themed', //delay: [600, 100]
        })

        /* delegate(attributesRef.current, {
             content(reference) {
                 const title = reference.getAttribute('title');
                 reference.removeAttribute('title');
                 return title;
             }, target: '[data-tippy-content]', placement: 'bottom', theme: 'oltb oltb-themed', //delay: [600, 100]
         })*/

    }, [layerTask, provider])


    useEffect(() => {
        if (viewAll && selectedLayer) {
            let params = {
                LAYERS: `${workspace}:${selectedLayer.value}`, VIEW: true,
            }
            let viewCql = cql?.replace('=', '<>')

            if (viewCql) {
                params.CQL_FILTER = viewCql
            }

            let layerUrl = `${api.GEOSERVER_URL}/${workspace}/wms?${client.formData(params)}`
            // console.log('ViewAll Layer', layerUrl)

            let tileLayer = new layer.Tile({
                title: selectedLayer.label, visible: true, properties: params, source: new source.TileWMS({
                    url: layerUrl
                })
            });

            map.addLayer(tileLayer)

            params = {
                service: 'WFS',
                version: '1.0.0',
                request: 'GetFeature',
                typeName: `${workspace}:${selectedLayer.value}`,
                outputFormat: 'application/json',
                authkey: layerTask?.authKey ? layerTask?.authKey : user.authKey,
            }

            if (viewCql) {
                params.CQL_FILTER = viewCql
            }

            let wfsUrl = `${api.GEOSERVER_URL + url.GET_WFS}?${client.formData(params)}`
            console.log('params', params)
            fetch(wfsUrl)
                .then(res => res.text())
                .then((response) => {
                    let extent = new source.Vector({
                        features: new GeoJSON().readFeatures(response)
                    }).getExtent()
                    map.getView().fit(extent, duration);
                }, (error) => {
                    console.log('error', error)
                })
        } else {
            let layers = []
            layers = map?.getLayers().getArray().map((layer, index) => {
                if (index > 0) {
                    return layers.concat(visibleLayer(layer));
                }
            });

            if (layers) {
                layers = layers.filter(layer => layer !== undefined).flat(5)
                layers.map(layer => {
                    let typeName = layer.get('LAYERS');
                    let view = layer.get('VIEW');
                    if (view && typeName === `${workspace}:${selectedLayer.value}`) {
                        map.removeLayer(layer)
                        map.getView().fit(defaultExtent, duration);
                    }
                })
            }
        }

    }, [viewAll])

    useEffect(() => {
        let layers = []
        layers = map?.getLayers().getArray().map((layer, index) => {
            if (index > 0) {
                return layers.concat(visibleLayer(layer));
            }
        });

        layers = layers?.filter(layer => layer).flat(5)

        if (extendView) {
            layers.map(layer => {
                let typeName = layer?.get('LAYERS');
                if (typeName && layer.getVisible()) {
                    console.log('typeName', typeName)
                    let source = layer.getSource()
                    console.log('source', source)

                    source.updateParams({CQL_FILTER: `county_id = 34`});
                }
            })
            /*
            let params = {
                LAYERS: `${workspace}:${selectedLayer.value}`,
                VIEW: true,
            }
            let viewCql = cql?.replace('=', '<>')
            if (viewCql) {
                params.CQL_FILTER = viewCql
            }

            let layerUrl = `${api.GEOSERVER_URL}/${workspace}/wms?${client.formData(params)}`
            console.log('ViewAll Layer', layerUrl)

            let tileLayer = new layer.Tile({
                title: selectedLayer.label,
                visible: true,
                properties: params,
                source: new source.TileWMS({
                    url: layerUrl
                })
            });

            map.addLayer(tileLayer)

            params = {
                service: 'WFS',
                version: '1.0.0',
                request: 'GetFeature',
                typeName: `${workspace}:${selectedLayer.value}`,
                outputFormat: 'application/json',
                authkey: layerTask?.authKey ? layerTask?.authKey : user.authKey,
            }

            if (viewCql) {
                params.CQL_FILTER = viewCql
            }

            let wfsUrl = `${api.GEOSERVER_URL + url.GET_WFS}?${client.formData(params)}`
            console.log('params', params)
            fetch(wfsUrl)
                .then(res => res.text())
                .then((response) => {
                        let extent = new source.Vector({
                            features: new GeoJSON().readFeatures(response)
                        }).getExtent()
                        map.getView().fit(extent, duration);
                    },
                    (error) => {
                        console.log('error', error)
                    }
                )*/
        } else {
            if (layers) {
                layers = layers.filter(layer => layer !== undefined).flat(5)
                layers.map(layer => {
                    let typeName = layer.get('LAYERS');
                    let view = layer.get('VIEW');
                    if (view && typeName === `${workspace}:${selectedLayer.value}`) {
                        map.removeLayer(layer)
                        map.getView().fit(defaultExtent, duration);
                    }
                })
            }
        }
    }, [extendView])

    useEffect(() => {
        if(showLabel) {
            let label = labels.find(lbl => lbl.code === showLabel.item.layer.code)
            console.log(showLabel.layer.getVisible(), label)
            if(!showLabel.layer.getVisible()){
                if(label) {
                    label.layer.getSource().clear()
                    map.removeLayer(label.layer)
                    setLabels(labels.filter(lbl => lbl.code !== showLabel.item.layer.code))
                }
            }else{
                showLabels(map, showLabel.item)
            }
        }
        return function  cleanUp()  {
            setShowLabel(false)
        }
     },[showLabel])

    const setupMap = (remotes, selected, extent, cql) => {
        let groups = [];
        const satelliteLayer = new layer.Tile({
            title: 'Esri Satellite', visible: editing, source: new source.XYZ({
                attributions: ['Powered by Esri'],
                attributionsCollapsible: true,
                url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            }), type: 'base'
        })

        const defaultLayer = new layer.Tile({
            visible: !editing, title: 'ArcGIS World Topo', source: new source.XYZ({
                attributions: ['Powered by Esri'],
                attributionsCollapsible: true, // url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}',
                url: "https://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
            }), type: 'base'
        })

        setSatelliteLayer(satelliteLayer)
        setDefaultLayer(defaultLayer)

        groups.push(new layer.Group({
            title: 'Base map', layers: [
                new layer.Tile({
                    title: "None",
                    visible: false,
                    type: "base",
                }),
                defaultLayer, satelliteLayer, new layer.Tile({
                    title: 'Open Street Map', visible: false, source: new source.OSM(), type: 'base'
                }),
                new layer.Tile({
                    visible: false, title: 'ArcGIS Street Map', source: new source.XYZ({
                        attributions: ['Powered by Esri'],
                        attributionsCollapsible: true,
                        url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
                    }), type: 'base'
                }),
            ]
        }))

        let options = {
            target: 'ol-map',
            view: new View({
                center: transform(center, 'EPSG:4326', 'EPSG:3857'), zoom: zoom,
            }),
            controls: defaultControls({
                zoom: false,
                rotate: false
            }).extend([
                new Home({
                    element: 'oltb-data',
                    click: function () {
                        console.log("Home");
                        setHome(true)
                    },
                }),
                new Pan({element: 'oltb-data'}), new ZoomIn({
                    target: 'oltb-data', zoomed: function () {
                        console.log('Zoomed in');
                    }
                }), new ZoomOut({
                    target: 'oltb-data', zoomed: function () {
                        console.log('Zoomed out');
                    }
                }), new FullScreen({
                    target: 'oltb-data', enter: function (event) {
                        console.log('Enter fullscreen mode', event);
                    }, leave: function (event) {
                        console.log('Leave fullscreen mode', event);
                    }
                }),
                new MeasureTool({
                    target: "oltb-data", start: function (event) {
                        console.log('Measure Start');
                    }, end: function (event) {
                        console.log('Measure end', event.feature);
                    }, abort: function (event) {
                        console.log('Measure abort');
                    }, error: function (event) {
                        console.log('Measure error');
                    }
                }),

                new Info({
                    target: 'oltb-data', click: function (active) {
                        console.log('Info');
                        setShowInfoWindow(active)
                    }
                }),

                new Overview({target: 'oltb-data'}),

                new GraticuleTool({target: 'oltb-data'}),

                new MyLocation({
                    target: 'oltb-data', location: function (location) {
                        console.log('Location', location);
                    }, error: function (error) {
                        console.log('Location error', error);
                    }
                }),
                new Refresh({
                    target: "oltb-data",
                    click: function () {
                        setRefresh(true)
                    }
                }),
                new CanvasScaleLine(),//new Layers({target: 'oltb-data'}),
                new CanvasTitle(),//new Layers({target: 'oltb-data'}),
                new CanvasAttribution(),
                new MousePosition({
                    coordinateFormat: createStringXY(6),
                    projection: 'EPSG:4326',

                }),
            ]),
            interactions: defaults({dragPan: !editing, zoom: false}),
            overlays
    :
        [popup]
    }
        let mapObject = new Map(options);


        const highlightStyle = new Style({
            stroke: new Stroke({
                color: "rgba(0, 204, 255)",
                width: 2,
            }),
        });

        let highlightOverlay = new layer.Vector({
            style: highlightStyle,
            source: new source.Vector({
                crossOrigin: 'anonymous',
            }),
            map: mapObject,
        });
        setHighlightLayer(highlightOverlay)

        remotes.map((group, index) => {
            let layers = [];
            group.layers.map((item, index) => {
                if (item.layers) {
                    let subLayers = [];
                    item.layers.map((subItem, index) => {
                        let layer = getLayer(mapObject, subItem, extent, selected, cql, defaultLayer, satelliteLayer, highlightOverlay)
                        if (layer) {
                            subLayers.push(layer);
                        }

                    })
                    layers.push(new layer.Group({
                        title: item.name, fold: 'open', layers: subLayers,
                    }))
                } else {
                    let layer = getLayer(mapObject, item, extent, selected, cql, defaultLayer, satelliteLayer, highlightOverlay)
                    if (layer) {
                        layers.push(layer);
                    }
                }
            })
            groups.push(new layer.Group({
                title: group.name, fold: 'open', layers: layers,
            }))
        })


        groups.forEach(function (layerGroup) {
            mapObject.addLayer(layerGroup);
        })

        if (extent) {
            mapObject.getView().fit(extent, duration);
        }


        LayerSwitcher.renderPanel(mapObject, toc.current, {reverse: true});
        /* var sidebar = new SidebarControl({element: 'sidebar', target: 'main', position: 'left'});
          mapObject.addControl(sidebar);


        var sidebar = new SidebarControl({element: 'sidebar', target: 'main', position: 'left' });
         mapObject.addControl(sidebar);*/


        // Add a layer switcher outside the map
        var search = new SearchNominatim({
            position: true,
            className: 'block border disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm',
            placeholder: 'Search location...'// Search, with priority to geo position
        });
        var requestData = search.requestData.bind(search);
        search.requestData = function (s) {
            var data = requestData(s);
            data.countrycodes = 'ke';
            return data;
        };

        const mousePositionDiv = document.getElementsByClassName("ol-mouse-position");
        var printDialog = new PrintDialog({className: 'ol-print'});
        printDialog.setSize('A4');
        printDialog.on(['print', 'error', 'show', 'hide'], function (e) {
            console.log('print', e)
            if (e.type == 'show') {
                toolbox.current.style.display = 'none'
                mousePositionDiv[0].style.display = 'none'
            } else if (e.type == 'hide') {
                toolbox.current.style.display = 'inline-flex'
                mousePositionDiv[0].style.display = 'block'
            } else if (e.image) {
                if (e.pdf) {
                    // Export pdf using the print info
                    var pdf = new PDF({
                        orientation: e.print.orientation,
                        unit: e.print.unit,
                        format: e.print.size
                    });
                    pdf.addImage(e.image, 'JPEG', e.print.position[0], e.print.position[0], e.print.imageWidth, e.print.imageHeight);
                    pdf.save(e.print.legend ? 'legend.pdf' : 'map.pdf');
                } else {
                    // Save image as file
                    e.canvas.toBlob(function (blob) {
                        var name = (e.print.legend ? 'legend.' : 'map.') + e.imageType.replace('image/', '');
                        this.print.saveAs(blob, name);
                    }, e.imageType, e.quality);
                }
            } else {
                console.warn('No canvas to export');
            }
        })

        mapObject.addControl(search);
        mapObject.addControl(printDialog);

        search.on('select', function (e) {
            if (e.search.geojson) {
                var format = new GeoJSON();
                var f = format.readFeature(e.search.geojson, {
                    dataProjection: "EPSG:4326", featureProjection: mapObject.getView().getProjection()
                })
                var view = mapObject.getView();

                view.fit(f.getGeometry().getExtent(), duration);
            } else {
                mapObject.getView().animate({
                    center: e.coordinate, zoom: Math.max(mapObject.getView().getZoom(), 13)
                });
            }
        });

        /* Add a layer switcher outside the map
        var switcher = new LayerSwitcher({target: '#home div'});
        mapObject.addControl(switcher);*/

        var parser = new WMSGetFeatureInfo();


        // Map pointer move
        mapObject.on('pointermove', function (evt) {
            let features = []
            mapObject.forEachFeatureAtPixel(evt.pixel, (feature) => {
                features.push(feature)
            });

            if (features.length) {
                features.map(feature => {
                    let properties = feature.getProperties();
                    let title = properties.label ? properties.label : properties.display
                    //console.log('properties', properties)
                    // console.log('title', title)
                    if (title && !editing) {
                        popup.show(evt.coordinate, `<h1>${title}</h1>`);
                    }
                })
            } else {
                popup.hide()
            }
        })


        mapObject.on('singleclick', (evt) => {
            setOnClick(evt)
        })

        mapObject.on('pointermove', function (e) {
            var pixel = mapObject.getEventPixel(e.originalEvent);
            var hit = mapObject.hasFeatureAtPixel(pixel);
            mapObject.getViewport().style.cursor = hit ? 'pointer' : '';
        });

        setMap(mapObject)

        //Initialize static managers with reference to map
        const managers = [InfoWindowManager, LayerManager, TooltipManager];
        managers.forEach((manager) => {
            manager.init(mapObject);
        });
    }
    const flatten = (item) => {
        if (!Array.isArray(item)) {
            return item
        } else {
            flatten([].concat.apply([], item))
        }
    }
    const visibleLayer = (layer) => {
        if (layer instanceof TileLayer || layer instanceof VectorLayer) {
            return layer;
        } else if (layer instanceof LayerGroup) {
            let layers = layer.getLayers().getArray().map(layer => {
                if (layer instanceof TileLayer || layer instanceof VectorLayer) {
                    return layer;
                } else {
                    return visibleLayer(layer)
                }
            })
            return layers;
        }
    }


    const showLabels = (mapObject, item, column) => {
        if (item.legend) {
            let legendURL = item.legend.replace('image%2Fpng', 'application/json');
            fetch(legendURL)
                .then(res => res.json())
                .then((response) => {
                    console.log('Legend', response.Legend)
                    if (response.Legend.length) {
                        let textColor;
                        let iconUrl;
                        let symbolizer = response.Legend[0].rules[0].symbolizers[0];
                        if (symbolizer.Polygon) {
                            textColor = symbolizer.Polygon.stroke
                        } else if (symbolizer.Line) {
                            textColor = symbolizer.Line.stroke
                        } else if (symbolizer.Point) {
                            textColor = symbolizer.Point.graphics[0].fill
                            iconUrl = symbolizer.Point.url
                        }
                        textColor = textColor?.startsWith('#') ? textColor : `#${textColor}`
                        console.log('textColor', textColor)
                        addLabel(mapObject, item, textColor, column);
                    }
                })
        } else {
            addLabel(mapObject, item, '#f4f4f4', column);
        }
    }

    const addLabel = (mapObject, item, textColor, column) => {
        let labelStyle = new Style({
            text: new Text({
                font: '16px Calibri,sans-serif', fill: new Fill({
                    color: textColor,
                })
            })
        });

        const labelProps = ['display','label']

        const params = {
            service: 'WFS',
            version: '1.3.0',
            request: 'describeFeatureType',
            typeName: `${workspace}:${item.layer.code}`,
            outputFormat: 'application/json',
        }
        fetch(`${api.GEOSERVER_URL}/wfs?${client.formData(params)}`)
            .then(res => res.json())
            .then((response) => {
                console.log('describeFeatureType', response)
                let featureProps = response.featureTypes[0].properties

                let  foundProps = featureProps.filter(prop => labelProps.includes(prop.name) || prop.name.indexOf('geom') >= 0).map(prop => prop.name)
                console.log('foundProps', foundProps)
                if(foundProps.length) {
                    const params = {
                        service: 'WFS',
                        version: '1.0.0',
                        request: 'GetFeature',
                        typeName: `${workspace}:${item.layer.code}`,
                        outputFormat: 'application/json',
                    }
                    if(cql && item.category  === category){
                        params.CQL_FILTER = cql
                    }
                    if (foundProps.length) {
                        params.propertyname = foundProps.join(',')
                    }
                    console.log('params', params)

                    let layerUrl = `${api.GEOSERVER_URL + url.GET_WFS}?${client.formData(params)}`
                    console.log('layerUrl', layerUrl)

                    console.log('item.layer.code', item.layer.code)
                    console.log('labels', labels)
                    let label = labels.find(layer => layer.code === item.layer.code)
                    console.log('label', label)
                    let labelLayer;

                    if (label) {
                        label.layer.getSource().clear()
                        map.removeLayer(label.layer)
                        setLabels(labels.filter(lbl => lbl.code !== showLabel.item.layer.code))
                    } else {
                        labelLayer = new layer.Vector({
                            style: function (feature) {
                                //var origGeom = feature.getGeometry();
                                // if (origGeom.getType() === 'Polygon' || origGeom.getType() === 'MultiPolygon') {
                                labelStyle
                                    .getText()
                                    .setText([`${column ? feature.get(column) : feature.get('display') ? feature.get('display') : feature.get('label') ? feature.get('label') : ''}`, 'bold 13px Calibri, sans-serif', '', '', '\n', '', '', 'italic 11px Calibri,sans-serif',]);
                                return [labelStyle];
                                // }
                            }, source: new source.Vector()
                        })
                        mapObject.addLayer(labelLayer)
                        fetch(layerUrl)
                            .then(res => res.json())
                            .then((response) => {
                               // console.log('label response', response)
                                const features = new GeoJSON().readFeatures(response);
                                if (features.length > 0) {
                                    labelLayer.getSource().addFeatures(features);
                                    let ls = [...labels,{code: item.layer.code, layer: labelLayer}]
                                    console.log('labels',ls)
                                    setLabels(ls)
                                }
                            });
                    }
                }
            })
    }

    const getLayer = (mapObject, item, extent, selected, cql, defaultLayer, satelliteLayer, highlightOverlay) => {
        if (layerTask && selected === item.layer.code) {
            return setupReview(mapObject, extent)
        } else {
            let visible = item.layer.code.startsWith(selected);
            const params = {
                LAYERS: `${workspace}:${item.layer.code}`
            }

            if (cql && (item.category === category || (category === 'COUNTY' && item.category === 'WSP'))) {
                params.CQL_FILTER = cql
            }

            let layerUrl = `${api.GEOSERVER_URL}/${workspace}/wms?${client.formData(params)}`
            // console.log('getLayer', layerUrl)
            params.item = item

            let actionVisible = category !== 'WASREB' && (item.category === category)
            let label = labels.find(layer => layer.code === item.layer.code)
            console.log('getLayer label',label)
            let tileLayer = new layer.Tile({
                title: item.legend ? `
        <div class="-mt-1 flex">
            <div class="block">
                 <span class="p-1"> ${item.layer.name}</span>
                 <img class="p-1" src="${item.legend + '&transparent=true'}" /> 
             </div>
        </div>` : `<div class="-mt-1">${item.layer.name}</div>`,
                actions: !layerTask ? [{
                    title: 'Show Attributes', click: (event) => {
                        console.log('show attributes')
                        openAttributTable({code: item.layer.code, name: item.layer.name, category: category})
                        tileLayer.setVisible(true)
                        LayerSwitcher.renderPanel(mapObject, toc.current, {reverse: true});
                    }
                },
                    {
                        title: 'Show Labels', click: (event) => {
                            tileLayer.setVisible(true)
                            LayerSwitcher.renderPanel(mapObject, toc.current, {reverse: true});
                            setShowLabel({layer: tileLayer, item: item})
                        }
                    },
                    {
                        title: 'Add Feature',
                        click: (event) => {
                            setEditing({title: 'Add Feature', mode: 'insert', layer: item.layer})
                        },
                        visible: actionVisible && user.permissions.includes(Permission.can_add_features)
                    }, {
                        title: 'Edit Layer', click: (event) => {
                            setEditing({title: 'Editing', mode: 'update', layer: item.layer})
                        },
                        visible: actionVisible && user.permissions.includes(Permission.can_edit_features)
                    }, {
                        title: 'Delete Feature(s)', click: (event) => {
                            setEditing({title: 'Delete Features', mode: 'delete', layer: item.layer})
                        },
                        visible: actionVisible && user.permissions.includes(Permission.can_delete_features)
                    }, {
                        title: 'Bulk Upload', click: (event) => {
                            setEditing({title: 'Bulk Upload', mode: 'upload', layer: item.layer})
                        },
                        visible: actionVisible && user.permissions.includes(Permission.can_add_features)
                    }, {
                        title: 'Add Incident', click: (event) => {
                            setEditing({title: 'Add incident', mode: 'add_incident', layer: item.layer})
                        },
                        visible: actionVisible && item.category == 'WSP' && user.permissions.includes(Permission.can_add_features)
                    }] : undefined,
                visible: visible,
                extent: item.extent,
                layer: item.layer,
                source: new source.TileWMS({
                    url: layerUrl
                })
            });

            const defaultExtent = extent ? extent : item.extent
            mapObject.getView().fit(defaultExtent, duration);
            if (selected === item.layer.code) {
                setShowLabel({layer: tileLayer, item: item})
            }

            tileLayer.on("change:visible", function () {
                if(!tileLayer.getVisible()) {
                    setShowLabel({layer: tileLayer, item: item})
                }
            });
            return tileLayer;
        }
    }


    const handleSearch = (selected, props, mode, query) => {
        setProperties(props)
        const typeName = `${workspace}:${selected.code}`
        let params = {
            LAYERS: typeName,
            crossOrigin: 'anonymous',
        }
        console.log('selected', selected)
        if (cql && selected.category === category) {
            params.CQL_FILTER = cql
        }
        let layerUrl = `${api.GEOSERVER_URL}/${workspace}/wms?${client.formData(params)}`
        console.log('Search Layer', layerUrl)

        if (searchLayer) {
            map.removeLayer(searchLayer)
            console.log('searchLayer', searchLayer)
        }

        params = {
            service: "WFS",
            version: "1.0.0",
            request: "GetFeature",
            typeName: typeName,
            outputFormat: "application/json",
        };

        if (query) {
            if (cql) {
                params.CQL_FILTER = encodeURIComponent(`${cql} and (${query})`)
            } else {
                params.CQL_FILTER = encodeURIComponent(query)
            }
            console.log('search params', params)
            highlightLayer.getSource().clear();
            fetch(`${api.GEOSERVER_URL + "/ows"}?${client.formData(params)}`)
                .then((res) => res.json())
                .then((response) => {
                    const features = new GeoJSON().readFeatures(response);
                    console.log("search", features);
                    if (features.length) {
                        highlightLayer.getSource().addFeatures(features);
                        let vectorSource = new source.Vector({
                            features: features,
                        });
                        let extent = vectorSource.getExtent();
                        map.getView().fit(extent, duration);
                        setSearchData(features)
                        openAttributTable({code: selected.code, name: selected.name, category: category})
                        let tileLayer = new layer.Tile({
                            title: selected.name,
                            visible: true,
                            properties: params,
                            layer:selected,
                            source: new source.TileWMS({
                                url: layerUrl
                            })
                        });
                        setSearchLayer(tileLayer)
                        map.addLayer(tileLayer)
                        map.getView().fit(defaultExtent, duration);
                    } else {
                        highlightLayer.getSource().clear();
                        Toast.error({text: `No records found for your selection`, autoremove: 3000})
                    }
                });
        }else{
            let tileLayer = new layer.Tile({
                title: selected.name,
                visible: true,
                properties: params,
                layer:selected,
                source: new source.TileWMS({
                    url: layerUrl
                })
            });
            setSearchLayer(tileLayer)
            map.addLayer(tileLayer)
            map.getView().fit(defaultExtent, duration);
        }
    }


    const handleDragStart = (event) => {
        // This method runs when the dragging starts
        console.log("Started...")
    }

    const handleDrag = (event) => {
        // This method runs when the component is being dragged
        console.log("Dragging...")
    }

    const handleDragEnd = (event) => {
        // This method runs when the dragging stops
        console.log("Ended...")
    }

    const closeAttributTable = () => {
        setShowAttributeTable(false)
        mapRef.current.style.height = '100%'
        mapRef.current.style.transition = 'height 100ms'
    }

    const openAttributTable = (layer) => {
        setSelectedLayer(layer)
        setShowAttributeTable(layer)
        mapRef.current.style.height = '60%'
        mapRef.current.style.transition = 'height 500ms'
    }

    const onAttributesSelected = (selected) => {
        console.log('selected', selected)
        let source = highlightLayer?.getSource()
        source.clear();

        if (selected.length) {
            source.addFeatures(selected);
            map.getView().fit(source.getExtent(), duration)
        } else {
            map.getView().fit(defaultExtent, duration)
        }
    }
    const deleteSelected = (layer) => {
        Dialog.confirm({
            html: `Are you sure you want to delete the seleced features from <strong>${layer.name}</strong>?`,
            onConfirm: () => {
                deleted.forEach(f => highlightLayer?.getSource().removeFeature(f));
                client.Transact({
                    mode: 'delete',
                    features: deleted,
                    edit: feature.edit,
                    target: feature.target,
                    targetNamespace: feature.targetNamespace,
                    targetPrefix: feature.targetPrefix,
                    workspace: workspace,
                    provider: user.userOrg,
                }, () => {
                    setDeleted(false)
                })

            }
        });
    }

    const editingCompleted = (data) => {
        console.log('editingCompleted', data)
        if (editing.mode === 'insert') {
            if(drawInteraction) {
                map.addInteraction(drawInteraction);
            }
        }
        setModified(false)
    }
    return (<React.Fragment>
        <main className="flex-grow">
            <div ref={mainRef} id="main" className="main flex flex-grow h-full">

                <Sidebar id="sidebar" collapsed={collapsed} selected={selected} onOpen={onOpen.bind(this)}
                         onClose={onClose.bind(this)} closeIcon={<FontAwesomeIcon icon={faXmark}/>}>
                    <Tab id="home" header="Layers" icon={<FontAwesomeIcon icon={faLayerGroup}/>}>
                        <div ref={toc} id="layers" className="layer-switcher"></div>
                    </Tab>
                    <Tab id="search" header="Selection"
                         icon={<FontAwesomeIcon icon={faSearch}/>}>
                        {user.permissions.includes(Permission.can_view_select) && (
                            <Search layerTask={layerTask} action={handleSearch}/>)}
                    </Tab>
                </Sidebar>
                <div className="relative w-full overflow-hidden">
                    <div id="oltb"></div>
                    <div id="oltb-data" ref={toolbox}></div>
                    <div ref={mapRef} id="ol-map" ></div>
                    {selectedLayers.length > 0 && (<InfoWindow data={selectedLayers} onClose={onCloseInfoWindow}/>)}
                    {showAttributeTable && (
                        <AttributeTable layer={showAttributeTable} cql={cql} selectedData={searchData}
                                        close={closeAttributTable} onSelected={onAttributesSelected}/>)}

                    {editing && (
                        <Draggable>
                            <div
                                className="w-96 bg-white rounded-r shadow-lg mt-12 mr-12 overflow-hidden top-6 right-6 fixed z-5"
                                aria-label="Sidebar">
                                <div
                                    className="py-2 px-4 text-white  bg-slate-800 border-b border-slate-700 flex justify-between cursor-pointer">
                                    <div className="flexjustify-start ">
                                        <div className="uppercase  font-bold">
                                            {editing.title}
                                        </div>
                                        <div className="">
                                            {editing.layer.name}
                                        </div>
                                    </div>
                                    <div className="p-2"
                                         onClick={() => setShowConfirmation(true)}>
                                        <FontAwesomeIcon icon={faXmark}/>
                                    </div>
                                </div>
                                <div className="bg-white">
                                    {modified && (
                                        <EditForm map={map} data={feature} feature={modified} editLayer={highlightLayer}
                                                  editing={editing} completed={editingCompleted}/>)}

                                    {deleted?.length > 0 && (<div className="flex justify-center">
                                        <div className="basis-6/12 m-4">
                                            <button type="button" onClick={(e) => deleteSelected(editing.layer)}
                                                    className="w-full text-white bg-red-700 hover:bg-red-900 font-medium rounded-lg text-md p-2">
                                                {`Delete Selected (${deleted.length})`}
                                            </button>
                                        </div>
                                    </div>)}

                                    {showBulkUpload && (<div className="flex justify-center">
                                        <div className="basis-6/12 m-4">
                                            <BulkUpload layer={showBulkUpload}/>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </Draggable>)}
                </div>
            </div>

            <Modal
                show={showConfirmation}
                size="md"
                popup={true}
                onClose={() => setShowConfirmation(false)}
            >
                <Modal.Header/>
                <Modal.Body>
                    <div className="text-center">
                        <svg className="w-16 h-16 text-red-600 mx-auto mb-6" fill="none" stroke="currentColor"
                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                        </svg>
                        <h3 className="mb-5 font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to exit editing?
                            You will lose any changes that have not been saved.
                        </h3>
                        <div className="flex justify-center gap-4">
                            <button data-modal-toggle="popup-modal" type="button"
                                    onClick={() => setEditing(false)}
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                Yes, I'm sure
                            </button>
                            <button data-modal-toggle="popup-modal" type="button"
                                    onClick={() => setShowConfirmation(false)}
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No,
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </main>
    </React.Fragment>)
}
export default MapComponent;