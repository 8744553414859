import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";

import List from "./List";
import Content from "./Content";

import "./VerticalTab.css";

function VerticalTab({titles, content, options}) {
    const [activeTabId, setActiveTabId] = useState(0);

    function btnClick(id) {
        setActiveTabId(id);
    }

    return (
        <div>
            <div className="flex">
                <div className={options?.titleClass ? options?.titleClass : 'basis-1/5 p-2'}>
                    <ul className="section__Jobs-styledTabList">
                        {titles.map((title, index) => (
                            <List
                                key={index}
                                onClick={btnClick}
                                index={index}
                                activeTabId={activeTabId}>
                                {title}
                            </List>
                        ))}
                    </ul>
                </div>
                <div className={options?.titleClass ? options?.contentClass : 'basis-4/5 p-2'}>
                    {content?.map((content, index) => (
                        <Content
                            key={index}
                            index={index}
                            activeTabId={activeTabId}>
                            {content}
                        </Content>
                    ))}
                </div>
            </div>
            <span className={
                    activeTabId === 0
                        ? "index1-chosen"
                        : activeTabId === 1
                            ? "index2-chosen"
                            : "index3-chosen"
                }
            >
        &nbsp;
      </span>
        </div>
    );
}

export default VerticalTab;
