import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import Select from 'react-select'
import {useSelector} from 'react-redux';
import Foot from '../../pages/Layouts/Footer'
import Header from '../../pages/Layouts/Header'
import {FileUploader} from "react-drag-drop-files";
import * as api from '../../apis/APIClient';
import {
    Button,
    Label,
} from 'flowbite-react'


import * as Yup from "yup";
import {useFormik, Formik, Form} from "formik";
import sssp_customer_meter from '../../assets/files/sssp_customer_meter.zip';
import sssp_service_area from '../../assets/files/sssp_service_area.zip';
import sssp_water_reservior from '../../assets/files/sssp_water_reservior.zip';
import sssp_water_source from '../../assets/files/sssp_water_source.zip';
import sssp_water_treatment_plant from '../../assets/files/sssp_water_treatment_plant.zip';
import wsp_actual_served_area from '../../assets/files/wsp_actual_served_area.zip';
import wsp_appurtenance from '../../assets/files/wsp_appurtenance.zip';
import wsp_appurtenance_bulk from '../../assets/files/wsp_appurtenance_bulk.zip';
import wsp_bulk_meter from '../../assets/files/wsp_bulk_meter.zip';
import wsp_customer_meter from '../../assets/files/wsp_customer_meter.zip';

import wsp_district_metering_area from '../../assets/files/wsp_district_metering_area_template.zip';
import wsp_fitting from '../../assets/files/wsp_fitting_template.zip';
import wsp_licensed_service_area from '../../assets/files/wsp_licensed_service_area_template.zip';
import wsp_low_income_area from '../../assets/files/wsp_low_income_area_template.zip';
import wsp_network_incident from '../../assets/files/wsp_network_incident_template.zip';
import wsp_plot from '../../assets/files/wsp_plot_template.zip';
import wsp_sanitation_facility from '../../assets/files/wsp_sanitation_facility_template.zip';
import wsp_sewer_mahole from '../../assets/files/wsp_sewer_mahole_template.zip';
import wsp_sewer_pipeline from '../../assets/files/wsp_sewer_pipeline_template.zip';
import wsp_waste_water_treatment from '../../assets/files/wsp_waste_water_treatment_template.zip';
import wsp_water_pipeline from '../../assets/files/wsp_water_pipeline_template.zip';
import wsp_water_pump from '../../assets/files/wsp_water_pump_template.zip';
import wsp_water_sampling_point from '../../assets/files/wsp_water_sampling_point_template.zip';
import wsp_water_source from '../../assets/files/wsp_water_source_template.zip';
import wsp_water_storage from '../../assets/files/wsp_water_storage_template.zip';
import wsp_water_treatment_plant from '../../assets/files/wsp_water_storage_template.zip';
import wsp_zone from '../../assets/files/wsp_zone.zip';

const files = [
    {
        file: wsp_actual_served_area,
        code: 'wsp_actual_served_area',
        description: 'WSP Actual Served Area are the polygon representing the Actual Served Area of the WSP with the network extent. Mostly lies within the service Area',
    },
    {
        file: wsp_appurtenance,
        code: 'wsp_appurtenance',
        description: 'Water appurtenance point feature are the representation of any fixtures along the water pipeline be it al valves, hydrant e.t.c'
    },
    {
        file: wsp_appurtenance_bulk,
        code: 'wsp_appurtenance_bulk',
        description: 'Water appurtenance point feature are the representation of any fixtures along the water pipeline be it al valves, hydrant e.t.c'
    },
    {
        file: wsp_bulk_meter,
        code: 'wsp_bulk_meter',
        description: 'Bulk meter are the points representing flow meters on the main pipe used to measure volume of water for the purpose of calculating Non-Revenue Water (NRW) and are often located at the boundary of District Metering Area (DMA) which are water management territory'
    },
    {
        file: wsp_customer_meter,
        code: 'wsp_customer_meter',
        description: 'WSP Customer Meter'
    },
    {
        file: sssp_customer_meter,
        code: 'sssp_customer_meter',
        description: 'SSSP Customer Meter'
    },
    {name: 'SSSP Service Area', file: sssp_service_area, code: 'sssp_service_area', description: 'The polygon representing the Service area licensed by WASREB'},
    {
        file: sssp_water_reservior,
        code: 'sssp_water_reservoir',
        description: 'These are points that represent storage facilities where water is stored'
    },
    {
        file: sssp_water_source,
        code: 'sssp_water_source',
        description: 'These are the point representation of the source of water. They comprise of all possible water sources which are: Intakes, Boreholes, Dam, River, Spring, and Lake'
    },
    {
        file: sssp_water_treatment_plant,
        code: 'sssp_water_treatment_plant',
        description: 'Waste Water Treatment Plant point representation of where waste water is treated.'
    },
    {
        file: wsp_district_metering_area,
        code: 'wsp_district_metering_area',
        description: 'These are the polygons that represent a District Metering Area (DMA) used to measure the water getting in and out of the boundary for the purpose of measuring water balance. The are used to determine Non-Revenue Water (NRW), the water lost from the production point to the consumer point through physical and non-physical losses'
    },
    {
        file: wsp_fitting,
        code: 'wsp_fitting',
        description: ''
    },
    {
        file: wsp_licensed_service_area,
        code: 'wsp_licensed_service_area',
        description: 'The polygon representing the Service area licensed by WASREB'
    },
    {
        file: wsp_low_income_area,
        code: 'wsp_low_income_area',
        description: 'These are polygons representing the Low Incomed Areas within a WSP'
    },
    {
        file: wsp_network_incident,
        code: 'wsp_network_incident',
        description: 'These points are reorientation where and water or sewer incident occur say a water pipe burst. They serve to assist the operation and maintenance of the network'
    },
    {
        file: wsp_plot,
        code: 'wsp_plot',
        description: ''
    },
    {
        file: wsp_sanitation_facility,
        code: 'wsp_sanitation_facility',
        description: 'Sanitation facilities are points representing sewer, or on-site sanitation facilities which are cistern flush, pour flush, key hole, VIP, Composting, and eco-san'
    },
    {
        file: wsp_sewer_mahole,
        code: 'wsp_sewer_manhole',
        description: 'WSP, WSP Sewer Manhole'
    },
    {
        file: wsp_sewer_pipeline,
        code: 'wsp_sewer_pipeline',
        description: 'Sewer pipelines are polylines representing sewer pipes from the customer connection to the waste water treatment plant'
    },
    {
        file: wsp_waste_water_treatment,
        code: 'wsp_waste_water_treatment',
        description: 'Waste Water Treatment Plant point representation of where waste water is treated.'
    },
    {
        file: wsp_water_pipeline,
        code: 'wsp_water_pipeline',
        description: 'WSP, WSP Water Pipeline'
    },
    {
        file: wsp_water_pump,
        code: 'wsp_water_pump',
        description: 'These point features represent the location of pumps; however, pumps are best represented as graphs for hydraulic models their locations are mapped to keep an inventory and where it is'
    },
    {
        file: wsp_water_sampling_point,
        code: 'wsp_water_sampling_point',
        description: 'These are points representation of water sampling points at the water pipeline, tanks, or consumer connection to determine water quality'
    },
    {
        file: wsp_water_source,
        code: 'wsp_water_source',
        description: 'These are the point representation of the source of water. They comprise of all possible water sources which are: Intakes\n' +
            ' Boreholes, Dam, River, Spring, and Lake'
    },
    {
        file: wsp_water_storage,
        code: 'wsp_water_storage',
        description: 'These are points that represent storage facilities where water is stored'
    },
    {
        file: wsp_water_treatment_plant,
        code: 'wsp_water_treatment_plant',
        description: 'Waste Water Treatment Plant point representation of where waste water is treated.'
    },
    {
        file: wsp_zone,
        code: 'wsp_zone',
        description: 'WSP Zones are water management boundaries'
    },
]

const Templates = () => {
    const {user} = useSelector((state) => state.auth);
    const category = user.userOrg?.category;
    const [groups, setGroups] = useState([])
    useEffect(() => {
        api.getGroupedLayers({category: category}, (data, error) => {
            console.log('data', data)
            let layers = data.filter(group => group.code !== 'Administrative').map(group => {
                return {
                    name: group.name,
                    layers: group.layers.map(layer => {
                        if (layer.layers) {
                            return {
                                name: layer.name,
                                layers: layer.layers.map(layer => {
                                    let available = files.find(file => file.code === layer.layer.code)
                                    if (available) {
                                        available.name = layer.layer.name
                                    } else {
                                        available = layer.layer
                                    }
                                    return available;
                                }).reverse()
                            }
                        } else {
                            let available = files.find(file => file.code === layer.layer.code)
                            if (available) {
                                available.name = layer.layer.name
                            } else {
                                available = layer.layer
                            }
                            //console.log('layer', layer)
                            if (layer.name) {
                                return {
                                    name: layer.name,
                                    layers: [available]
                                }
                            } else {
                                return [available]
                            }
                        }
                    }).reverse()
                }
            })
            console.log('layers', layers)
            setGroups(layers)
        })
    }, [])

    return (<div className="text-gray-500 bg-gray-100 font-body min-h-screen flex flex-col justify-center align-middle">
            <Header/>
            <main className="flex-grow">
                <div className="container mx-auto py-12">
                    <h1 className="text-lg dashboard-heading">Layer Templates</h1>
                    <div className="container mx-auto py-12">
                        {groups && (
                            <table className="table-auto border-collapse border border-slate-500 w-full">
                                <tbody>
                                {groups.map((group, index) => (
                                    <tr key={index}>
                                        <td>
                                            <p className="p-3">{group.name}</p>
                                        </td>
                                        <td>
                                        {group.layers.map((layer, index) => (
                                                layer.layers ? (
                                                    <table className="table-auto w-full">
                                                        <tbody>
                                                        <tr key={index}>
                                                            <td className="border border-slate-300  w-1/5">
                                                                <p className="p-3">{layer.name}</p>
                                                            </td>
                                                            <td className="border border-slate-300 w-4/5">
                                                                {layer.layers.map((layer, index) => (
                                                                    <table
                                                                        className="table-auto w-full">
                                                                        <tbody>
                                                                        <tr key={index}>
                                                                            <td className="border border-slate-300 w-2/5" >
                                                                                { layer.file ?
                                                                                    (<a className="font-medium text-blue-600 underline  p-3"
                                                                                        download
                                                                                        href={layer.file}
                                                                                        download>{layer.name}</a>) :
                                                                                    (<p className="p-3">{layer.name}</p>)
                                                                                }
                                                                            </td>
                                                                            <td className="border border-slate-300 p-3 w-3/5">{layer?.description}</td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>))}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    )
                                                    : layer.map((layer, index) => (
                                                        <table key={index} className="table-auto w-full">
                                                        <tbody>
                                                        <tr>
                                                            <td className="border border-slate-300 w-2/5" >
                                                                {layer.file ?
                                                                    (<a className="font-medium text-blue-600 underline  p-3"
                                                                        download
                                                                        href={layer.file}
                                                                        download>{layer.name}</a>) :
                                                                    (<p className="p-3">{layer.name}</p>)
                                                                }
                                                            </td>
                                                            <td className="border border-slate-300 p-3 w-3/5">{layer?.description}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>))
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </main>
            <Foot/>
        </div>
    );
}

export default Templates
export {files}