import React, {useMemo, useEffect, useState} from 'react';
import {api} from "../../config";
import Pagination from '../../components/Pagination';
import * as url from "../../helpers/url_helper";
import Header from '../Layouts/Header';
import {Checkbox, Table, Button, Label, Modal, TextInput, Dropdown, FormControl} from 'flowbite-react'
import Foot from '../Layouts/Footer';
import Select from 'react-select';
import * as Yup from "yup";
import {useFormik} from "formik";
import * as client from '../../apis/APIClient';
import {useHistory} from 'react-router-dom';
import {FiEdit} from 'react-icons/fi';
import {MdOutlineDeleteOutline} from 'react-icons/md';
import {useLocation} from "react-router-dom";
import {CircularProgress} from '@mui/material';
import MUIDataTable from "mui-datatables";
import {useSelector} from 'react-redux';
import Moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const ReadMore = ({children}) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const size = 150
    return (
        <p className="text">
            {isReadMore ? text.slice(0, size) : text}
            {text.length > size && (<span onClick={toggleReadMore} className="read-or-hide  text-blue-600 ">
        {isReadMore ? "...read more" : " show less"}
      </span>)}
        </p>
    );
};


const DataRequests = () => {
    const {user} = useSelector((state) => state.auth);
    const [pending, setPending] = useState(true);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);

    const handleClickOpen = (status) => {
        setStatus(status)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const submit = async () => {
        var response = await client.updateRequest(status);
        loadData()
    };

    const columns = [
        {
            name: "ticketNumber",
            label: "Ticket No",
        },
        {
            name: "requestDate",
            label: "Date",
            options: {
                customBodyRender: (value) => {
                    return Moment(value).format('DD MMM YYYY hh:mma');
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: "fullName",
            label: "Requester",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    let data = rows[tableMeta.rowIndex]
                    return (
                        <>
                            <div><span className="font-bold">Name: </span>{value}</div>
                            <div><span className="font-bold">Institution: </span>{data.institution}</div>
                            <div><span className="font-bold">Email: </span>{data.email}</div>
                            <div><span className="font-bold">Role: </span>{data.role}</div>
                        </>
                    );
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: "kpiDataRequest",
            label: "KPI Data",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (value && (
                        <>
                            <div><span className="font-bold">KPIs: </span>{value?.layerNames?.join(', ')}</div>
                            <div><span className="font-bold">Aggregation: </span>{value?.aggregateLevel}</div>
                            <div><span className="font-bold">Period: </span>{value?.year?.replace('/', ' - ')}</div>
                        </>
                    ));
                },
                filter: false,
                filter: false,
            },
        },
        {
            name: "gisUtilityDataRequest",
            label: "GIS Data",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (value && (
                            <>
                                <div><span className="font-bold">Layers: </span>{value?.layerNames?.join(', ')}</div>
                                <div><span className="font-bold">Counties: </span>{value?.counties?.join(', ')}</div>
                                <div><span className="font-bold">WSPs: </span>{value?.wsps?.join(', ')}</div>
                            </>)
                    );
                },
                filter: false,
                filter: false,
            },
        },
        {
            name: "proposedOutputs",
            label: "Proposed Outputs",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (<ReadMore>{value}</ReadMore>);
                },
                filter: false,
                filter: false,
            },
        },
        {
            name: "proposedUse",
            label: "Proposed Use",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (<ReadMore>{value}</ReadMore>);
                },
                filter: false,
                filter: false,
            },
        },
        {
            name: "detailedDesc",
            label: "Description",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (<ReadMore>{value}</ReadMore>);
                },
                filter: false,
                filter: false,
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    let name = ' - '
                    switch (value) {
                        case 'PENDING':
                            name = 'Pending'
                            break;
                        case 'ACKNOWLEDGED':
                            name = 'Acknowledged'
                            break;
                        case 'IN_PROGRESS':
                            name = 'In  Progress'
                            break;
                        case 'COMPLETED':
                            name = 'Completed'
                            break;
                    }
                    return name;
                }
            },
        },
        {
            name: "id",
            label: "Action",
            options: {
                print:false,
                download: false,
                filter: false,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let data = rows[tableMeta.rowIndex]
                    return (
                        data.status !== 'COMPLETED' && (<div className="mb-6 border border-gray-400 p-2 rounded">
                            <Dropdown label="Change Status" inline  className="">
                                {data.status === 'PENDING' && (<Dropdown.Item onClick={() => handleClickOpen({
                                    id: value,
                                    name: 'Acknowledge',
                                    status: 'ACKNOWLEDGED'
                                })}>
                                    Acknowledged
                                </Dropdown.Item>)}
                                {(data.status === 'PENDING' || data.status === 'ACKNOWLEDGED') && (<Dropdown.Item
                                    onClick={() => handleClickOpen({
                                        id: value,
                                        name: 'In Progress',
                                        status: 'IN_PROGRESS'
                                    })}>
                                    In Progress
                                </Dropdown.Item>)}
                                <Dropdown.Item onClick={() => handleClickOpen({
                                    id: value,
                                    name: 'Completed',
                                    status: 'COMPLETED'
                                })}>
                                    Completed
                                </Dropdown.Item>
                            </Dropdown>
                        </div>)
                    );
                },
            },
        },
    ];

    const tableOptions = {
        //filterType: 'custom',
        selectableRows: false,
        onDownload: (buildHead, buildBody, columns, data) => {
            const alteredData = data?.map((row, index) => ({index, data: row?.data?.map((field, index) => {
                    let value = ''
                    if( field?.dataCategory){
                        if(field.dataCategory === 'GIS'){
                            value = `layers: ${field.layerNames.join(', ')}\nCounties: ${field.counties.join(', ')}\nWSPs: ${field.wsps.join(', ')}`
                        }else{
                            value = `KPIs: ${field.layerNames.join(', ')}\nAggregation: ${field.aggregateLevel}\nPeriod: ${field.year}`
                        }
                        return  value;
                    }else if(columns[index].name === 'requestDate'){
                        return Moment(field).format('DD MMM YYYY hh:mma');
                    }
                    return field
                }),
            }))
            return `${buildHead(columns)}${buildBody(alteredData)}`.trim()
        }
    };

    useEffect(() => {
        loadData()
    }, []);

    const loadData = () => {
        let params = {
            statuses: 'PENDING,ACKNOWLEDGED,IN_PROGRESS,COMPLETED',
            sort: 'id,desc'
        }

        client.fetchLDataRequests({params: params}, function (response, error) {
            if (response) {
                if (response.error) {
                    setError(response.error)
                    setIsLoading(false);
                    return;
                }
                setPending(false);
                setRows(response.content);
            } else if (error) {
                setPending(false);
                setIsLoading(false);
                this.setState({error});
            }
            handleClose(true)
        })
    }

    document.title = "Requests for Data | Maji Data";

    return (
        <div className="text-gray-500 bg-gray-100 font-body min-h-screen flex flex-col justify-center align-middle">
            <Header/>
            <main className="flex-grow">
                <div className="container mx-auto py-12">
                    <h1 className="text-lg dashboard-heading mb-6 ">Requests for Data</h1>
                    <MUIDataTable
                        data={rows}
                        columns={columns}
                        options={tableOptions}
                    />
                </div>
            </main>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                    Change Status
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        This request status will change to <b>{status.name}</b>. Do you want to proceed?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button autoFocus onClick={submit}>
                        Save changes
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            <Foot/>
        </div>
    )
}

export default DataRequests