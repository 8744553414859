import React, {Component, Element} from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import {authProtectedRoutes, publicRoutes} from './allRoutes';
import ProtectedRoute from './ProtectedRoute';
import {useSelector} from 'react-redux';
import Map from "../pages/Map/Map";
import HomeDashboard from "../pages/HomeDashboard";
import Login from "../pages/Authentication/Login";

const Index = () => {
    const {user, isAuthenticated, loading} = useSelector((state) => state.auth);
    const category = user?.userOrg ? user.userOrg.category : undefined;

    return (
        <React.Fragment>
            <Router>
                <Routes>
                    {publicRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={route.component}
                            key={idx}
                            exact={true}
                        />
                    ))}
                    {authProtectedRoutes.map((route, idx) => {
                        if (loading === false) {
                            return <Route
                                path={route.path}
                                element={<ProtectedRoute component={route.path==='/' && category !== 'WASREB' ?  <Map/> :  route.component} />}
                                key={idx}
                                exact={true}
                            />
                        }
                    })}
                </Routes>
            </Router>
        </React.Fragment>
    )
}

export default Index