import {useEffect, useState} from 'react'
import {useParams, useLocation, useNavigate} from "react-router-dom";
import {Button, Label, Modal, Textarea, TextInput, Radio} from 'flowbite-react'
import Header from '../Layouts/Header'
import Foot from '../Layouts/Footer'
import SingleSubmission from '../../pages/Submissions/SingleSubmission'
import {api, workspace} from "../../config";
import * as url from "../../helpers/url_helper";
import * as client from '../../apis/APIClient';
import moment from 'moment-timezone'
import {useSelector} from 'react-redux';
import {Permission} from "../../config";
import {ToastContainer, toast} from 'react-toastify';

const SubmissionDetails = () => {
    let {id} = useParams();
    const location = useLocation();
    const [submission, setSubmission] = useState(location.state.feature);
    const [paneLayers, setPaneLayers] = useState([])
    const [reviewVisible, setReviewVisible] = useState(false);
    const [modificationVisible, setModificationVisible] = useState(false);
    const [rejectVisible, setRejectVisible] = useState(false);
    const [approveVisible, setApproveVisible] = useState(false);
    const [layer, setLayer] = useState(null);
    const [actions, setActions] = useState([]);
    const {user} = useSelector((state) => state.auth);
    const [error, setError] = useState('');
    const [message, showMessage] = useState(null);
    const navigate = useNavigate()

    const notify = (message) => toast.success(message ? message : "Operation successful!");
    const warnNotify = (error) => toast.error(error ? error : "Please select at least one layer to publish!");

    const workspace = location.state.feature.layerTasks.length > 0 ? location.state.feature.layerTasks[0]?.layer?.code?.split(':')[0] : null;
    const onLayerChange = (value) => {
        setLayer(submission.layerTasks.find(layerTask => layerTask.id == value))
    };

    useEffect(() => {
        setLayer(location.state.feature.layerTasks.length > 0 ? location.state.feature.layerTasks[0] : null)
        client.fetchSubmission(id, function (data, error) {
           // console.log('Submission', data)
            setSubmission(data)
            const layer = data.layerTasks.length > 0 ? data.layerTasks[0] : null
            setLayer(layer)

            let actions = [];
            if (data.submittedAt) {
                actions.push({
                    date: data.submittedAt,
                    action: 'Submitted',
                    actionBy: data.submittedBy,
                })
            }
            if (data.reviewAt) {
                actions.push({
                    date: data.reviewAt,
                    action: 'Reviewed',
                    actionBy: data.reviewedBy,
                })
            }
            if (data.approvedAt) {
                actions.push({
                    date: data.approvedAt,
                    action: 'Approved',
                    actionBy: data.approvedBy,
                })
            }
            if (data.publishedAt) {
                actions.push({
                    date: data.approvedAt,
                    action: 'Published',
                    actionBy: data.publishedBy,
                })
            }
            if (data.rejectedAt) {
                actions.push({
                    date: data.rejectedAt,
                    action: 'Rejected',
                    actionBy: data.rejectedBy,
                })
            }
            actions.sort((a, b) => a.date - b.date)
            setActions(actions)
        })
    }, [])


    const onStartReview = () => {
        const updateRequest = {
            status: "REVIEW"
        }
        client.editSubmission(submission.id, updateRequest, (data, error) => {
            if (data) {
                console.log(data);
                setReviewVisible(false);
                setSubmission(data)
            } else if (error) {
                //   setError(error);
            }
        })
    };

    const handleApproval = (id) => {
        const updateTaskRequest = {
            status: "APPROVED"
        }
        client.editLayerTask(id, updateTaskRequest, function (data, error) {
            if (data) {
                console.log(data);
                setApproveVisible(false);
                notify();
                setLayer(data)
            } else if (error) {
                warnNotify(error);
            }
        })
    }

    const handleReject = (id) => {
        const updateTaskRequest = {
            status: "REJECTED"
        }
        client.editLayerTask(id, updateTaskRequest, function (data, error) {
            if (data) {
                console.log(data);
                setRejectVisible(false);
                notify();
                setLayer(data)
            } else if (error) {
                warnNotify(error);
            }
        })
    }

    const handleModification = (id) => {
        const updateTaskRequest = {
            status: "CHANGE_REQUEST"
        }
        client.editLayerTask(id, updateTaskRequest, function (data, error) {
            if (data) {
                console.log(data);
                setModificationVisible(false);
                notify();
                setLayer(data)
            } else if (error) {
                warnNotify(error);
            }
        })
    }

    const onAction = (action) => {
        switch (action) {
            case 'approve':
                setApproveVisible(true)
                break;
            case 'modification':
                setModificationVisible(true)
                break;
            case 'reject':
                setRejectVisible(true)
                break;
        }
    }

    return (
        <div className='flex flex-col min-h-screen'>
            <Header/>
            <ToastContainer/>
            <div className="flex-grow">
                <div className="container mx-auto py-6">
                    <div className="flex justify-between">
                        <div className="basis-3/12 rounded-md bg-white overflow-hidden">
                            <p className="bg-slate-200 px-2 py-2 font-bold flex justify-start">
                                <svg
                                    className="w-5 h-5 mr-2"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                                    ></path>
                                </svg>
                                Layers
                            </p>

                            {layer && submission.layerTasks.map((layerTask, index) => (
                                <div key={index} className="gap-2 m-4">
                                    <Label className="text-md font-normal">
                                        <Radio name="layer" value={layerTask.id}
                                               checked={layerTask.id === layer.id}
                                               onChange={(e) => onLayerChange(e.target.value)}/>
                                        <span className="p-1"> {layerTask.layerTitle}
                                            <small>({layerTask.taskStatus})</small> </span>
                                    </Label>
                                </div>
                            ))}
                        </div>
                        <div className="w-9/12 rounded-md bg-white ml-6 overflow-hidden">
                            <div className="border-y border-gray-200 bg-gray-50">
                                <div>
                                    <div
                                        className="flex w-full items-center justify-between first:rounded-t-lg last:rounded-b-lg py-2 px-2 text-left font-medium text-gray-500 dark:text-gray-400 hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:hover:bg-gray-800 dark:focus:ring-gray-800 text-gray-900 bg-gray-100 dark:bg-gray-800 dark:text-white">
                                        <h2>
                                            <p>#{submission?.id}</p>{`${submission?.provider.fullName} (${submission?.provider.category})`}
                                        </h2>

                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                                            <div>
                                                {submission.status === 'SUBMITTED' && user.userOrg.category === 'WASREB' && user.permissions.includes(Permission.can_review_submissions) && (

                                                    <button className="primary-button flex" type="button"
                                                            onClick={() => setReviewVisible(true)}>
                                                        <svg className="w-4 h-4 mr-4" fill="none" stroke="currentColor"
                                                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                                        </svg>
                                                        Review
                                                    </button>
                                                )}
                                            </div>
                                            <div>
                                                <button className="outline-button" type="button"
                                                        onClick={() => navigate(-1)}>
                                                    Back
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative overflow-x-auto sm:rounded-lg">
                                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                            <tbody>
                                            <tr>
                                                <td className="border border-slate-300  w-6/12">
                                                    <ul className="p-3">
                                                        {submission.notes?.map((note, index) => (
                                                            <li key={index} className="p-1">{note.note}</li>
                                                        ))}
                                                    </ul>
                                                    <p className="mb-2 text-gray-500">

                                                    </p>
                                                </td>
                                                <td className="border border-slate-300  w-6/5">
                                                    <table className="table-auto border-collapse  w-full">
                                                        <thead
                                                            className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                        <tr>
                                                            <th scope="col" className="px-6 py-3">Date</th>
                                                            <th scope="col" className="px-6 py-3">Action</th>
                                                            <th scope="col" className="px-6 py-3">Action by</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {actions.length > 0 && actions.map((action, index) => (
                                                            <tr key={index}>
                                                                <td className="px-6 py-1">{moment.utc(action.date).tz('Africa/Nairobi').format('DD MMM YYYY hh:mma')}</td>
                                                                <td className="px-6 py-1">{action.action}</td>
                                                                <td className="px-6 py-1">{action.actionBy}</td>
                                                            </tr>))}
                                                        </tbody>

                                                    </table>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {layer && (<SingleSubmission layerTask={layer} onAction={onAction}/>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={reviewVisible}
                size="md"
                popup={true}
                onClose={() => setReviewVisible(false)}
            >
                <Modal.Header/>
                <Modal.Body>
                    <div className="text-center">
                        <svg className="w-16 h-16 text-red-600 mx-auto mb-6" fill="none" stroke="currentColor"
                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                        </svg>
                        <h3 className="mb-5 font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to start the review?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <button data-modal-toggle="popup-modal" type="button"
                                    onClick={() => onStartReview()}
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                Yes, I'm sure
                            </button>
                            <button data-modal-toggle="popup-modal" type="button"
                                    onClick={() => setReviewVisible(false)}
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No,
                                cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={approveVisible}
                size="md"
                popup={true}
                onClose={() => setApproveVisible(false)}
            >
                <Modal.Header/>
                <Modal.Body>
                    <div className="text-center">
                        <svg className="w-16 h-16 text-red-600 mx-auto mb-6" fill="none" stroke="currentColor"
                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                        </svg>
                        <h3 className="mb-5 font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to approve this layer?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <button data-modal-toggle="popup-modal" type="button"
                                    onClick={() => handleApproval(layer.id)}
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                Yes, I'm sure
                            </button>
                            <button data-modal-toggle="popup-modal" type="button"
                                    onClick={() => setApproveVisible(false)}
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No,
                                cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={modificationVisible}
                position="center"
                onClose={() => setModificationVisible(false)}
            >
                <Modal.Header className="bg-slate-800">
                    <span className="text-slate-300">Ask for modification</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-2">
                        <div className="mb-4">
                            <div className="block uppercase font-bold">
                                <Label htmlFor="remarks" value="Remakrs"/>
                            </div>
                            <TextInput
                                id="remarks"
                                name="notes"
                                placeholder="Enter your remarks"
                                required={true}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-slate-700">
                    <Button
                        onClick={() => handleModification(layer.id)}
                        className="bg-slate-300 hover:bg-slate-200 text-slate-800 rounded-md"
                        style={{backgroundColor: '#cbd5e1', color: '#1e293b'}}>
                        Submit
                    </Button>
                    <Button
                        onClick={() => setModificationVisible(false)}
                        className="bg-slate-500 hover:bg-slate-400 text-slate-800 rounded-md"
                        style={{backgroundColor: '#64748b', color: '#1e293b'}}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={rejectVisible}
                size="md"
                popup={true}
                onClose={() => setRejectVisible(false)}
            >
                <Modal.Header/>
                <Modal.Body>
                    <div className="text-center">
                        <svg className="w-16 h-16 text-red-600 mx-auto mb-6" fill="none" stroke="currentColor"
                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                        </svg>
                        <h3 className="mb-5 font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to reject this layer?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <button data-modal-toggle="popup-modal" type="button"
                                    onClick={() => handleReject(layer.id)}
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                Yes, I'm sure
                            </button>
                            <button data-modal-toggle="popup-modal" type="button"
                                    onClick={() => setRejectVisible(false)}
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No,
                                cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Foot/>
        </div>
    )
}

export default SubmissionDetails