import Foot from '../Layouts/Footer'
import Header from '../Layouts/Header'
import React, {useEffect, useState, useMemo} from 'react';
import {Dropdown, Button, Modal, Label, Textarea, TextInput} from 'flowbite-react';
import Pagination from '../../components/Pagination';
import * as api from '../../apis/APIClient';
import {useSelector} from 'react-redux';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone';
import MUIDataTable from "mui-datatables";
import {CircularProgress} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {Permission, textLabels} from "../../config";
import type { CustomFlowbiteTheme } from 'flowbite-react';
import { Flowbite } from 'flowbite-react';

let PageSize = 15;

const Publishing = () => {
    const [pending, setPending] = useState(true);
    const [rows, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const {user} = useSelector((state) => state.auth);
    const workspace = user.userOrg ? user.userOrg.abbrev : 'WASREB';
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [submitVisible, setSubmitVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [error, setError] = useState();
    const stages = 'APPROVED';

    const customTheme: CustomFlowbiteTheme['button'] = {
        color: {
            primary: 'bg-red-500 hover:bg-red-600',
        },
    };

    const navigate = useNavigate();

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return rows?.filter(task => task.audit).filter((item) => item.layer?.toLowerCase().includes(searchValue.toLowerCase())).slice(firstPageIndex, lastPageIndex);
    }, [currentPage, rows, searchValue]);

    const notify = (message) => toast.success(message ? message : "Operation was successful!");
    const warnNotify = (error) => toast.error(error ? error : "An  error occurred!");

    const tableOptions = {
        filterType: 'dropdown',
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        rowsSelected: selectedCheckboxes,
        selectableRows: 'multiple', //or single
        selectableRowsOnClick: true,
        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            setSelectedCheckboxes(rowsSelected)
            const ids = rowsSelected.map(index => rows[index].id);
            console.log('ids', ids)
            console.log('rowsSelected', rowsSelected)
        },
        size: 'small',
        textLabels: textLabels
    };

    const columns = [
        {
            name: 'provider',
            label: 'WSP',
            options: {
                customBodyRender: (value) => {
                    return value.fullName;
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: 'layerTitle',
            label: 'Layer',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'audit',
            label: 'Created At',
            options: {
                customBodyRender: (value) => {
                    return moment(value.createdAt).tz('Africa/Nairobi').format('DD MMM YYYY hh:mma');
                }
            }
        },
        {
            name: 'submittedAt',
            label: 'Submitted At',
            options: {
                customBodyRender: (value) => {
                    return value ? moment.utc(value).tz('Africa/Nairobi').format('DD MMM YYYY hh:mma') : '-';
                }
            }
        },
        {
            name: 'approvedAt',
            label: 'Approved At',
            options: {
                customBodyRender: (value) => {
                    return value ? moment.utc(value).tz('Africa/Nairobi').format('DD MMM YYYY hh:mma') : '-';
                }
            }
        },
        {
            name: 'taskStatus',
            label: 'Status',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "id",
            label: "Action",
            options: {
                print: false,
                download: false,
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="relative">
                            <Dropdown className="z-5000"  label="Actions" inline size="sm" >
                                <Dropdown.Item onClick={()=>{navigate(`/submissions/edits/${value}`, {state: {task: value}})}}>
                                    View
                                </Dropdown.Item>
                                {user.permissions.includes(Permission.can_publish_submissions) && (<Dropdown.Item onClick={()=>setSubmitVisible([value])}>
                                    Publish
                                </Dropdown.Item>)}
                            </Dropdown>
                        </div>
                    );
                },
                sort: false,
            },
        }]

    useEffect(() => {
        setPending(true)
        api.getLayerTasks({statuses: stages}, function (data, error) {
            setRows(data.content)
            setPending(false)
        })
    }, []);


    const onCheckChange = id => {
        // Find index
        const findIdx = selectedCheckboxes.indexOf(id);

        // Index > -1 means that the item exists and that the checkbox is checked
        // and in that case we want to remove it from the array and uncheck it
        if (findIdx > -1) {
            selectedCheckboxes.splice(findIdx, 1);
        } else {
            selectedCheckboxes.push(id);
        }
        console.log(selectedCheckboxes)
    };


    const handlePublish = () => {
       // const ids = submitVisible.map(index => rows[index].id);
        api.publish(submitVisible, function (response, error) {
            if (response) {
                console.log(response);
                setSubmitVisible(false)
                notify();
                setPending(true)
                api.getLayerTasks({statuses: stages}, function (data, error) {
                    setRows(data.content)
                    setPending(false)
                })
            } else if (error) {
                console.log('error', error)
                setSubmitVisible(false)
                warnNotify(error);
            }
        })
    }

    return (
        <div className="text-gray-500 bg-gray-100 font-body min-h-screen flex flex-col justify-center align-middle">
            <Header/>
            <ToastContainer/>
            <main className="flex-grow">
                <div className="container mx-auto py-12">
                    <h1 className="text-lg dashboard-heading mb-6 ">PUBLISHING</h1>

                    {user.permissions.includes(Permission.can_publish_submissions) &&(<div className="mb-6">
                        <Dropdown label="Action">
                            <Dropdown.Item onClick={() => {
                                if (selectedCheckboxes.length === 0) {
                                    warnNotify('Please select at least one layer to publish');
                                } else {
                                    setSubmitVisible(selectedCheckboxes.map((ck, index) => rows[index].id));
                                }
                            }}>
                                Publish
                            </Dropdown.Item>
                        </Dropdown>
                    </div>)}
                    <MUIDataTable
                        title={
                            <p>
                                Approved Layers
                                {pending && <CircularProgress size={24} style={{
                                    marginLeft: 15,
                                    position: 'relative',
                                    top: 4
                                }}/>}
                            </p>
                        }
                        data={rows}
                        columns={columns}
                        options={tableOptions}
                    />
                </div>
            </main>

            <Foot/>

            <Modal
                show={submitVisible}
                size="md"
                popup={true}
                onClose={() => setSubmitVisible(false)}
            >
                <Modal.Header/>
                <Modal.Body>
                    <div className="text-center">
                        <svg className="w-16 h-16 text-red-600 mx-auto mb-6" fill="none" stroke="currentColor"
                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                        </svg>
                        <h3 className="mb-5 font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to publish this layer(s)?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <button data-modal-toggle="popup-modal" type="button"
                                    onClick={() => handlePublish()}
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                Yes, I'm sure
                            </button>
                            <button data-modal-toggle="popup-modal" type="button"
                                    onClick={() => setSubmitVisible(false)}
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No,
                                cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Publishing