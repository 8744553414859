import React, {useState, useEffect} from "react";
import Route from './Routes';
import './App.css';
import IdleTimer from "./IdleTimer";
import {useNavigate} from 'react-router-dom';

function App() {
    const [isTimeout, setIsTimeout] = useState(false);
    useEffect(() => {
        const timer = new IdleTimer({
            timeout: 10, //expire after 10 seconds
            onTimeout: () => {
                console.log('onTimeout')
                //navigate('/logout')
                setIsTimeout(true);
            },
            onExpired: () => {
                // do something if expired on load
                console.log('Expired')
              //  navigate('/logout')
                setIsTimeout(true);
            }
        });

        return () => {
            timer.cleanUp();
        };
    }, []);
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
