import React from "react";
import "./style.css";
import parse from 'html-react-parser'

function Content(props) {
    return (
        <div
            key={props.index}
            className="section__Jobs-styledContent"
            style={
                props.activeTabId === props.index
                    ? {display: "block"}
                    : {display: "none"}
            }
        >
            {props.children}
        </div>
    );
}

export default Content;
