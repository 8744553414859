module.exports = {
  "messages": [
      {
          "wsp_sanitation_facility": [
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "Label",
                  "display_name": "Label",
                  "description": "Label of the feature given by the WSP"
              },
              {
                  "column_name": "Type",
                  "display_name": "Sanitation Service Type",
                  "description": "Type of Sanitation facility. The types selectable are; Sewered, and Non-Sewered. There is a condition such that If user picks 'Sewered'; Containment is automatically 'Null'; If user selects 'Non-sewered' the options in the 'Facil_Type' column become active"
              },
              {
                  "column_name": "Facil_Type",
                  "display_name": "Sanitation Facility Type",
                  "description": "Sanitation facility type that can be selectabe from choces which are; Cistern Flush, Key Hole, Pour flush, VIP, Composting, and Eco-San"
              },
              {
                  "column_name": "No_Toilets",
                  "display_name": "No of Toilets",
                  "description": "The number of tolets in the sanitaion facity"
              },
              {
                  "column_name": "ContainTyp",
                  "display_name": "Containment Type",
                  "description": "Containment type of the sanitation facility. The selectable options are; Conservancy Tank, Lined Pit, Septic Tank, and Bio-Digester"
              },
              {
                  "column_name": "OP_Status",
                  "display_name": "Status",
                  "description": "The feature operation status. The options selectable are; Operational, Operational/Serviceable, Under Construction, Non Operational/Obsolete, and Proposed"
              },
              {
                  "column_name": "Donor",
                  "display_name": "Donor",
                  "description": "Donor of the sanitation facility"
              },
              {
                  "column_name": "ProjctName",
                  "display_name": "Project",
                  "description": "The project which implemented the sanitation facility construction"
              },
              {
                  "column_name": "Contractor",
                  "display_name": "Contractor",
                  "description": "Contractor who built the sanitation facility"
              },
              {
                  "column_name": "Operator",
                  "display_name": "Operator",
                  "description": "The oparator of the sanitation facility"
              },
              {
                  "column_name": "Ownership",
                  "display_name": "Reference No.",
                  "description": "Owner of the water source. The selectable options are; Private, and Public"
              },
              {
                  "column_name": "OwnshpDesc",
                  "display_name": "Ownership Description",
                  "description": "The owership description that can not be be described in the ownership"
              },
              {
                  "column_name": "Area_Name",
                  "display_name": "Area Name",
                  "description": "Area name where the sanitation facility is built"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks and comments observed about the sanitation facility"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA",
                  "description": "Water Works Development Agency (WWDA) as identified by WASREB which the feature belongs to"
              }
          ]
      },
      {
          "wsp_water_source": [
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "Label",
                  "display_name": "Label",
                  "description": "Label of the feature given by the WSP"
              },
              {
                  "column_name": "Type",
                  "display_name": "Type of Water Source",
                  "description": "Type of the water source. There options for selection in this attribute which are Surface, and Ground"
              },
              {
                  "column_name": "Definition",
                  "display_name": "Type of Water Source Definition",
                  "description": "Definition of the water source type. The options for selection are Borehole, Dam, River, Spring, Lake, Shallow Well, and Water pan"
              },
              {
                  "column_name": "SourceName",
                  "display_name": "Name of Source",
                  "description": "Water source name given by the WSP"
              },
              {
                  "column_name": "Elev_m",
                  "display_name": "Elevation (M)",
                  "description": "Height above sea level of the water source"
              },
              {
                  "column_name": "Depth_m",
                  "display_name": "Depth (M)",
                  "description": "Depth of the water source"
              },
              {
                  "column_name": "Yield_M3_H",
                  "display_name": "Yield (M3/hr)",
                  "description": "Water source yield"
              },
              {
                  "column_name": "OP_Status",
                  "display_name": "Operational Status",
                  "description": "Water source operation status. The options selectable are; Operational, Operational/Serviceable, Under Construction, Non Operational/Obsolete, and Proposed"
              },
              {
                  "column_name": "Donor",
                  "display_name": "Project Donor",
                  "description": "Donor who funded the water source project"
              },
              {
                  "column_name": "ProjctName",
                  "display_name": "Project Name",
                  "description": "Project name which implemented the water source"
              },
              {
                  "column_name": "Contractor",
                  "display_name": "Contractor",
                  "description": "Contractor who built the water source"
              },
              {
                  "column_name": "Ownership",
                  "display_name": "Ownership",
                  "description": "Owner of the water source. The selectable options are; Private, and Public"
              },
              {
                  "column_name": "Built_Date",
                  "display_name": "Built date",
                  "description": "Date(Year, Month, Day) when the water source was built"
              },
              {
                  "column_name": "Road",
                  "display_name": "Road Name",
                  "description": "Road/Street name where the water source is located"
              },
              {
                  "column_name": "Area_Name",
                  "display_name": "Area Name",
                  "description": "Area name where the water source is located"
              },
              {
                  "column_name": "Zone",
                  "display_name": "Zone",
                  "description": "WSP territorial zone which the water source is located in"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks about the water source"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA",
                  "description": "Water Works Development Agency (WWDA) as identified by WASREB which the feature belongs to"
              }
          ]
      },
      {
          "wsp_appurtenance": [
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "Label",
                  "display_name": "Label",
                  "description": "Label of the feature given by the WSP"
              },
              {
                  "column_name": "Type",
                  "display_name": "Type",
                  "description": "Type of the appurtenance. The selectable options are; Chamber, Line Marker/Marker Post, Pressure Gauge, Service Duct, Valve, Endline, Offtake, Fire hydrant. A condition exist such that If user selects 'Valve' Options in the 'definition' column  will be; Air Valve, Butterfly Valve, Sluice Valve, Control Valve, Pressure Reducing Valve, Non-Return Valve, Wash out Valve, Isolating Valve, Flow control Valve, Gate Valve, Section Valve"
              },
              {
                  "column_name": "Definition",
                  "display_name": "Definition",
                  "description": "Definition of the appurtenance type. A condition axists above such that if user selects 'Valve' Options in the 'definition' column  will be; Air Valve, Butterfly Valve, Sluice Valve, Control Valve, Pressure Reducing Valve, Non-Return Valve, Wash out Valve, Isolating Valve, Flow control Valve, Gate Valve, Section Valve"
              },
              {
                  "column_name": "Material",
                  "display_name": "Material",
                  "description": "Material used to make the appurtenance. the selectable option are; Asbestos cement, Brass, Cast Iron, Concrete, Cornex, Galvanized Iron, High Density Polyethylene, Polyvynile Chloride, and Uplasticized Polyvynile Chloride"
              },
              {
                  "column_name": "Diam_DN",
                  "display_name": "Nominal Diameter (DN)",
                  "description": "Diameter in MM of the appurtenance"
              },
              {
                  "column_name": "Elev_m",
                  "display_name": "Elevation (M)",
                  "description": "Height above sea level of the appurtenance"
              },
              {
                  "column_name": "OP_Status",
                  "display_name": "Operational Status",
                  "description": "The feature operation status. The options selectable are; Operational, Operational/Serviceable, Under Construction, Non Operational/Obsolete, and Proposed"
              },
              {
                  "column_name": "Donor",
                  "display_name": "Donor",
                  "description": "Donor who funded the appurtenance"
              },
              {
                  "column_name": "ProjctName",
                  "display_name": "Project",
                  "description": "Project name which implemented the appurtenance"
              },
              {
                  "column_name": "Contractor",
                  "display_name": "Contractor",
                  "description": "Contractor who built the appurtenance"
              },
              {
                  "column_name": "Built_Date",
                  "display_name": "Built Date",
                  "description": "Date(Year, Month, Day) of building the appurtenance"
              },
              {
                  "column_name": "Road",
                  "display_name": "Road",
                  "description": "Road/Street name where the appurtenance is"
              },
              {
                  "column_name": "Area_Name",
                  "display_name": "Area Name",
                  "description": "Area name where the appurtenance is at"
              },
              {
                  "column_name": "Zone",
                  "display_name": "Zone",
                  "description": "WSP territorial zone which the appurtenance is at"
              },
              {
                  "column_name": "Region",
                  "display_name": "",
                  "description": "Region name given by WSP"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks and comments observed about the appurtenance"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA",
                  "description": "Water Works Development Agency (WWDA) as identified by WASREB which the feature belongs to"
              }
          ]
      },
      { "wsp_fitting":[
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "Label",
                  "display_name": "Label",
                  "description": "Label of the feature given by the WSP"
              },
              {
                  "column_name": "Type",
                  "display_name": "Type of Fitting",
                  "description": "Type of the fitting. The selectable options are;  Clamp, Junction, Reducer, Socket, Strainer, Coupler/Coupling, Bend, Tee, Union, Adapter, Wye, Plastic-to-coupler Slip, Elbow, Compression Fitting, and Plug"
              },
              {
                  "column_name": "Definition",
                  "display_name": "Type of Fittting Definition",
                  "description": "Definition of the fitting type. The selection are in a manner that If User Selects 'Clamp'; Then the available option for the 'Definition' Column is 'Saddle Clamp' ONLY, If User Selects 'Adapter'; Then the available option for the 'Definition' Column is 'Threaded Adapter' ONLY, If User Selects 'Elbow'; Then the available option for the 'Definition' Column is 'Long Sweep Elbow' ONLY, If User Selects 'Wye'; Then the available option for the 'Definition' Column is 'Double Wye' ONLY, If User Selects 'Union'; Then the available option for the 'Definition' Column is 'Dielectric Union' ONLY, If User Selects 'Junction'; Then the available option for the 'Definition' Column is 'T Junction & X Junction' ONLY"
              },
              {
                  "column_name": "Material",
                  "display_name": "Material",
                  "description": "Material used to make the fitting. the selectable option are; Asbestos cement, Brass, Cast Iron, Concrete, Cornex, Galvanized Iron, High Density Polyethylene, Polyvynile Chloride, and Uplasticized Polyvynile Chloride"
              },
              {
                  "column_name": "Diam_DN",
                  "display_name": "Nominal Diameter (DN)",
                  "description": "Diameter in MM of the fitting"
              },
              {
                  "column_name": "Elev_m",
                  "display_name": "Elevation (M)",
                  "description": "Height above sea level of the fitting"
              },
              {
                  "column_name": "OP_Status",
                  "display_name": "Operational Status",
                  "description": "The feature operation status. The options selectable are; Operational, Operational/Serviceable, Under Construction, Non Operational/Obsolete, and Proposed"
              },
              {
                  "column_name": "Donor",
                  "display_name": "Donor",
                  "description": "Donor who funded the fitting"
              },
              {
                  "column_name": "ProjctName",
                  "display_name": "Project",
                  "description": "Project name which implemented the fitting"
              },
              {
                  "column_name": "Contractor",
                  "display_name": "Contractor",
                  "description": "Contractor who built the fitting"
              },
              {
                  "column_name": "Built_Date",
                  "display_name": "Buillt Date",
                  "description": "Date(Year, Month, Day) of building the fitting"
              },
              {
                  "column_name": "Road",
                  "display_name": "Road",
                  "description": "Road/Street name where the fitting is"
              },
              {
                  "column_name": "Area_Name",
                  "display_name": "Area Name",
                  "description": "Area name where the fitting is at"
              },
              {
                  "column_name": "Zone",
                  "display_name": "Zone",
                  "description": "WSP territorial zone which the fitting is at"
              },
              {
                  "column_name": "Region",
                  "display_name": "Region",
                  "description": "Region name given by WSP"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks and comments observed about the fitting"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA",
                  "description": "Water Works Development Agency (WWDA) as identified by WASREB which the feature belongs to"
              }
          ]
      },
      { "wsp_sewer_manhole":[
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "Label",
                  "display_name": "Label",
                  "description": "Label of the feature given by the WSP"
              },
              {
                  "column_name": "MH_Desc",
                  "display_name": "Man Hole Description",
                  "description": "Sewer Manhole Description"
              },
              {
                  "column_name": "Material",
                  "display_name": "Material",
                  "description": "Sewer Manhole materal type use to construct the manhole structure. The options for selectio are; Asbetos Cement, Ceramic, Concrete, Polyvinyl Chloride, Steel, Unplasticized Polyvinyl Chloride, Wood"
              },
              {
                  "column_name": "Mat_Desc",
                  "display_name": "Material Description",
                  "description": "Sewer Manhole material descrtiption which is selectable from the following options; Heavy Duty, Light Duty"
              },
              {
                  "column_name": "MH_Shape",
                  "display_name": "Man Hole Shape",
                  "description": "Sewer Manhole shape which can be selected from the options; Circular, Rectangular, Square, and triangular"
              },
              {
                  "column_name": "Dimension",
                  "display_name": "Man Hole Dimension",
                  "description": "Dimensions of the sewer manhole describing the size and measuremets"
              },
              {
                  "column_name": "Elev_m",
                  "display_name": "Elevation (M)",
                  "description": "Height above sea level of the Sewer Manhole"
              },
              {
                  "column_name": "Depth_m",
                  "display_name": "Depth (M)",
                  "description": "Depth of the Sewer Manhole in meters"
              },
              {
                  "column_name": "MHCoverMT",
                  "display_name": "Man Hole Cover Material",
                  "description": "The sewer Manhole's cover material. The selectable options are; Cast Iron, Ceramic, Concrete, Metal, Plastic, and Steel"
              },
              {
                  "column_name": "MHCoverSHP",
                  "display_name": "Man Hole Cover Shape",
                  "description": "This attribute takes the sewer Manhole cover and the selectable option are; Circular, Rectangular, Square, and Triangular"
              },
              {
                  "column_name": "OP_Status",
                  "display_name": "Operational Status",
                  "description": "Sewer manhole operation status. The options selectable are; Operational, Operational/Serviceable, Under Construction, Non Operational/Obsolete, and Proposed"
              },
              {
                  "column_name": "Vulnerabil",
                  "display_name": "Vulnerability Status",
                  "description": "Sewer Manhole vulnerability state. The selectable options are; High, Low, and Medeum"
              },
              {
                  "column_name": "Donor",
                  "display_name": "Donor",
                  "description": "Donor who funded the water sewer manhole"
              },
              {
                  "column_name": "ProjctName",
                  "display_name": "Project",
                  "description": "Project name which implemented the sewer manhole"
              },
              {
                  "column_name": "Contractor",
                  "display_name": "Contractor",
                  "description": "Contractor who built the sewer manhole"
              },
              {
                  "column_name": "Built_Date",
                  "display_name": "Built Date",
                  "description": "Date(Year, Month, Day) when the sewer manhole was built"
              },
              {
                  "column_name": "Road",
                  "display_name": "Road",
                  "description": "Road/Street name where the sewer manhole is located"
              },
              {
                  "column_name": "Zone",
                  "display_name": "Zone",
                  "description": "WSP territorial zone which the sewer manhole is located in"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks about the sewer manhole"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA Name",
                  "description": "Water Works Development Agency (WWDA) as identified by WASREB which the feature belongs to"
              }
          ]
      },
      {"wsp_sewer_pipeline":[
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "Label",
                  "display_name": "Label",
                  "description": "Label of the feature given by the WSP"
              },
              {
                  "column_name": "Type",
                  "display_name": "Type",
                  "description": "The Sewer Pipe Type category of its function. The possible options are categorized into; Trunk, Mains, and Laterals"
              },
              {
                  "column_name": "Material",
                  "display_name": "Material",
                  "description": "Sewer Pipeline materal type. The options for selectio are; Asbetos, Cast Iron, Concrete, Galvanized Iron(GI), High Density Polyethylene (HDPE), Polypropylene Random Copolymer (PPR), Polyvinyl Chloride(PVC), Steel, and Unplasticized Polyvinyl Chloride(UPVC)"
              },
              {
                  "column_name": "Diam_DN",
                  "display_name": "Diameter",
                  "description": "The size of diameter of Sewer Pipeline in MM"
              },
              {
                  "column_name": "StartNodeF",
                  "display_name": "Start Node Point",
                  "description": "The begining node point feature of the sewer pipeline e.g. an appurtenance, fitting etc."
              },
              {
                  "column_name": "StNodElv_m",
                  "display_name": "Start Node Point Elevation (m)",
                  "description": "Height above sea level of the begining node point feature of the sewer pipeline"
              },
              {
                  "column_name": "EndNodeF",
                  "display_name": "End Node Point",
                  "description": "The end node point feature of the sewer pipeline e.g. an appurtenance, fitting etc."
              },
              {
                  "column_name": "EdNodElv_m",
                  "display_name": "End Node Point Elevation (m)",
                  "description": "Height above sea level of the end node point feature of the sewer pipeline"
              },
              {
                  "column_name": "Length_m",
                  "display_name": "Length (m)",
                  "description": "Length of the pipe that is autogenerated by GIS systems after drawing the pipe line"
              },
              {
                  "column_name": "OP_Status",
                  "display_name": "Operation Status",
                  "description": "Sewer Pipeline operation status. The options selectable are; Operational, Operational/Serviceable, Under Construction, Non Operational/Obsolete, and Proposed"
              },
              {
                  "column_name": "Donor",
                  "display_name": "Donor",
                  "description": "Donor who funded the construction of the Sewer Pipeline"
              },
              {
                  "column_name": "ProjctName",
                  "display_name": "Project Name",
                  "description": "The project name that implemented the Sewer Pipeline"
              },
              {
                  "column_name": "Contractor",
                  "display_name": "Contractor",
                  "description": "Contractor who built the Water Sewer Pipeline"
              },
              {
                  "column_name": "Built_Date",
                  "display_name": "Built Date",
                  "description": "Date(Year, Month, Day) when the Sewer Pipeline was built"
              },
              {
                  "column_name": "Road",
                  "display_name": "Road",
                  "description": "Road or street name where the Sewer Pipline is laid"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks, comment or observation about the Sewer Pipline"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA",
                  "description": "Water Works Development Agency (WWDA) as identified by WASREB which the feature belongs to"
              }
          ]
      },
      {"wsp_bulk_meter":[
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "Label",
                  "display_name": "Label",
                  "description": "Label of the feature given by the WSP"
              },
              {
                  "column_name": "Account_No",
                  "display_name": "Account Number",
                  "description": "Account Number associated with the Bulk Meter from WSP billing system"
              },
              {
                  "column_name": "Serial_No",
                  "display_name": "Serial Number",
                  "description": "Serial number of the bulk meter by the manufacturer"
              },
              {
                  "column_name": "Flow_Desc",
                  "display_name": "Flow Decription",
                  "description": "Flow description of the bulk meter type of water volume measurement and can be chosen from options Mechanical, ultrasonic, and Insertion,"
              },
              {
                  "column_name": "Diam_DN",
                  "display_name": "Nominal Diameter",
                  "description": "Diameter size of the bulk meter in MM"
              },
              {
                  "column_name": "Model",
                  "display_name": "Model",
                  "description": "Model of the bulk meter"
              },
              {
                  "column_name": "Inst_Mode",
                  "display_name": "Installation Mode",
                  "description": "Intalltion mode of the bulk meter and can be chosen from the two options Vertical, and horizontal."
              },
              {
                  "column_name": "Elev_m",
                  "display_name": "Elevation (M)",
                  "description": "Height above sea level of the bulk meter"
              },
              {
                  "column_name": "Op_Status",
                  "display_name": "Operational Status",
                  "description": "Bulk Meter operation status. The options selectable are; Operational, Operational/Serviceable, Under Construction, Non Operational/Obsolete, and Proposed"
              },
              {
                  "column_name": "Donor",
                  "display_name": "Donor",
                  "description": "Donor who funded the installation of the Bulk Meter"
              },
              {
                  "column_name": "ProjctName",
                  "display_name": "Project",
                  "description": "The project name that implemented the Bulk Meter"
              },
              {
                  "column_name": "Contractor",
                  "display_name": "Contractor",
                  "description": "Contractor who built the Contractor who built the Water Storage facility"
              },
              {
                  "column_name": "Built_Date",
                  "display_name": "Built Date",
                  "description": "Date(Year, Month, Day) when the Bulk Meter was installed"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks, comments, or observation about the Bulk Meter"
              },
              {
                  "column_name": "DMA In",
                  "display_name": "DMA In",
                  "description": "District Metering Area(DMA) is a water balance territory where the DMA in is were water is measured into it"
              },
              {
                  "column_name": "DMA Out",
                  "display_name": "DMA Out",
                  "description": "District Metering Area(DMA) is a water balance territory where the DMA out is were water is measured  out of it"
              },
              {
                  "column_name": "Sub Zone",
                  "display_name": "Sub Zone",
                  "description": "WSP territorial subzone which the Bulk Meter is located in"
              },
              {
                  "column_name": "Zone",
                  "display_name": "Zone",
                  "description": "WSP territorial zone which the Bulk Meter is located in"
              },
              {
                  "column_name": "Region",
                  "display_name": "Region",
                  "description": "Region or area where the Bulk Meter is at that is not a zone and may include a single or multiple zones"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks, comment or observation about the bulk meter"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA",
                  "description": "Water Works Development Agency (WWDA) as identified by WASREB which the feature belongs to"
              }
          ]
      },
      {"wsp_water_pipeline":[
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "Type",
                  "display_name": "Type",
                  "description": "Type that categorize Water Pipeline based on its function. the possible options include; Trasmission Line, Distribution Line, Raw Water Line, and Service line"
              },
              {
                  "column_name": "Definition",
                  "display_name": "Definition",
                  "description": "If a user selects 'transmission' or 'raw water line'; the 'definition' column becomes active. Otherwise answer is null. Otherwise when the choices are made from type there are two possible choices which are; Gravity Main, and Rising Main"
              },
              {
                  "column_name": "Material",
                  "display_name": "Material",
                  "description": "Water Pipeline materal type. The options for selectio are; Asbetos, Cast Iron, Concrete, Galvanized Iron(GI), High Density Polyethylene (HDPE), Polypropylene Random Copolymer (PPR), Polyvinyl Chloride(PVC), Steel, and Unplasticized Polyvinyl Chloride(UPVC)"
              },
              {
                  "column_name": "Diam_DN",
                  "display_name": "Nominal Diameter (mm)",
                  "description": "Diameter size of the Water Pipeline in MM"
              },
              {
                  "column_name": "StartNode_F",
                  "display_name": "Start Node Feature",
                  "description": "The begining or start node feature of the Water Pipeline e.d appurtenance, fitting"
              },
              {
                  "column_name": "StNodElv_m",
                  "display_name": "Start Node Feature Elevation",
                  "description": "Heigh above sea level of the the begining or start node feature of the Water Pipeline e.d appurtenance, fitting"
              },
              {
                  "column_name": "EndNode_F",
                  "display_name": "End Node Feature",
                  "description": "The finising end or end node feature of the Water Pipeline e.d appurtenance, fitting"
              },
              {
                  "column_name": "EdNodElv_m",
                  "display_name": "End Node Feature Elevation",
                  "description": "Heigh above sea level of the the finishing or end node feature of the Water Pipeline e.d appurtenance, fitting"
              },
              {
                  "column_name": "Length_m",
                  "display_name": "Pipe Length",
                  "description": "Length of the Water Pipeline in meters automatically computed when the line is drawn"
              },
              {
                  "column_name": "OP_Status",
                  "display_name": "Operational Status",
                  "description": "Water Pipeline operation status. The options selectable are; Operational, Operational/Serviceable, Under Construction, Non Operational/Obsolete, and Proposed"
              },
              {
                  "column_name": "Donor",
                  "display_name": "Donor",
                  "description": "Donor who funded the construction of the Water Pipeline"
              },
              {
                  "column_name": "Project",
                  "display_name": "Project",
                  "description": "Contractor who built the Contractor who built the Water Storage facility"
              },
              {
                  "column_name": "Contractor",
                  "display_name": "Contractor",
                  "description": "Date(Year, Month, Day) when the Bulk Meter was installed"
              },
              {
                  "column_name": "Built_Date",
                  "display_name": "Built Date",
                  "description": "Remarks, comments, or observation about the Bulk Meter"
              },
              {
                  "column_name": "Road",
                  "display_name": "Road",
                  "description": "District Metering Area(DMA) is a water balance territory where the DMA in is were water is measured into it"
              },
              {
                  "column_name": "Area Name",
                  "display_name": "Area Name",
                  "description": "District Metering Area(DMA) is a water balance territory where the DMA out is were water is measured  out of it"
              },
              {
                  "column_name": "Zone",
                  "display_name": "Zone",
                  "description": "WSP territorial zone which the Water Pipeline is located in"
              },
              {
                  "column_name": "Region",
                  "display_name": "Region",
                  "description": "Region or area where the Water Pipeline is at that is not a zone and may include a single or multiple zones"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks, comment or observation about the Water Pipeline"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA",
                  "description": "Water Works Development Agency (WWDA) as identified by WASREB which the feature belongs to"
              }
          ]
      },
      { "wsp_waste_water_treatment_plant":[
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "Label",
                  "display_name": "Label",
                  "description": "Label of the feature given by the WSP"
              },
              {
                  "column_name": "Type",
                  "display_name": "Type",
                  "description": "The type of Waste Water Treatment Plant facility. The choices can be selected from;  Centralized, and Decentralized"
              },
              {
                  "column_name": "Treat_Tech",
                  "display_name": "Treatment Technology",
                  "description": "The technology used in Waste Water Treatment Plant"
              },
              {
                  "column_name": "Eff_Tst_Par",
                  "display_name": "Effluent Test Parameters",
                  "description": "The effluent test parameters considered"
              },
              {
                  "column_name": "Capty_ m3_d",
                  "display_name": "Capacity in M3/Day",
                  "description": "Capacity of the Waste Water Treatment Plant in cubic meters per day"
              },
              {
                  "column_name": "Dischg_Pnt",
                  "display_name": "Discharge Point",
                  "description": "This is an open ended user inputs name of water body that the Waste Water Treatment Plant discharges the treated waste water"
              },
              {
                  "column_name": "Elev_m",
                  "display_name": "Elevation (M)",
                  "description": "Height above sea level of the Waste Water Treatment Plant"
              },
              {
                  "column_name": "Op_Status",
                  "display_name": "Operational Status",
                  "description": "Waste Water Treatment Plant operation status. The options selectable are; Operational, Operational/Serviceable, Under Construction, Non Operational/Obsolete, and Proposed"
              },
              {
                  "column_name": "Donor",
                  "display_name": "Donor",
                  "description": "Donor who funded the construction of the Waste Water Treatment Plant"
              },
              {
                  "column_name": "ProjctName",
                  "display_name": "Project",
                  "description": "Project that implemented the Waste Water Treatment Plant"
              },
              {
                  "column_name": "Contractor",
                  "display_name": "Contractor",
                  "description": "Contractor who built the Contractor who built the Waste Water Treatment Plant"
              },
              {
                  "column_name": "Built Date",
                  "display_name": "Built Date",
                  "description": "Date(Year, Month, Day) when the Waste Water Treatment Plant was installed"
              },
              {
                  "column_name": "Area_Name",
                  "display_name": "Area Name",
                  "description": "Area name where the Waste Water Treatment Plant is at"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks, comment or observation about the Waste Water Treatment Plant"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA",
                  "description": "Water Works Development Agency (WWDA) as identified by WASREB which the feature belongs to"
              }
          ]
      },{"wsp_water_sampling_point":[
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "Label",
                  "display_name": "Label",
                  "description": "Label of the feature given by the WSP"
              },
              {
                  "column_name": "Test_Param",
                  "display_name": "Test Parameter",
                  "description": "Type of test parameter bing tested at the Water Sampling Point"
              },
              {
                  "column_name": "Sample_Pnt",
                  "display_name": "Point of Sampling",
                  "description": "Where in the water network the Water Sampling Point is at. The possible choices are;  Tank, Water Pipeline, Customer Point, and Reservoir"
              },
              {
                  "column_name": "Area_Name",
                  "display_name": "Area Name",
                  "description": "Are name used to identify the Water Sampling Point"
              },
              {
                  "column_name": "Region",
                  "display_name": "Region",
                  "description": "Region where the Water Sampling Point serving"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks, comment or observation about the Water Sampling Point"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA",
                  "description": "Water Works Development Agency (WWDA) as identified by WASREB which the feature belongs to"
              }
          ]
      },{"wsp_water_treatment_plant":[
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "Label",
                  "display_name": "Label",
                  "description": "Label of the feature given by the WSP"
              },
              {
                  "column_name": "Treat_Type",
                  "display_name": "Treatment Type",
                  "description": "Water Treting type Type either it is Partial or full water treatment"
              },
              {
                  "column_name": "Treat_Desc",
                  "display_name": "Other Treatment",
                  "description": "Treatment type description which can be either Disinfection only or Other type of water treatment"
              },
              {
                  "column_name": "OtherTreat",
                  "display_name": "Description",
                  "description": "In case of other treatment type description a user describes it"
              },
              {
                  "column_name": "WQ_Tst_Par",
                  "display_name": "Water Quality Test Parameter",
                  "description": "The Water Quality test parameters considered"
              },
              {
                  "column_name": "Elev_m",
                  "display_name": "Elevation (m)",
                  "description": "Height above sea level of the Water Treatment Plant"
              },
              {
                  "column_name": "DsgCp_m3_d",
                  "display_name": "Design Capacity (m3)",
                  "description": "Design Capacity of the Water Treatment Plant in cubic meters per day"
              },
              {
                  "column_name": "OprCp_m3_d",
                  "display_name": "Operating Capacity (m3)",
                  "description": "Operating Capacity of the Water Treatment Plant in cubic meters per day"
              },
              {
                  "column_name": "OP_Status",
                  "display_name": "Status",
                  "description": "Water Treatment Plant operation status. The options selectable are; Operational, Operational/Serviceable, Under Construction, Non Operational/Obsolete, and Proposed"
              },
              {
                  "column_name": "Donor",
                  "display_name": "Donor",
                  "description": "Donor who funded the construction of the Water Treatment Plant"
              },
              {
                  "column_name": "ProjctName",
                  "display_name": "Project",
                  "description": "Project that implemented the Water Treatment Plant"
              },
              {
                  "column_name": "Contractor",
                  "display_name": "Contractor",
                  "description": "Contractor who built the Contractor who built the Water Treatment Plant"
              },
              {
                  "column_name": "Built_Date",
                  "display_name": "Bult_Date",
                  "description": "Date(Year, Month, Day) when the Water Treatment Plant was installed"
              },
              {
                  "column_name": "Area_Name",
                  "display_name": "Area Name",
                  "description": "Area Name where the Treatment Plant is at"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks, comment or observation about the Water Treatment Plant"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA",
                  "description": "Water Works Development Agency (WWDA) as identified by WASREB which the feature belongs to"
              }
          ] }, {
          "wsp_actual_served_area":[
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB"
              },
              {
                  "column_name": "AreaServed",
                  "display_name": "Area served",
                  "description": "Area names served"
              },
              {
                  "column_name": "POP",
                  "display_name": "Population Served",
                  "description": "Population served"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WSREB"
              },
              {
                  "column_name": "WSP_Name_F",
                  "display_name": "WSP Name",
                  "description": "WSP Name"
              },
              {
                  "column_name": "WSP_Acronym",
                  "display_name": "WSP Acronym",
                  "description": "WSP Acronym name"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "County ID as identified by WASREB"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "Area_M2",
                  "display_name": "Area (M2)",
                  "description": "Geometry Area of the Actual Served Area in square meters"
              }
          ]
      }, { "wsp_district_metering_area":[
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "Label",
                  "display_name": "Label",
                  "description": "Label of the feature given by the WSP"
              },
              {
                  "column_name": "Descript",
                  "display_name": "Description",
                  "description": "Description of the District Metering Area"
              },
              {
                  "column_name": "Wtr_Source",
                  "display_name": "Water Source",
                  "description": "Source of water being measured"
              },
              {
                  "column_name": "Bulk_Meter",
                  "display_name": "Bulk Meter",
                  "description": "The water volume measuring Bulk Meter"
              },
              {
                  "column_name": "Zone",
                  "display_name": "Zone",
                  "description": "Territorial zone whis the DMA is in. A Zone can have a single several DMAs"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks, comment or observation about the DMA"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA",
                  "description": "Water Works Development Agency (WWDA) as identified by WASREB which the feature belongs to"
              }
          ]}, {"wsp_licensed_service_area":[
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Official Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSPMandate",
                  "display_name": "WSP Mandate",
                  "description": "The mandate of the WSP"
              },
              {
                  "column_name": "Label",
                  "display_name": "WSP Short Name",
                  "description": "Label to define name commonly used"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WSP_Name_F",
                  "display_name": "WSPName",
                  "description": ""
              },
              {
                  "column_name": "Area_m2",
                  "display_name": "SA Area (M2)",
                  "description": "Area in square meters of the Licence Service Area"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              }
          ]
      }, {"wsp_zone":[
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "Label",
                  "display_name": "Label",
                  "description": "Label of the feature given by the WSP"
              },
              {
                  "column_name": "Descript",
                  "display_name": "Description",
                  "description": "Description of the Zone as per the WSP"
              },
              {
                  "column_name": "State",
                  "display_name": "State",
                  "description": "State of the zone"
              },
              {
                  "column_name": "DateUpdatd",
                  "display_name": "Date",
                  "description": "Date when the zone was last updated by the WSP"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA",
                  "description": "Water Works Development Agency (WWDA) as identified by WASREB which the feature belongs to"
              }
          ]}, {"wsp_low_income_area":[
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "LIA_Name",
                  "display_name": "LIA Name",
                  "description": "Low Income Area name"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "Affiliated WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "Area_Km2",
                  "display_name": "Area (Km2)",
                  "description": "Are of LIA in square Kilometers"
              },
              {
                  "column_name": "LIA_Ver",
                  "display_name": "LIA Verified",
                  "description": ""
              },
              {
                  "column_name": "ServStatus",
                  "display_name": "Water Service Status",
                  "description": "Status of served by WSP. The options can be either Served, unserved, or partially served"
              },
              {
                  "column_name": "Served_By",
                  "display_name": "Service Provider(s)",
                  "description": "The serving entity and the options are Water Service Providr(WSP), Small Scale Service Provider(SSSP), or both the WSP and SSSP"
              },
              {
                  "column_name": "No_of_Conn",
                  "display_name": "No of Connections",
                  "description": "Number of water connection in the LIA"
              },
              {
                  "column_name": "Within_WSP",
                  "display_name": "Within WSP SA",
                  "description": "If the LIA lies within the service are of the WSP. The options are; Within, or Not within"
              },
              {
                  "column_name": "Sanitation",
                  "display_name": "Sanitation Status",
                  "description": "Sanitation service within the LIA. The possible options are; Sewered, Unsewered"
              },
              {
                  "column_name": "Population",
                  "display_name": "Population",
                  "description": "Population within the LIA"
              },
              {
                  "column_name": "POPDensity",
                  "display_name": "Population Density",
                  "description": "Population density of the LIA"
              },
              {
                  "column_name": "AfflSubLoc",
                  "display_name": "Affiliated Sublocation",
                  "description": "Affliate sublocations  covered by the LIA"
              },
              {
                  "column_name": "Urbanizati",
                  "display_name": "Urbanization Status",
                  "description": "The urbanization category determined. The possible options are; Rural, Rural urban, Core Urban, and Peri urban"
              },
              {
                  "column_name": "DataSource",
                  "display_name": "Data Source",
                  "description": "Data source which informed the data either Majidata or WSP"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks, comments or observations about the LIA"
              }
          ]},{ "wsp_network_incident": [
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "GIS feature represented"
              },
              {
                  "column_name": "Label",
                  "display_name": "Label",
                  "description": "Label of the feature given by the WSP"
              },
              {
                  "column_name": "Type",
                  "display_name": "Type",
                  "description": "Description of the incident per the WSP. Has a drop down for the possible selections Water Network or sewer Network"
              },
              {
                  "column_name": "Category",
                  "display_name": "Category",
                  "description": "Category of the incidence"
              },
              {
                  "column_name": "IncidntTyp",
                  "display_name": "Incident Type",
                  "description": "The type of the incident after selection of attribute type which describe the specific incident. Has a drop down of possible options"
              },
              {
                  "column_name": "Descriptio",
                  "display_name": "Incident Description",
                  "description": "Descriptive comments of the incidence that help describe the incident"
              },
              {
                  "column_name": "Area_Name",
                  "display_name": "Area Name",
                  "description": "Area name where the incident happen for purpose of are identification."
              },
              {
                  "column_name": "Ticket_Co",
                  "display_name": "Complaint Tickect No",
                  "description": "The ticket no number assigned in response to the incident"
              },
              {
                  "column_name": "Date_Rprt",
                  "display_name": "Date Reported",
                  "description": "Date of reporting the incident"
              },
              {
                  "column_name": "Date_Rslvd",
                  "display_name": "Date Resolved",
                  "description": "Date when the incident was resolved"
              },
              {
                  "column_name": "Pipe Size",
                  "display_name": "PipeSize_DN",
                  "description": ""
              },
              {
                  "column_name": "IncidntStg", 
                  "display_name": "Incident Stage",
                  "description": "stage of the incidence within the operation and maintenance response team."
              },
              {
                  "column_name": "Zone",
                  "display_name": "Zone",
                  "description": "Zone where the incident lies within"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "In addition to comment there are remarks that further describe the operation and maintenance incident resolution"
              },
              {
                  "column_name": "WSP_Name",
                  "display_name": "WSP Name",
                  "description": "WSP name as identified by WASREB which the feature belongs to. The selected WSP_Name will auto populate the values for WSP_ID, County_ID, County, WWDA_ID, and WWDA"
              },
              {
                  "column_name": "WSP_ID",
                  "display_name": "WSP ID",
                  "description": "WSP ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "Country ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County name as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA_ID",
                  "display_name": "WWDA ID",
                  "description": "Water Works Development Agency (WWDA) ID as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA",
                  "description": "Water Works Development Agency (WWDA) as identified by WASREB which the feature belongs to"
              },
              {
                  "column_name": "Network_Feature_ID",
                  "display_name": "Network Feature ID",
                  "description": "Network feature ID that the incident takes place. When a user selects a feature the value of  feature ID is automaticcaly retrieved. This means that feature should first exist before selection."
              },
              {
                  "column_name": "Network_Feature",
                  "display_name": "Network Feature",
                  "description": "This is automatically retrieved from the selected feature"
              }
          ]
      }, {"sssp_service_area": [
              {
                  "column_name": "Feature",
                  "display_name": "Feature",
                  "description": "Feature"
              },
              {
                  "column_name": "SSSP_Name",
                  "display_name": "SSSP Name",
                  "description": "SSSP Name"
              },
              {
                  "column_name": "SSSP_ID",
                  "display_name": "SSSP_ID",
                  "description": "SSSP_ID"
              },
              {
                  "column_name": "TownServed",
                  "display_name": "Town Served/Village Served",
                  "description": "Town Served/Village Served"
              },
              {
                  "column_name": "Aff_Subloc",
                  "display_name": "Affiliated Sublocations",
                  "description": "Affiliated Sublocations"
              },
              {
                  "column_name": "Sub_County",
                  "display_name": "Sub County",
                  "description": "Sub County"
              },
              {
                  "column_name": "Incharge",
                  "display_name": "Designated Incharge",
                  "description": "Designated Incharge"
              },
              {
                  "column_name": "Contacts",
                  "display_name": "Contacts",
                  "description": "Contacts"
              },
              {
                  "column_name": "Registered",
                  "display_name": "Legal Registration (Y/N)",
                  "description": "Legal Registration (Y/N)"
              },
              {
                  "column_name": "RegName",
                  "display_name": "Registered Name",
                  "description": "Registered Name"
              },
              {
                  "column_name": "in_WSPLSA",
                  "display_name": "Within WSPs' Licensed SA",
                  "description": "Within WSPs' Licensed SA"
              },
              {
                  "column_name": "Regulated",
                  "display_name": "Regulated (Y/N)",
                  "description": "Regulated (Y/N)"
              },
              {
                  "column_name": "RegulatedBy",
                  "display_name": "Regulated By",
                  "description": "Regulated By"
              },
              {
                  "column_name": "RegWSPName",
                  "display_name": "Regulating WSP Name",
                  "description": "Regulating WSP Name"
              },
              {
                  "column_name": "RegWSPID",
                  "display_name": "Regulating WSP ID",
                  "description": "Regulating WSP ID"
              },
              {
                  "column_name": "ServOvrlap",
                  "display_name": "Service Overlaps with WSP (Y/N)",
                  "description": "Service Overlaps with WSP (Y/N)"
              },
              {
                  "column_name": "Serves_LIA",
                  "display_name": "Serves LIA (Y/N)",
                  "description": "Serves LIA (Y/N)"
              },
              {
                  "column_name": "LIAName",
                  "display_name": "Name of Served LIA",
                  "description": "Name of Served LIA"
              },
              {
                  "column_name": "Year_Com",
                  "display_name": "Year First Commisioned",
                  "description": "Year First Commisioned"
              },
              {
                  "column_name": "OP_Status",
                  "display_name": "Operational Status",
                  "description": "Operational Status"
              },
              {
                  "column_name": "Cust_Categ",
                  "display_name": "Customer Category",
                  "description": "Customer Category"
              },
              {
                  "column_name": "Pop_Servd",
                  "display_name": "Population Served",
                  "description": "Population Served"
              },
              {
                  "column_name": "WaterSourc",
                  "display_name": "Water Source",
                  "description": "Water Source"
              },
              {
                  "column_name": "Source_Des",
                  "display_name": "Water Source Description",
                  "description": "Water Source Description"
              },
              {
                  "column_name": "SupplyMode",
                  "display_name": "Mode of Supply",
                  "description": "Mode of Supply"
              },
              {
                  "column_name": "EnergySorc",
                  "display_name": "Energy Source",
                  "description": "Energy Source"
              },
              {
                  "column_name": "Connec_typ",
                  "display_name": "Connection Type",
                  "description": "Connection Type"
              },
              {
                  "column_name": "Yield",
                  "display_name": "Average Yield (Cubic M/Day)",
                  "description": "Average Yield (Cubic M/Day)"
              },
              {
                  "column_name": "Treatment",
                  "display_name": "Treated Water (Y/N)",
                  "description": "Treated Water (Y/N)"
              },
              {
                  "column_name": "TreatMode",
                  "display_name": "Mode of Treatment (Full/Partial)",
                  "description": "Mode of Treatment (Full/Partial)"
              },
              {
                  "column_name": "PartialTre",
                  "display_name": "Partial Treatment Description",
                  "description": "Partial Treatment Description"
              },
              {
                  "column_name": "Testing",
                  "display_name": "Water Testing (Y/N)",
                  "description": "Water Testing (Y/N)"
              },
              {
                  "column_name": "TestType",
                  "display_name": "Type of Testing",
                  "description": "Type of Testing"
              },
              {
                  "column_name": "DayServWk",
                  "display_name": "Days Served/Week",
                  "description": "Days Served/Week"
              },
              {
                  "column_name": "HrsPerD",
                  "display_name": "Hours of Service/Day",
                  "description": "Hours of Service/Day"
              },
              {
                  "column_name": "MtrdConn",
                  "display_name": "No. of Metered Connections",
                  "description": "No. of Metered Connections"
              },
              {
                  "column_name": "UnMtrdConn",
                  "display_name": "No. of Unmetered Connections",
                  "description": "No. of Unmetered Connections"
              },
              {
                  "column_name": "TarriffTyp",
                  "display_name": "Type of Tarrif",
                  "description": "Type of Tarrif"
              },
              {
                  "column_name": "Tarrif_App",
                  "display_name": "Tarrif Approved By",
                  "description": "Tarrif Approved By"
              },
              {
                  "column_name": "Cost_Flat",
                  "display_name": "Actual Tarrif Applied (Flat Rate)",
                  "description": "Actual Tarrif Applied (Flat Rate)"
              },
              {
                  "column_name": "Cost_Vol",
                  "display_name": "Actual Tarrif Applied (Volumetric)",
                  "description": "Actual Tarrif Applied (Volumetric)"
              },
              {
                  "column_name": "Cost_Block",
                  "display_name": "Actual Tarrif Applied (Block)",
                  "description": "Actual Tarrif Applied (Block)"
              },
              {
                  "column_name": "Ownership",
                  "display_name": "Ownership",
                  "description": "Ownership"
              },
              {
                  "column_name": "Owner",
                  "display_name": "Owner",
                  "description": "Owner"
              },
              {
                  "column_name": "Mgt_Type",
                  "display_name": "Type of Management (Owner/Delegated)",
                  "description": "Type of Management (Owner/Delegated)"
              },
              {
                  "column_name": "Delegated",
                  "display_name": "Delegated to",
                  "description": "Delegated to"
              },
              {
                  "column_name": "Donor",
                  "display_name": "Donor/Support By",
                  "description": "Donor/Support By"
              },
              {
                  "column_name": "scheme_id",
                  "display_name": "Scheme ID",
                  "description": "Scheme ID"
              },
              {
                  "column_name": "WWDA",
                  "display_name": "WWDA",
                  "description": "WWDA"
              },
              {
                  "column_name": "County",
                  "display_name": "County",
                  "description": "County"
              },
              {
                  "column_name": "County_ID",
                  "display_name": "County ID",
                  "description": "County ID"
              },
              {
                  "column_name": "Remarks",
                  "display_name": "Remarks",
                  "description": "Remarks"
              }
          ],
          "wsp_water_pump" : [
              {
                  "coulmn_name": "feature",
                  "display_name": "Feature",
                  "description": "Feature"
              },
              {
                  "coulmn_name": "label",
                  "display_name": "Label",
                  "description": "Label"
              },
              {
                  "coulmn_name": "type",
                  "display_name": "Type of Pump",
                  "description": "Type of Pump"
              },
              {
                  "coulmn_name": "elev_m",
                  "display_name": "Elevation (M)",
                  "description": "Elevation (M)"
              },
              {
                  "coulmn_name": "op_status",
                  "display_name": "Operational Status",
                  "description": "Operational Status"
              },
              {
                  "coulmn_name": "yield_m3_h",
                  "display_name": "Yield (M3/Hr)",
                  "description": "Yield (M3/Hr)"
              },
              {
                  "coulmn_name": "powersourc",
                  "display_name": "Power Source",
                  "description": "Power Source"
              },
              {
                  "coulmn_name": "hybriddesc",
                  "display_name": "Hybrid Source Description",
                  "description": "Hybrid Source Description"
              },
              {
                  "coulmn_name": "pumpsiz_dn",
                  "display_name": "Pump Size",
                  "description": "Pump Size"
              },
              {
                  "coulmn_name": "droppip_dn",
                  "display_name": "Drop Size",
                  "description": "Drop Size"
              },
              {
                  "coulmn_name": "pump_sno",
                  "display_name": "Pump Serial Number",
                  "description": "Pump Serial Number"
              },
              {
                  "coulmn_name": "p_rat_m3_h",
                  "display_name": "Pump Rate",
                  "description": "Pump Rate"
              },
              {
                  "coulmn_name": "pumphd_m",
                  "display_name": "Pump Head",
                  "description": "Pump Head"
              },
              {
                  "coulmn_name": "pump make",
                  "display_name": "Pump Make",
                  "description": "Pump Make"
              },
              {
                  "coulmn_name": "pump_mdl",
                  "display_name": "Pump Model",
                  "description": "Pump Model"
              },
              {
                  "coulmn_name": "pumpinghrs",
                  "display_name": "Pump Hours",
                  "description": "Pump Hours"
              },
              {
                  "coulmn_name": "pmpinst_yr",
                  "display_name": "Pump Year of Installation",
                  "description": "Pump Year of Installation"
              },
              {
                  "coulmn_name": "motr_sno",
                  "display_name": "Motor Serial Number",
                  "description": "Motor Serial Number"
              },
              {
                  "coulmn_name": "motr_ratin",
                  "display_name": "Motor Rate",
                  "description": "Motor Rate"
              },
              {
                  "coulmn_name": "motr_make",
                  "display_name": "Motor Make",
                  "description": "Motor Make"
              },
              {
                  "coulmn_name": "motr_mdl",
                  "display_name": "Motor Model",
                  "description": "Motor Model"
              },
              {
                  "coulmn_name": "motrins_yr",
                  "display_name": "Motor Year of Installation",
                  "description": "Motor Year of Installation"
              },
              {
                  "coulmn_name": "dsgcap",
                  "display_name": "Design Capacity",
                  "description": "Design Capacity"
              },
              {
                  "coulmn_name": "avg_kwh",
                  "display_name": "Average KWH",
                  "description": "Average KWH"
              },
              {
                  "coulmn_name": "avg_ms_day",
                  "display_name": "Avg_ms_day",
                  "description": "Avg_ms_day"
              },
              {
                  "coulmn_name": "strge_tank",
                  "display_name": "Storage Tank",
                  "description": "Storage Tank"
              },
              {
                  "coulmn_name": "enabled",
                  "display_name": "Enabled",
                  "description": "Enabled"
              },
              {
                  "coulmn_name": "infosource",
                  "display_name": "Information Source",
                  "description": "Information Source"
              },
              {
                  "coulmn_name": "flwratem3h",
                  "display_name": "Flow Rate (M3/Hr)",
                  "description": "Flow Rate (M3/Hr)"
              },
              {
                  "coulmn_name": "inletdiam",
                  "display_name": "Inlet Diameter (mm)",
                  "description": "Inlet Diameter (mm)"
              },
              {
                  "coulmn_name": "outletdiam",
                  "display_name": "Outlet Diameter (mm)",
                  "description": "Outlet Diameter (mm)"
              },
              {
                  "coulmn_name": "ownership",
                  "display_name": "Ownership",
                  "description": "Ownership"
              },
              {
                  "coulmn_name": "managed_by",
                  "display_name": "Managed By",
                  "description": "Managed By"
              },
              {
                  "coulmn_name": "area name",
                  "display_name": "Area Name",
                  "description": "Area Name"
              },
              {
                  "coulmn_name": "zone",
                  "display_name": "Zone",
                  "description": "Zone"
              },
              {
                  "coulmn_name": "remarks",
                  "display_name": "Remarks",
                  "description": "Remarks"
              },
              {
                  "coulmn_name": "wsp_name",
                  "display_name": "WSP Name",
                  "description": "WSP Name"
              },
              {
                  "coulmn_name": "wsp_id",
                  "display_name": "WSP ID",
                  "description": "WSP ID"
              },
              {
                  "coulmn_name": "county_id",
                  "display_name": "County ID",
                  "description": "County ID"
              },
              {
                  "coulmn_name": "county",
                  "display_name": "County",
                  "description": "County"
              },
              {
                  "coulmn_name": "wwda_id",
                  "display_name": "WWDA ID",
                  "description": "WWDA ID"
              },
              {
                  "coulmn_name": "wwda",
                  "display_name": "WWDA",
                  "description": "WWDA"
              }
          ]

      }
  ]
};