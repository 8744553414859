import React from "react";
import HomeDashboard from "../pages/HomeDashboard";
import Providers from "../pages/Providers"
import ProviderDetails from "../pages/Providers/ProviderDetails"
import Sssps from "../pages/Providers/SSSPs/index";
import Users from "../pages/Users/Users";
import Map from "../pages/Map/Map";
import Logout from "../pages/Authentication/Logout";
import Login from "../pages/Authentication/Login";
import ApprovalDetails from "../pages/ApprovalDetails";
import EditUser from "../pages/Users/Users/EditUser";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import Publishing from "../pages/Submissions/Publishing";
import SubmissionDetails from "../pages/Submissions/SubmissionDetails";
import Edits from "../pages/Submissions/Edits";
import Submissions from "../pages/Submissions/Submissions";
import SsspDetails from "../pages/Providers/SSSPs/SsspDetails";
import DataRequests from "../pages/DataRequests";
import Templates from "../pages/Map/Templates";
import Help from "../pages/Help";

const authProtectedRoutes = [
  { path: "/", component: <HomeDashboard/> },
  { path: "/sssps", component: <Sssps/> },
  { path: "/sssps/:id", component: <SsspDetails/> },
  { path: "/users", component: <Users/> },
  { path: "/users/:id", component: <EditUser/> },
  { path: "/submissions/edits/:id", component: <ApprovalDetails/> },
  { path: "/submissions/edits", component: <Edits/> },
  { path: "/submissions/publishing", component: <Publishing/> },
  { path: "/submissions", component: <Submissions/> },
  { path: "/submissions/:id", component: <SubmissionDetails/> },
  { path: "/templates", component: <Templates/> },
  { path: "/providers", component: <Providers/> },
  { path: "/providers/:id", component: <ProviderDetails/> },
  { path: "/requests", component: <DataRequests/> },
  { path: "/map", component: <Map/> },
  ];

const publicRoutes = [
  { path: "/login", component: <Login/> },
  { path: "/forgot/password", component: <ForgotPassword/> },
  { path: "/reset/password", component: <ResetPassword/> },
  { path: "/logout", component: <Logout/> },
  { path: "/help", component: <Help/> },
];

export { authProtectedRoutes, publicRoutes };