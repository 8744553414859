import React, {useEffect, useState} from 'react';
import store from '../redux/store'
import {api} from "../config";
import * as url from "../helpers/url_helper";
import {WFS, GML} from "ol/format";
import XMLParser from "react-xml-parser";
import Toast from '../components/Map/common/Toast';
import {messages} from '../property_mapping';
import {GeoJSON, WMSCapabilities} from "ol/format";
import {toLonLat, fromLonLat, transform, transformExtent} from 'ol/proj';

let jwt = select(store.getState());
const statuses = 'SUBMITTED,REVIEW,CHANGE_REQUEST,REJECTED,APPROVED,PUBLISHED';
const workspace = 'GENERAL'

store.subscribe(listener)

function select(state) {
    return state.auth.jwt
}

function listener() {
    jwt = select(store.getState())
}
const formData = (params) => {
    let paramKeys = [];

    if (params) {
        Object.keys(params).map(key => {
            paramKeys.push(key + '=' + params[key]);
            return paramKeys;
        });
    }

    return paramKeys && paramKeys.length ? paramKeys.join('&') : "";
};


const fetchStatistics = (callback) => {
    const params = {
        service: 'WFS',
        version: '1.0.0',
        request: 'GetFeature',
        typeName: `${workspace}:kpi_national_v`,
        outputFormat: 'application/json',
    }
    fetch(`${api.GEOSERVER_URL}/${workspace}${url.GET_WFS}?${formData(params)}`)
        .then(res => res.json())
        .then((response) => {
                console.log(response)
                if (callback) {
                    callback(response.features)
                }
                console.log(response)
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
                console.log(error)
            }
        )
}

const fetchRoles = async () =>  {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
    };
      let json = await fetch(`${api.API_URL}/roles`,requestOptions);
      let response = await  json.json();
    return response;
}

const updateRequest = async ({id, status}) =>  {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
    };
    let json = await fetch(`${api.API_URL}/data/requests/${id}?${formData({newStatus: status})}`,requestOptions);
    let response = await  json.json();
    return response;
}

const updateUserRoles = async ({id, roles, permissions}) =>  {
    let requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
        body: JSON.stringify(roles)
    };
    let json = await fetch(`${api.API_URL}/users/${id}/roles`,requestOptions);
    let response = await  json.json();

    console.log('roles response', response)

     requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
        body: JSON.stringify(permissions)
    };
    json = await fetch(`${api.API_URL}/users/${id}/permissions`,requestOptions);
    response = await  json.json();
    console.log('permissions response', response)
    return response;
}


const createProvider = (params, callback) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
        body: JSON.stringify(params)
    };
    fetch(`${api.API_URL}${url.PROVIDERS}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log(response)
                if (callback) {
                    if(response.errors){
                        callback(null, response.errors.join(','))
                    }else {
                        callback(response)
                    }
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
                console.log(error)
            }
        )
};

const fetchProviders = (params, callback) => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
    };
    const data = params ? `?${formData(params)}` : ''
    fetch(`${api.API_URL}${url.PROVIDERS}${data}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log('providers', response)
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
                console.log(error)
            }
        )
};

const getProvider = (id, callback) => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
    };
    fetch(`${api.API_URL}${url.PROVIDERS}/${id}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log('provider', response)
                if (callback) {
                    if(response.errors){
                        callback(null, response.errors.join(','))
                    }else {
                        callback(response)
                    }
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
                console.log(error)
            }
        )
};




const fetchCapabilities = (options, callback) => {
    const params = {
        service: options.service,
        request: 'GetCapabilities'
    }
    let url = `${options.url}?${formData(params)}`;
    console.log(url)
    fetch(`${options.url}?${formData(params)}`)
        .then(res => res.text())
        .then((response) => {
                //console.log(response);
                if (callback) {
                    return callback(response)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                    return null;
                }
            }
        )
};

const getGroupedLayers = (options, callback) => {
    let remotes = [];
    const capOptions = {
        service: 'WMS',
        url: `${api.GEOSERVER_URL}/${workspace}/ows`,
    }
    const parser = new WMSCapabilities()
    fetchCapabilities(capOptions, (xmlText, error) => {
       // console.log(xmlText);
        if (xmlText) {
            var result = parser.read(xmlText);
            result.Capability.Layer.Layer.map((xmlLayerGroup) => {
                let subLayers = []
                if (xmlLayerGroup.Layer) {
                    let category;
                    switch (xmlLayerGroup.Name) {
                        case 'Administrative':
                            category = 'WASREB'
                            break;
                        case 'county_layers':
                            category = 'COUNTY'
                            break;
                        case 'wsp_layers':
                            category = 'WSP'
                            break;
                    }
                    if (options.category === 'WASREB' || options.category === category) {
                        xmlLayerGroup.Layer.map((xmlSubLayer) => {
                            let layers = []
                            if (xmlSubLayer.Layer) {
                                xmlSubLayer.Layer.map((xmlLayer) => {
                                    layers.push({
                                        layer: {code: xmlLayer.Name, name: xmlLayer.Title},
                                        extent: transformExtent(xmlLayer.EX_GeographicBoundingBox, 'EPSG:4326', 'EPSG:3857'),
                                        legend: xmlLayer.Style[0].LegendURL[0].OnlineResource,
                                        category: category
                                    })
                                })
                            }
                            if (layers.length > 0) {
                                subLayers.push({
                                    code: xmlSubLayer.Name,
                                    name: xmlSubLayer.Title,
                                    extent: transformExtent(xmlSubLayer.EX_GeographicBoundingBox, 'EPSG:4326', 'EPSG:3857'),
                                    layers: layers
                                })
                            } else {
                                subLayers.push({
                                    layer: {code: xmlSubLayer.Name, name: xmlSubLayer.Title},
                                    extent: transformExtent(xmlSubLayer.EX_GeographicBoundingBox, 'EPSG:4326', 'EPSG:3857'),
                                    legend: xmlSubLayer.Style[0].LegendURL[0].OnlineResource,
                                    category: category
                                })
                            }
                        })

                        if (subLayers.length > 0) {
                            remotes.push({
                                code: xmlLayerGroup.Name,
                                name: xmlLayerGroup.Title,
                                legend: xmlLayerGroup.Style.LegendURL,
                                extent: transformExtent(xmlLayerGroup.EX_GeographicBoundingBox, 'EPSG:4326', 'EPSG:3857'),
                                layers: subLayers.reverse(),
                            })
                        }
                    }
                }
            })
            callback(remotes)
        }
    })
}

const getLayers = (options, callback) => {
    const capOptions = {
        service: 'WMS',
        url: `${api.GEOSERVER_URL}/${options.workspace}/ows`,
    }
    const parser = new WMSCapabilities()
    fetchCapabilities(capOptions, (xmlText, error) => {
        let layers = [];
        if (xmlText) {
            var result = parser.read(xmlText);
            result.Capability.Layer.Layer.map((xmlLayerGroup) => {
                if (!options.exclude || (options.exclude && !options.exclude.includes(xmlLayerGroup.Name)))
                    if (xmlLayerGroup.Layer) {
                        let category;
                        switch (xmlLayerGroup.Name) {
                            case 'Administrative':
                                category = 'WASREB'
                                break;
                            case 'county_layers':
                                category = 'COUNTY'
                                break;
                            case 'wsp_layers':
                                category = 'WSP'
                                break;
                        }
                        xmlLayerGroup.Layer.map((xmlSubLayer) => {
                            if (xmlSubLayer.Layer) {
                                xmlSubLayer.Layer.map((xmlLayer) => {
                                    layers.push({code: xmlLayer.Name, name: xmlLayer.Title, category: category})
                                })
                            } else {
                                layers.push({code: xmlSubLayer.Name, name: xmlSubLayer.Title, category: category})
                            }
                        })
                    }
            })
            callback(layers, error)
        }
    })
}
const fetchSSSPs = (options, callback) => {
    const params = {
        service: 'WFS',
        version: '1.0.0',
        request: 'GetFeature',
        outputFormat: 'application/json',
        typeName: `${workspace}:sssp_service_area`,
    }
    if (options.provider && options.provider.category === "COUNTY") {
        let query = `county_id = ${options.provider.countyId} and stage='published'`
        params.CQL_FILTER = query

        fetch(`${api.GEOSERVER_URL + url.GET_WFS}?${formData(params)}`)
            .then(res => res.json())
            .then((response) => {
                    console.log(response)
                    if (callback) {
                        callback(response.features)
                    }
                },
                (error) => {
                    if (callback) {
                        callback(null, error)
                    }
                }
            )
    }else{
        callback([])
    }

};

const fetchCounties = (options, callback) => {
    const params = {
        service: 'WFS',
        version: '1.0.0',
        request: 'GetFeature',
        outputFormat: 'application/json',
        typeName: `${workspace}:county`,
        PROPERTYNAME: 'county_id,countyname',
    }
    let layerUrl = `${api.GEOSERVER_URL}${url.GET_WFS}?${formData(params)}`

    fetch(layerUrl)
        .then(res => res.json())
        .then((response) => {
               // console.log('counties', response)
                if (callback) {
                    callback(response.features)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const fetchWSPs = (options = {}, callback) => {
    const params = {
        service: 'WFS',
        version: '1.0.0',
        request: 'GetFeature',
        outputFormat: 'application/json',
        typeName: `${workspace}:wsp_licensed_service_area`,
        PROPERTYNAME: 'gid,wsp_id,wsp_name,label,wsp_acrony',
    }
    if (options.cql) {
        params.CQL_FILTER = options.cql
    }
    let layerUrl = `${api.GEOSERVER_URL}${url.GET_WFS}?${formData(params)}`
    fetch(layerUrl)
        .then(res => res.json())
        .then((response) => {
                console.log('wsps', response)
                if (callback) {
                    if (response.errors) {
                        callback(null, response.errors.join(','))
                    } else {
                        callback(response.features)
                    }
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};
const fetchWWDAs = (options = {}, callback) => {
    const params = {
        service: 'WFS',
        version: '1.0.0',
        request: 'GetFeature',
        outputFormat: 'application/json',
        typeName: `${workspace}:wwda`,
        PROPERTYNAME: 'gid,wwda,wwda_id'
    }
    if (options.cql) {
        params.CQL_FILTER = options.cql
    }
    let layerUrl = `${api.GEOSERVER_URL}${url.GET_WFS}?${formData(params)}`
    fetch(layerUrl)
        .then(res => res.json())
        .then((response) => {
              //  console.log('wwdas', response)
                if (callback) {
                    callback(response.features)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const FetchApprovals = (options, callback) => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
    };

    let params = {};
    if (options.workspace !== 'WASREB') {
        params = {
            stages: statuses,
            'orgId': `${options.provider?.id}`
        }
    } else {
        params = {
            stages: statuses
        }
    }

    fetch(`${api.API_URL}${url.GET_APPROVALS}?${formData(params)}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log(response)
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const filterApprovals = (params, callback) => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
    };

    fetch(`${api.API_URL}${url.GET_APPROVALS}?${formData(params)}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log(response)
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const approveTask = (id, updateTaskRequest, callback) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${jwt.accessToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateTaskRequest)
    };
    console.log(`Bearer ${jwt.accessToken}`)
    console.log(updateTaskRequest)
    fetch(`${api.API_URL}${url.GET_APPROVALS + '/' + id}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log('response', response)
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                console.log('error', error)
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const bulkUpdateTasks = (updateTaskRequest, callback) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${jwt.accessToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateTaskRequest)
    };

    console.log(updateTaskRequest)
    fetch(`${api.API_URL}${url.SUBMISSIONS}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log('response', response)
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                console.log('error', error)
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const getLayerTasks = (options, callback) => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
    };
    console.log(requestOptions)
    options.page = 0
    options.size = 100
    options.sort = 'id,desc'

    /*const params = {
        stages: options.stages,
        orgId: user.userOrg.id,
        page: 0,
        size: 100,
        sort:'id,desc'
    }*/

    let baseUrl = `${api.API_URL}${url.LAYER_TASKS}/filter?${formData(options)}`
    console.log(baseUrl)
    fetch(baseUrl, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log(response)
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const fetchSubmissions = (params, callback) => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
    };

    params.page = 0
    params.size =  100
    params.sort =  'id,desc'
    let url;
    if(params.provider){
        url = `${api.API_URL}/submissions/provider-submissions?${formData(params)}`;
    }else{
        url = `${api.API_URL}/submissions?${formData(params)}`;
    }
    fetch(url, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log('response', response)
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const fetchSubmission = (id, callback) => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
    };

    fetch(`${api.API_URL}${url.SUBMISSIONS + '/' + id}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log(response)
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const editLayerTask = (id, updateTaskRequest, callback) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${jwt.accessToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateTaskRequest)
    };
    fetch(`${api.API_URL}${url.LAYER_TASKS + '/' + id}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log('response', response)
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                console.log('error', error)
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const fetchLayerTask = (id, callback) => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
    };

    fetch(`${api.API_URL}${url.LAYER_TASKS + '/' + id}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log(response)
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const fetchLDataRequests = (options = {}, callback) => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
    };
    console.log('options.params',options.params)

    fetch(`${api.API_URL}/data/requests?${formData(options.params)}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log(response)
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const publish = (ids, callback) => {
    let params = {
        ids : ids
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${jwt.accessToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    };
    let url = `${api.API_URL}/layer-tasks/publish`

    console.log('accessToken', jwt.accessToken)
    console.log('body', params)
    console.log('url', url)
    fetch(url, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log('response', response)
                if (callback) {
                    if (response.errors) {
                        callback(undefined, response.errors.join('</br>'))
                    } else {
                        callback(response)
                    }
                }
            },
            (error) => {
                console.log('error', error)
                if (callback) {
                    callback(undefined, error)
                }
            }
        )
};

const editSubmission = (id, updateTaskRequest, callback) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${jwt.accessToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateTaskRequest)
    };
    fetch(`${api.API_URL}${url.SUBMISSIONS + '/' + id}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log('response', response)
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                console.log('error', error)
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const FetchLayerProperties = (options, callback) => {
    const params = {
        service: 'WFS',
        version: '1.0.0',
        request: 'GetFeature',
        typeName: options.layer,
        outputFormat: 'application/json'
    }

    fetch(`${api.GEOSERVER_URL}${url.GET_WFS}?${formData(params)}`)
        .then(res => res.json())
        .then((response) => {
                if (callback) {
                    callback(response.features)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const fetchUsers = (params, callback) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
    };
    console.log('params', params);
    let urlPart;
    if(params.category){
        urlPart = `/filter?${formData(params)}`
    }else {
        urlPart = `?${formData(params)}`
    }
    fetch(`${api.API_URL}${url.GET_USERS}${urlPart}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log('users',response)
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                console.log('users error',error)
                if (callback) {
                    callback([], error)
                }
            }
        )
};

const fetchSingleUser = (params, callback) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
    };
    fetch(`${api.API_URL}${url.GET_USERS + "/" + params}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log(response)
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const createUser = (params, callback) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
        body: JSON.stringify(params)
    };
    fetch(`${api.API_URL}${url.CREATE_USER}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                if (callback) {
                    if(response.errors){
                        callback(null, response.errors.join(', '))
                    }else {
                        callback(response)
                    }
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const editUser = (params, callback) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
        body: JSON.stringify(params)
    };
    fetch(`${api.API_URL}${url.GET_USERS + '/' + params.id}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const forgotPassword = (params, callback) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(params)
    };
    fetch(`${api.API_URL}${url.FORGOT_PASSWORD}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const createPassword = (params, callback) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
        body: JSON.stringify(params)
    };
    requestOptions.body = JSON.stringify(params)
    fetch(`${api.API_URL}${url.CREATE_PASSWORD}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                if (callback) {
                    if(response.errors){
                        callback(null, response.errors.join(', '))
                    }else{
                        callback(response)
                    }
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const changePassword = (params, callback) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(params)
    };
    fetch(`${api.API_URL}${url.CHANGE_PASSWORD}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                if (callback) {
                    callback(response)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const GetProperties = (options, callback, exclude) => {
    const layer = options.layer.split(':')[1];
    let message = messages.find(message => message[layer])
    if(message) {
        message = message[layer]
    }
    console.log('message', message)
    console.log('options', options)
    const params = {
        service: 'WFS',
        version: '1.3.0',
        request: 'describeFeatureType',
        typeName: options.layer,
        outputFormat: 'application/json',
    }
    fetch(`${api.GEOSERVER_URL}/wfs?${formData(params)}`)
        .then(res => res.json())
        .then((response) => {
                console.log('describeFeatureType', response)
                let featureProps = exclude ? featureProps.filter(col => !exclude.includes(col.name)) : response.featureTypes[0].properties
                const targetPrefix = response.targetPrefix;
                const targetNamespace = response.targetNamespace;
                const type = featureProps.find(prop => prop.name === 'geom' || prop.name === 'the_geom')?.type.split(':')[1];

                const params = {
                    layerName: layer,
                }
                let layerResponse = {
                    targetPrefix: targetPrefix,
                    targetNamespace: targetNamespace,
                    type: type,
                }
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                };
                let url = `${api.API_URL}/layer-properties-settings?${formData(params)}`;
                console.log('url', requestOptions)
                fetch(url, requestOptions)
                    .then(res => res.json())
                    .then((response) => {
                            console.log('layer-properties', response)
                            let properties = {}

                            featureProps.filter(prop => prop.name !== 'geom').map(prop => {
                                let property = response.find(r => r.propertyName.toLowerCase() === prop.name.toLowerCase())
                                let alias = message?.find(m => m.column_name?.toLowerCase() === prop.name.toLowerCase())
                                properties[prop.name] = {
                                    value: prop.localType == 'date' ? null : '',
                                    name: prop.name,
                                    maxOccurs: prop.maxOccurs,
                                    minOccurs: prop.minOccurs,
                                    nillable: prop.nillable,
                                    type: prop.localType,
                                    choices: property?.hasChoices || property?.hasDependency ? property?.choices : null,
                                    multiple: property?.multipleChoice,
                                    prepopulated: property?.prepopulated ? {
                                        field: property?.prepopInfo?.prepopProperty,
                                        layer: property?.prepopInfo?.prepopSourceLayer,
                                        displayField: property?.prepopInfo?.visibleProperty
                                    } : false
                                    ,
                                    dependency: property?.dependency,
                                    label: alias?.display_name,
                                    description: alias?.description
                                }
                            })

                            layerResponse.properties = properties
                            console.log('layerResponse', layerResponse)
                            if (callback) {
                                callback(layerResponse)
                            }
                        },
                        (error) => {
                            console.log('error', error)
                        }
                    )
            },
            (
                error
            ) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
}

const deleteEdits = (options, callback) =>{
    var requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
        body: JSON.stringify(options.ids)
    };
    console.log('request',requestOptions)
    fetch(`${api.API_URL}/layer-tasks/revert-edits`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log('Success', response)
                if(callback) {
                    if(response.errors){
                        callback(undefined, response.errors.join(','))
                    } else{
                        callback(response)
                    }
                }
            },
            (error) => {
                console.log('Error', error)
                if(callback) {
                    if(!error){
                        callback(error)
                    }else {
                        callback(null, error)
                    }
                }
            }
        )
}

const deactivateUser = (options, callback) =>{
    var requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        }
    };
    console.log('request',requestOptions)
    fetch(`${api.API_URL}/users/${options.id}`, requestOptions)
        .then(res => res.text())
        .then((response) => {
                console.log('Success', response)
                if(response.errors){
                    callback(undefined, response.errors.join(','))
                }else{
                    callback(response)
                }
            },
            (error) => {
                console.log('Error', error)
                if(callback) {
                    callback(undefined, error)
                }
            }
        )
}
const activateUser = (options, callback) =>{
    var requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        }
    };
    console.log('request',requestOptions)
    fetch(`${api.API_URL}/users/${options.id}/activate`, requestOptions)
        .then(res => res.text())
        .then((response) => {
                console.log('Success', response)
                if(response.errors){
                    callback(undefined, response.errors.join(','))
                }else{
                    callback(response)
                }
            },
            (error) => {
                console.log('Error', error)
                if(callback) {
                    callback(undefined, error)
                }
            }
        )
}

const Transact = (options, callback) => {
    console.log('Transact', options)
    let formatWFS = new WFS();
    let formatGML = new GML({
        featureNS: options.targetNamespace,
        featureType: options.edit.code,
        srsName: 'EPSG:3857',
        featurePrefix: options.targetPrefix,
    });

    var removeProps = [];
    if (options.mode === 'insert') {
        removeProps = ['id', 'gid', 'prev_f_gid', 'stage', 'operation', 'version']
    } else {
        //  removeProps = ['prev_f_gid','stage','operation','version']
        options.features.forEach(feature => feature.set('prev_f_gid', ''))
    }
    let toDelete =  false;

    removeProps.forEach(prop => options.features.forEach(feature => feature.unset(prop)));
    let xs = new XMLSerializer();
    var node;
    switch (options.mode) {
        case 'insert':
            node = formatWFS.writeTransaction(options.features, null, null, formatGML);
            break;
        case 'update':
            node = formatWFS.writeTransaction(null, options.features, null, formatGML);
            break;
         case 'delete':
             toDelete = options.features.filter(feature => feature.stage == null || feature.stage.toLowerCase() == 'edit' || feature.stage.toLowerCase() == 'create')
             /*if(toDelete) {
                 node = formatWFS.writeTransaction(null, null, toDelete, formatGML);
             }
             break;*/
    }
    //Change feature geometry column
   // let featureId =
    let featureIds = options.features.map(feature => feature.getProperties().gid ?feature.getProperties().gid : feature.getProperties().id)
    if (node) {
        const find_geometry = `<Property><Name>geometry</Name>`;
        const re_geometry = new RegExp(find_geometry, "g");

        let xml = xs.serializeToString(node)
        var payload = xml.replace(re_geometry, `<Property><Name>the_geom</Name>`);
        if (options.mode === 'insert') {
            const find_insert_geometry = `geometry`;
            payload = payload.replace(new RegExp(find_insert_geometry, "g"), `the_geom`);
        }
        console.log('payload', payload)

        let provider = options.provider ? options.provider : '';
        let data = new FormData();
        data.append("body", payload)

        let requestOptions = {
            method: 'POST',
            body: data,
        };

        fetch(`${api.GEOSERVER_URL}/wfs`, requestOptions)
            .then(res => res.text())
            .then((response) => {
                    console.log(response)
                    let xml = new XMLParser().parseFromString(response);
                    let transactionResponse = xml.getElementsByTagName('wfs:TransactionResponse')

                    if (transactionResponse.length) {
                        let updated = xml.getElementsByTagName('wfs:totalUpdated')[0].value
                        let inserted = xml.getElementsByTagName('wfs:totalInserted')[0].value
                        let deleted = xml.getElementsByTagName('wfs:totalDeleted')[0].value

                        console.log('updated', updated)
                        console.log('inserted', inserted)
                        console.log('deleted', deleted)
                        if (options.mode === 'insert') {
                            let fids = xml.getElementsByTagName('ogc:FeatureId').map(element => element.attributes.fid.split('.')[1])
                            console.log('fids', fids)
                            featureIds = fids
                        }
                        console.log('message', `${options.mode} was successful`)
                        console.log('provider.id', options.provider?.id)
                        Toast.success({text: `${options.mode} was successful`, autoremove: 3000});
                        if (!toDelete && options.mode !== 'add_incident') {
                            postEdits(options, featureIds, callback)
                        }else{
                            let ids = toDelete.map(feature => feature.getId().split('.'))[1]
                            console.log('request', requestOptions)
                            callback(ids)
                        }
                    } else {
                        let error = xml.getElementsByTagName('ows:ExceptionText')[0].value
                        Toast.error({text: error});
                        if(callback) {
                            callback(null, error)
                        }
                    }
                },
                (error) => {
                    console.log('Error', error)
                    Toast.error({text: error});
                }
            )
    } else {
        postEdits(options,featureIds, callback)
    }
}
const postEdits = (options, featureIds, callback) => {
    let task = {
        orgId: options.provider?.id,
        editLayer: options.edit.code,
        targetLayer: options.edit.code,
        layerTitle: options.edit.name,
        workspace: options.workspace,
        edits:featureIds,
        note: options.remarks,
        taskAction: options.mode.toUpperCase(),
    }
    console.log(jwt.accessToken)
    var requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt.accessToken}`
        },
        body: JSON.stringify(task)
    };

    console.log('request', JSON.stringify(task))

    fetch(`${api.API_URL}/layer-tasks`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log('Success', response)
                if(callback) {
                    callback(response)
                }
            },
            (error) => {
                console.log('Error', error)
                if(callback) {
                    callback(null, error)
                }
            }
        )
}

const uploadFile = (options, callback) => {
    let data = new FormData();
    data.append("zippedShapefile", options.file)

    var requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${jwt.accessToken}`
        },
        body: data
    }

    const params = {
        'targetLayerName': options.layer,
        'providerId': options.provider.id,
        'providerAlias': options.provider.fullName
    }

    console.log('request', data)

    fetch(`${api.API_URL + '/bulk-upload'}?${formData(params)}`, requestOptions)
        .then(res => res.text())
        .then((response) => {
                console.log('Success', response)
                callback(response)
            },
            (error) => {
                console.log('Error', error)
                callback(null,error)
            }
        )
}
export {
    formData,
    fetchStatistics,
    fetchRoles,
    createProvider,
    fetchProviders,
    getProvider,
    updateUserRoles,
    fetchSSSPs,
    fetchCapabilities,
    getLayers,
    getGroupedLayers,
    fetchCounties,
    fetchWSPs,
    fetchWWDAs,
    FetchApprovals,
    filterApprovals,
    approveTask,
    bulkUpdateTasks,
    fetchLDataRequests,
    getLayerTasks,
    fetchSubmissions,
    fetchSubmission,
    editLayerTask,
    fetchLayerTask,
    publish,
    editSubmission,
    FetchLayerProperties,
    fetchUsers,
    fetchSingleUser,
    createUser,
    editUser,
    updateRequest,
    deleteEdits,
    activateUser,
    deactivateUser,
    forgotPassword,
    createPassword,
    changePassword,
    GetProperties,
    uploadFile,
    Transact,
};