import React from 'react'
import {Avatar, Accordion, Button, Modal, Label, Textarea, TextInput} from 'flowbite-react';
import {useLocation, useParams} from 'react-router-dom';
import {api, workspace} from "../../config";
import * as url from "../../helpers/url_helper";
import * as client from '../../apis/APIClient';
import {useEffect, useState} from 'react';
import MapComponent from '../../components/Map'
import {useSelector} from 'react-redux';
import {GeoJSON, WMSCapabilities, WMSGetFeatureInfo} from "ol/format";
import {Permission} from "../../config";

const SingleSubmission = ({layerTask, onAction}) => {
    const {user} = useSelector((state) => state.auth);
    const [error, setError] = useState('');
    const [message, showMessage] = useState(null);
    const [features, setFeatures] = useState([]);
    const [buttons, setButtons] = useState([]);
    const id = 0;
    let excludedProps = ['gid', 'wsp_id','wwda_id','county_id', 'geometry', 'geom', 'the_geom', 'objectid', 'prev_id', 'prev_f_id', 'version', 'stage', 'approved_by', 'date_approved', 'status', 'created_by', 'date_created', 'updated_by', 'date_updated', 'netwk_f_id', 'netwk_f', 'network_f', 'boundedBy', 'prev_f_gid']
   const category = user.userOrg.category
   const reviewer = layerTask.reviewedBy
   const userEmail = user.email
    /*console.log('taskStatus',layerTask.taskStatus)
    console.log('category',category)
    console.log(`'${reviewer}'`,`'${userEmail}'`)*/
    useEffect(() =>{
       // console.log('user.permissions',user.permissions)
       // console.log('check',reviewer == userEmail)
        if (category == 'WASREB' && reviewer == userEmail) {
            console.log('buttons','reviewedBy')
            var buttons = []
            if ((layerTask.taskStatus === 'REVIEW' || layerTask.taskStatus === 'CHANGE_REQUEST') && user.permissions.includes(Permission.can_approve_submissions)) {
                console.log('buttons','Approve')
                buttons = [...buttons, {action: 'approve', color:'success', icon: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                                                                       d="M5 13l4 4L19 7"></path>,  title: 'Approve'}]
            }
            if ((layerTask.taskStatus === 'REVIEW' || layerTask.taskStatus === 'REJECTED') && user.permissions.includes(Permission.can_approve_submissions)) {
                console.log('buttons','Ask for modification')
                buttons = [...buttons, {action: 'modification', color:'info', icon: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                                                                       d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>,  title: 'Ask for modification'}]
            }
            if ((layerTask.taskStatus === 'REVIEW' || layerTask.taskStatus === 'CHANGE_REQUEST') && user.permissions.includes(Permission.can_approve_submissions)) {
                console.log('buttons','Reject')
                buttons = [...buttons, {action: 'reject', color:'failure', icon:  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                                                                d="M6 18L18 6M6 6l12 12"></path>,  title: 'Reject'}]
            }
           // console.log('buttons',buttons)
            setButtons(buttons)
        }
    },[layerTask])

   
    const callback = (features, data) => {
        let rows = features.map(feature => Object.entries(feature.getProperties()).filter(prop => !excludedProps.includes(prop[0])).map(prop =>{
            let property = data?.properties[prop[0]]
            return [property?.label ? property.label : property.name, prop[1]]
        } ))
        setFeatures(rows)
    }

    return (
        <React.Fragment>
            <div
                className="flex w-full items-center justify-between first:rounded-t-lg last:rounded-b-lg p-2 px-5 text-left text-gray-500 dark:text-gray-400 hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:hover:bg-gray-800 dark:focus:ring-gray-800 text-gray-900 bg-gray-100 dark:bg-gray-800 dark:text-white">

                <div className="p-0">
                    <h2 className="font-medium">{layerTask.layerTitle}</h2>
                    <span className="mb-2 text-gray-500 font-small">{`${layerTask.taskAction} (${layerTask.taskStatus})`}</span>
                </div>

                {buttons.length > 0 && ( <div className="p-0">
                    <Button.Group outline={true}>
                        {buttons.map((button,index) => (<Button key={index} color={button.color} onClick={() => onAction(button.action)}>
                            <svg className="w-4 h-4 mr-4" fill="none" stroke="currentColor"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                {button.icon}
                            </svg>
                            {button.title}
                        </Button>))}
                    </Button.Group>
                </div> )}
            </div>

            {message && <div
                className="bg-green-100 rounded-lg py-3 px-6 mb-4 text-base text-green-700 inline-flex items-center w-full"
                role="alert">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle"
                     className="w-4 h-4 mr-2 fill-current" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512">
                    <path fill="currentColor"
                          d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
                </svg>
                Operation was successfully!
            </div>}

            {layerTask && (<div className='flex'>
                <div className="basis-8/12 rounded-md bg-white overflow-hidden">
                    <MapComponent height={55} layerTask={layerTask} callback={callback}/>
                </div>
                <div className="basis-4/12 p-2 bg-white overflow-scroll" style={{height: 55+'vh'}}>
                    {features.map((feature,index) => (
                        <table key={index} className="w-96 text-sm text-left text-gray-500 dark:text-gray-400 w-full">
                            <thead
                                className="text-xs text-white uppercase bg-gray-400 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col-4" className="px-6 py-3">Property</th>
                                <th scope="col-8">Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            {feature.filter(prop => prop[0] !== 'geometry' && prop[0] !== 'the_geom' && prop[0] !== 'gid'  && prop[1] && prop[0].toLowerCase() !== 'null').map((prop, index) => (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <td scope="row"
                                            className="px-6 py-2 font-medium text-gray-900 dark:text-white whitespace-nowrap">{prop[0]}</td>
                                        <td>{prop[1]}</td>
                                    </tr>
                                )
                            )}
                            </tbody>
                        </table>
                    ))}

                </div>
            </div>)}
        </React.Fragment>
    )
}

export default SingleSubmission